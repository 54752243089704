import { useEffect, useState } from 'react'; 
import { useAuthContext } from '../contexts/AuthContext';

import { getDatabase, ref, get, query, orderByChild, equalTo, update } from "firebase/database";
import { db } from "../configs/firebase";


export default function Payment(){

	const { userDetails, user } = useAuthContext();

	const [stallDetails, setStallDetails] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [pastDue, setPastDue] = useState([]);
	const [upcommingDue, setUpcommingDue] = useState([]);
	const [transactions, setTransactions] = useState([]);

	function isPastDue(dateString) {
	  const givenDate = new Date(dateString);
	  const currentDate = new Date();

	  givenDate.setHours(0, 0, 0, 0);
	  currentDate.setHours(0, 0, 0, 0);

	  return givenDate < currentDate;
	}

	function convertDateFormat(dateString) {
	  const months = [
	    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
	    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
	  ];

	  const dateParts = dateString.split('-');
	  
	  const year = dateParts[0];
	  const monthIndex = parseInt(dateParts[1], 10) - 1;
	  const day = parseInt(dateParts[2], 10);

	  const formattedDate = `${months[monthIndex]} ${day}, ${year}`;

	  return formattedDate;
	}

	function formatTimestamp(timestamp) {
	  const date = new Date(timestamp);
	  const year = date.getFullYear().toString().slice(-2);
	  const month = (date.getMonth() + 1).toString().padStart(2, '0');
	  const day = date.getDate().toString().padStart(2, '0');

	  // Convert 24h time to 12h time format
	  let hours = date.getHours();
	  const ampm = hours >= 12 ? 'PM' : 'AM';
	  hours = hours % 12;
	  hours = hours ? hours.toString().padStart(2, '0') : '12';
	  
	  const minutes = date.getMinutes().toString().padStart(2, '0');
	  const seconds = date.getSeconds().toString().padStart(2, '0');

	  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${ampm}`;
	}

	async function fetchTransactionsByUserId(userId) {
	  const transactionsRef = ref(db, 'transactions');
	  const transactionsQuery = query(transactionsRef, orderByChild('userId'), equalTo(userId));
	  
	  try {
	    const snapshot = await get(transactionsQuery);
	    if (snapshot.exists()) {
	      const transactions = [];
	      snapshot.forEach((childSnapshot) => {
	        transactions.push({ id: childSnapshot.key, ...childSnapshot.val() });
	      });

	      setTransactions(transactions)
	      return transactions;
	    } else {
	      console.log('No transactions found for the user:', userId);
	      return [];
	    }
	  } catch (error) {
	    console.error('Error fetching transactions:', error);
	    return [];
	  }
	}

	useEffect(() => {
	  const fetchStallDetails = async () => {
	  	setIsLoading(true);
	    const fetchedStallDetails = [];

	    try {
	      for (const stallNumber of userDetails.stallsOwned) {
	        const stallsRef = ref(db, 'stalls');
	        const queryByStallNumber = query(stallsRef, orderByChild('stallNumber'), equalTo(stallNumber));
	        
	        const querySnapshot = await get(queryByStallNumber);
	        if (querySnapshot.exists()) {
	          querySnapshot.forEach((stallSnapshot) => {
	            const stallData = stallSnapshot.val();
	            fetchedStallDetails.push(stallData);
	          });
	        } else {
	          console.log(`No data found for stall ${stallNumber}.`);
	        }
	      }

	      if (fetchedStallDetails.length > 0) {
	        setStallDetails(fetchedStallDetails);

	        const pastDueArr = [];
	        const dueDatesArr = [];

	        fetchedStallDetails.map(stall => {
	        	const { stallAmount, stallDescription,
	        	 stallImg, stallName, stallNumber, stallOwner, 
	        	 stallProduce, stallSize, status 
	        	} = stall

	        	stall.dueDates.map(due => {

	        		let data = {
	        			dueDate: due,
	        			stallAmount,
	        			stallDescription,
	        			stallImg,
	        			stallName,
	        			stallNumber,
	        			stallOwner,
	        			stallProduce,
	        			stallSize,
	        			status
	        		}


	        		if(isPastDue(due)){
	        			pastDueArr.push(data)
	        		} else {
	        			dueDatesArr.push(data)
	        		}
	        	})
	        });

	        setPastDue(pastDueArr);
	        setUpcommingDue(dueDatesArr);

	      } else {
	        console.log('No stall details were fetched.');
	      }
	    } catch (error) {
	      console.error("Error fetching stall details: ", error);
	    } finally {
        setIsLoading(false);
     	}
	  };

	  if (userDetails?.stallsOwned?.length > 0) {
	    fetchStallDetails();
	  }
	}, [userDetails.stallsOwned]);



	useEffect(() => {
		if(user){
			fetchTransactionsByUserId(user.uid)
		}
	}, [])

	if (isLoading) {
	    return <div>Loading...</div>;
	}

	return (
		<div className="w-full px-4 h-max">
			<h1 className="text-center mt-6">Payments</h1>
			<div className="w-[60px] mx-auto border-2 border-primary-green rounded-xl mb-4"></div>

			<h2 className={`${pastDue.length === 0? "hidden" : null}`}>Past Dues</h2>
			{
				pastDue.map((due, index) => {
					return (
						<div key={index} className="flex flex-col bg-white p-4 rounded-xl min-h-[80px] shadow-xl mb-4">
							<div className="flex justify-between">
								<p className="text-[14px] mb-2"><span className="font-bold">Due date:</span> <span className="text-[#FF0000]">{`${convertDateFormat(due.dueDate)}`}</span></p>
								<div className="w-max rounded-md px-2 bg-primary-gray h-max"><p className="text-[14px] text-white">Stall {due.stallNumber}</p></div>
							</div>
							<p><span className="font-bold">Penalty:</span> Php {`${due.stallAmount * .25}`}</p>
							<p><span className="font-bold">Amount Due:</span> Php {`${due.stallAmount}`}</p>
							<p className="mt-4"><span className="font-bold">Total Amount Due:</span> Php {`${due.stallAmount + due.stallAmount * .25}`}</p>
						</div>
					)
				})
			}

			<h2 className="">Upcomming Dues</h2>
			<div className={`${upcommingDue.length !== 0? "hidden" : null} flex items-center justify-center text-primary-gray bg-white p-4 rounded-xl min-h-[80px] shadow-xl mb-4`}>
				You currently have no dues.	
			</div>
			
			{
				upcommingDue.map((due, index) => {
					return(
						<div key={index} className="flex flex-col bg-white p-4 rounded-xl min-h-[80px] shadow-xl mb-4">
							<div className="flex justify-between">
								<p className="text-[14px] mb-2"><span className="font-bold">Due date:</span> {`${convertDateFormat(due.dueDate)}`}</p>
								<div className="w-max rounded-md px-2 bg-primary-gray h-max"><p className="text-[14px] text-white">Stall {due.stallNumber}</p></div>
							</div>
							<p><span className="font-bold">Penalty:</span> Php {`0`}</p>
							<p><span className="font-bold">Amount Due:</span> Php {`${due.stallAmount}`}</p>
							<p className="mt-4"><span className="font-bold">Total Amount Due:</span> Php {`${due.stallAmount}`}</p>
						</div>
					)
				})
			}

			<h2 className="">Transactions</h2>
			<div className={`${transactions.length !== 0? "hidden" : null } flex items-center justify-center text-primary-gray bg-white p-4 rounded-xl min-h-[80px] shadow-xl mb-4`}>
				You currently have no transactions.	
			</div>
			{
				transactions.map(transaction => {
					return (
						<div className="flex flex-col bg-white p-4 rounded-xl min-h-[80px] shadow-xl mb-4">
							<div className="flex justify-between">
								<p className="text-[14px] mb-2"><span className="font-bold">Transaction Id:</span> {`${transaction.id}`}</p>
								<div className="w-max rounded-md px-2 bg-primary-gray h-max"><p className="text-[14px] text-white">Stall {transaction.stallNumber}</p></div>
							</div>
							<p><span className="font-bold">Penalty:</span> Php {`${transaction.penalty}`}</p>
							<p><span className="font-bold">Amount:</span> Php {`${transaction.amount}`}</p>
							<p className=""><span className="font-bold">Due date:</span> {`${transaction.dues.join(", ")}`}</p>
							<p className=""><span className="font-bold">Date of payment:</span> {`${formatTimestamp(transaction.createdAt)}`}</p>
							<p className="mt-4"><span className="font-bold">Total Amount:</span> Php {`${transaction.totalAmount}`}</p>
						</div>		
					)
				})
			}

		</div>

	);
}