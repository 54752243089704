import { useState, useEffect } from 'react';

import { FaRegTrashAlt } from "react-icons/fa";

import { ref as dbRef, push, set as dbSet, get, child, update, remove } from "firebase/database";
import { db } from "../configs/firebase";
import { toast } from 'react-toastify';

// mui styling
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: `#40916C`,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const AddItemModal = ({isVisible, toggleAddItemModal, toggleReloadTable}) => {

	const [name, setName] = useState('');
	const [weight, setWeight] = useState(0);
	const [price, setPrice] = useState(0);

	const handleSaveItem = async (e) => {
		e.preventDefault();

		const commoditiesData = {
		  name,
		  weight,
		  price,
		  isArchived: false,
		  createdAt: Date.now(),
		  updatedAt: Date.now(),
		};

		try {
		  const commoditiesRef = dbRef(db, 'commodities');
		  const newCommoditiesRef = push(commoditiesRef);
		  await dbSet(newCommoditiesRef, commoditiesData);
		  toast.success("Commodity saved successfully!");
		  toggleReloadTable();
		  toggleAddItemModal();
		} catch (error) {
		  console.error("Error saving commodities:", error);
		  toast.error("Failed to save commodities.");
		}
	}

	if(!isVisible)return null

	return (

		<div className={`fixed z-50 inset-0 flex items-center justify-center overflow-y-auto backdrop-blur-sm`}>

			<form onSubmit={handleSaveItem} className="flex flex-col max-w-[500px] w-full bg-white rounded-lg shadow-2xl mx-2 p-4 gap-4">

				<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
				    <input type="text" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={(e) => setName(e.target.value)} required />
				    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Commodity:</p>
			    </div>

			    <div className="flex flex-row gap-4">
			    	<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
			    	    <input type="text" min="0" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={(e) => setWeight(e.target.value)} required />
			    	    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Weight (KG):</p>
			      </div>

			    	<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
			    	    <input type="number" min="0" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={(e) => setPrice(e.target.value)} required />
			    	    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Price (Php):</p>
			        </div>    
		        </div>

		        <div className="flex flex-row w-full gap-4">
		        	<button 
		        	type="button" 
		        	onClick={() => toggleAddItemModal()} 
		        	className="w-full border-2 border-primary-green min-h-[40px] rounded-xl px-4 font-bold 
		        	text-primary-green mt-4 hover:opacity-70">
		        		Cancel
		        	</button>
		        	<button type="submit" className="w-full bg-primary-green min-h-[40px] rounded-xl px-4 font-bold text-white mt-4 hover:opacity-70">
		        		Save
		        	</button>
		        </div>

			</form>

		</div>

	)

}

const UpdateItemModal = ({isVisible, toggleUpdateItemModal, metaData, toggleReloadTable}) => {

	const { oldName, oldWeight, oldPrice, indexNum, commodityId } = metaData

	const [name, setName] = useState('');
	const [weight, setWeight] = useState(0);
	const [price, setPrice] = useState(0);

	useEffect(() => {
		setName(oldName);
		setWeight(oldWeight);
		setPrice(oldPrice);
	}, [isVisible])

	const handleUpdateItem = async (e) => {
		e.preventDefault();

		const dataToUpdate = {
		  ...(name !== oldName && { name }),
		  ...(weight !== oldWeight && { weight }),
		  ...(price !== oldPrice && { price }),
		  updatedAt: Date.now(),
		};

		try {
		  const dataItemRef = dbRef(db, `commodities/${commodityId}`);
		  await update(dataItemRef, dataToUpdate);
		  toast.success("Commodity updated successfully!");
		  toggleReloadTable();
		  toggleUpdateItemModal();
		} catch (error) {
		  console.error("Error updating commodities:", error);
		  toast.error("Failed to update commodities.");
		}
	}

	const handleDeleteItem = async () => {
	  try {
	    const dataItemRef = dbRef(db, `commodities/${commodityId}`);
	    await remove(dataItemRef);
	    toast.success("Commodities deleted successfully!");
	    toggleReloadTable();

	    toggleUpdateItemModal();
	  } catch (error) {
	    console.error("Error deleting commodities:", error);
	    toast.error("Failed to delete commodities.");
	  }
	};

	if(!isVisible)return null

	return (

		<div className={`fixed z-50 inset-0 flex items-center justify-center overflow-y-auto backdrop-blur-sm`}>

			<form onSubmit={handleUpdateItem} className="flex flex-col max-w-[500px] w-full bg-white rounded-lg shadow-2xl mx-2 p-4 gap-4">

				<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
				    <input type="text" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={(e) => setName(e.target.value)} value={name} required />
				    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Commodity:</p>
			    </div>

			    <div className="flex flex-row gap-4">
			    	<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
			    	    <input type="text" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={(e) => setWeight(e.target.value)} value={weight} required />
			    	    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Weight (KG):</p>
			        </div>

			    	<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
			    	    <input type="number" min="0" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={(e) => setPrice(e.target.value)} value={price} required />
			    	    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Price (Php):</p>
			        </div>    
		        </div>

		        <div className="flex flex-row w-full gap-4">
		        	<button 
		        	type="button" 
		        	onClick={handleDeleteItem} 
		        	className="flex justify-center items-center min-w-[40px] bg-primary-gray min-h-[40px] 
		        	rounded-xl font-bold mt-4 hover:opacity-70">
		        		<FaRegTrashAlt />
		        	</button>
		        	<button 
		        	type="button" 
		        	onClick={() => toggleUpdateItemModal()} 
		        	className="w-full border-2 border-primary-green min-h-[40px] rounded-xl px-4 font-bold 
		        	text-primary-green mt-4 hover:opacity-70">
		        		Cancel
		        	</button>
		        	<button type="submit" className="w-full bg-primary-green min-h-[40px] rounded-xl px-4 font-bold text-white mt-4 hover:opacity-70">
		        		Save
		        	</button>
		        </div>

			</form>

		</div>

	)
}

export default function PriceMonitoring(){

	const [showAddItemModal, setShowAddItemModal] = useState(false);
	const [showUpdateItemModal, setShowUpdateItemModal] = useState(false);
	const [metaData, setMetaData] = useState({}); 

	const [loading, setLoading] = useState(false);
	const [reloadTable, setReloadTable] = useState(false);

	// State for pagination
	const [rows, setRows] = useState([])
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	const toggleAddItemModal = () => {
		setShowAddItemModal(!showAddItemModal);
	}

	const toggleUpdateItemModal = () => {
		setShowUpdateItemModal(!showUpdateItemModal);
	}

	const toggleReloadTable = () => {
		setReloadTable(!reloadTable);
	}

	const handleChangePage = (event, newPage) => {
    	setPage(newPage);
  	};

	const handleChangeRowsPerPage = (event) => {
	  setRowsPerPage(parseInt(event.target.value, 10));
	  setPage(0);
	};

  	useEffect(() => {
  	  const fetchCommodities = async () => {
  	    const dataRef = dbRef(db, 'commodities');
  	  
  	    try {
  	      setLoading(true);
  	      const snapshot = await get(dataRef);
  	  
  	      if (snapshot.exists()) {
  	        const dataArray = Object.entries(snapshot.val()).map(([key, value]) => ({
  	          id: key,
  	          ...value,
  	        }));
  	        dataArray.sort((a, b) => b.createdAt - a.createdAt);
  	        setRows(dataArray);
  	      } else {
  	        setRows([]);
  	        console.log("No data available");
  	      }
  	    } catch (error) {
  	      console.error(error);
  	    } finally {
  	      setLoading(false);
  	    }
  	  };
  	  
  	  fetchCommodities();
  	}, [reloadTable]);

  	if(loading)return<p>Loading...</p>

	return (

		<div className="w-full px-4">
			<AddItemModal isVisible={showAddItemModal} toggleAddItemModal={toggleAddItemModal} toggleReloadTable={toggleReloadTable}/>
			<UpdateItemModal isVisible={showUpdateItemModal} toggleUpdateItemModal={toggleUpdateItemModal} metaData={metaData} toggleReloadTable={toggleReloadTable}/>

			<h1 className="text-center mt-6">Price Monitoring</h1>
			<div className="w-[60px] mx-auto border-2 border-primary-green rounded-xl"></div>

			<div className="w-full flex justify-end my-4">
				<button onClick={toggleAddItemModal} className="bg-primary-green h-10 rounded-xl px-4 font-bold text-white">+ Add Item</button>
			</div>

			<TableContainer component={Paper}>
			  <Table sx={{ minWidth: 700 }} aria-label="customized table">
			    <TableHead>
			      <TableRow>
			        <StyledTableCell>Commodities</StyledTableCell>
			        <StyledTableCell align="center">Weight (KG)</StyledTableCell>
			        <StyledTableCell align="center">Whole Sale Price (Php)</StyledTableCell>
			        <StyledTableCell align="center">Action</StyledTableCell>
			      </TableRow>
			    </TableHead>
			    <TableBody>
			    	{(rowsPerPage > 0
			    	  ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
			    	  : rows
			    	).map((row, index) => (
			    	  <StyledTableRow key={row.name + index}>
			    	    <StyledTableCell component="th" scope="row">
			    	      {row.name}
			    	    </StyledTableCell>
			    	    <StyledTableCell align="center">{row.weight}</StyledTableCell>
			    	     <StyledTableCell align="center">{row.price}</StyledTableCell>
			    	    <StyledTableCell align="center">
			    	    	<button 
				              onClick={() => {
				              	toggleUpdateItemModal()
				              	setMetaData({
				              		oldName: row.name,
				              		oldWeight: row.weight,
				              		oldPrice: row.price,
				              		indexNum: index,
				              		commodityId: row.id
				              	})
				              }}
				              className="p-2 font-bold text-primary-green border-2 border-primary-green rounded-lg 
				              hover:bg-primary-green hover:text-white">
				              Edit
				            </button>
            			</StyledTableCell>
			    	  </StyledTableRow>
			    	))}

			    	{emptyRows > 0 && (
			    	  <TableRow style={{ height: 53 * emptyRows }}>
			    	    <TableCell colSpan={6} />
			    	  </TableRow>
			    	)}
			    </TableBody>
			  </Table>
			</TableContainer>

			<TablePagination
			  rowsPerPageOptions={[5, 10, 25, 50]}
			  component="div"
			  count={rows.length}
			  rowsPerPage={rowsPerPage}
			  page={page}
			  onPageChange={handleChangePage}
			  onRowsPerPageChange={handleChangeRowsPerPage}
			/>

		</div>

	)

}