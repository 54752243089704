import React, { useRef, useEffect, useState } from 'react';

export function LimitText({text, classNameProp}) {
  const myDivRef = useRef(null);

  const [editText, setEditText] = useState(text)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const div = document.getElementById(`${text}`);
    const newText = div;
    setEditText(newText);
  }, [text])
  
  return (
    <div>
      <div 
      ref={myDivRef} 
      id={`${text}`} 
      // onClick={() => setIsOpen(!isOpen)} 
      className={`${!isOpen? classNameProp : ""}`}
      >{text}</div>
    </div>
  );
}

export function LimitTextHtml({text, classNameProp}) {
  const myDivRef = useRef(null);

  const [editText, setEditText] = useState(text)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const div = document.getElementById(`${text}`);
    const newText = div;
    setEditText(newText);
  }, [text])
  
  return (
    <div>
      <div 
      ref={myDivRef} 
      id={`${text}`} 
      onClick={() => setIsOpen(!isOpen)} 
      className={`${!isOpen? classNameProp : ""}`}
      dangerouslySetInnerHTML={{ __html: text }}
      ></div>
    </div>
  );
}
