import { useState, useEffect } from 'react';
import { MdAddPhotoAlternate } from "react-icons/md";
import { ref as dbRef, push, set as dbSet, get, child, update, remove } from "firebase/database";
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../configs/firebase";
import BG from "../assets/login_BG.png";
import { toast } from 'react-toastify';

import { FaPencil } from "react-icons/fa6";
import { FaRegTrashAlt } from "react-icons/fa";

import { useAuthContext } from '../contexts/AuthContext';

const AddNewsModal = ({isVisible, toggleAddNewsModal, setNewsList}) => {

	const { user } = useAuthContext();

	const [newsImg, setNewsImg] = useState('');
	const [imagePreviewUrl, setImagePreviewUrl] = useState('');
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');

	const handleImageChange = (e) => {
	  if (e.target.files && e.target.files[0]) {
	    let reader = new FileReader();
	    let file = e.target.files[0];

	    reader.onloadend = () => {
	      setNewsImg(file);
	      setImagePreviewUrl(reader.result);
	    };

	    reader.readAsDataURL(file);
	  }
	};

	const handleSaveNews = async (e) => {
	  e.preventDefault();

	  let filename = ''

	  if(newsImg){
	  	filename = `newsImages/${user.uid}/${Date.now()}/${newsImg.name}`;
	  }

	  // Start uploading the image if it exists
	  let imgUrl = '';
	  if (newsImg) {
	    try {
	      const imageRef = storageRef(storage, `${filename}`);
	      const snapshot = await uploadBytes(imageRef, newsImg);
	      imgUrl = await getDownloadURL(snapshot.ref);
	    } catch (error) {
	      console.error("Error uploading image:", error);
	      toast.error("Failed to upload image.");
	      return; // Exit the function if image upload fails
	    }
	  }

	  // Construct the news data object
	  const newsData = {
	    title,
	    description,
	    imgUrl,
	    isArchived: false,
	    createdAt: Date.now(),
	    updatedAt: Date.now(),
	  };

	  // Push the news data to the Realtime Database
	  try {
	    const newsRef = dbRef(db, 'news');
	    const newNewsRef = push(newsRef);
	    await dbSet(newNewsRef, newsData);
	    toast.success("News saved successfully!");
	    const newNewsData = { ...newsData, id: newNewsRef.key };
	    setNewsList(prevNewsList => [newNewsData, ...prevNewsList]);
	    setImagePreviewUrl('')
	    setNewsImg('')
	    toggleAddNewsModal();
	  } catch (error) {
	    console.error("Error saving news:", error);
	    toast.error("Failed to save news.");
	  }
	};

	if(!isVisible) return null

	return (

		<div className={`fixed z-50 inset-0 flex items-start justify-center overflow-y-auto backdrop-blur-sm py-8`}>

			<div className="flex lg:flex-row flex-col max-w-[440px] lg:max-w-[1080px] w-full bg-white rounded-lg shadow-2xl mx-2 my-auto p-4 gap-4">
				
				<div className="lg:w-1/2 w-full">
					<div className="relative w-full pt-[56.25%] border-2 border-secondary-gray rounded-lg overflow-hidden hover:bg-primary-gray hover:cursor-pointer">
		                {
		                	!imagePreviewUrl?
		                	<img
		                	  className="absolute top-0 left-0 bottom-0 right-0 object-fit h-full w-full"
		                	  src={BG}
		                	/>
		                	:
		                	<img
		                	  className="absolute top-0 left-0 bottom-0 right-0 object-fit h-full w-full"
		                	  src={imagePreviewUrl}
		                	/>
		                }
		                <div className="absolute inset-0 group flex place-content-center hover:bg-black/50">
		                  <MdAddPhotoAlternate
		                    className={`self-center w-10 h-10 rounded-[5px] group-hover:opacity-100 ${
		                      newsImg? "opacity-0" : null
		                    }`}
		                  />

		                  <input
		                    type="file"
		                    id="imageInput"
		                    name="imageInput"
		                    accept="image/*"
		                    className={`absolute left-0 top-0 opacity-0 hover:cursor-pointer w-full h-full`}
		                    onChange={handleImageChange}
		                  />
		                </div>
		              </div>
				</div>

				<form onSubmit={handleSaveNews} className="flex flex-col lg:w-1/2 w-full">
					<label className="font-semibold">Title:</label>
					<input
						className="border rounded-lg pl-2 outline-none"
						onChange={(e) => {setTitle(e.target.value)}}
						type="text"
						required
					/>
					<label className="font-semibold mt-4">Description:</label>
					<textarea
						onChange={(e) => {setDescription(e.target.value)}}
						className="border rounded-lg pl-2 outline-none h-full"
						type="text"
						required
					/>
					<div className="flex flex-row w-full gap-4">
						<button 
						type="button" 
						onClick={() => toggleAddNewsModal()} 
						className="w-full border-2 border-primary-green min-h-[40px] rounded-xl px-4 font-bold 
						text-primary-green mt-4 hover:opacity-70">
							Cancel
						</button>
						<button type="submit" className="w-full bg-primary-green min-h-[40px] rounded-xl px-4 font-bold text-white mt-4 hover:opacity-70">
							Save
						</button>
					</div>
				</form>

			</div>

		</div>

	)
}

const UpdateNewsModal = ({isVisible, toggleUpdateNewsModal, setNewsList, metaData}) => {

	const { oldNewsImg, oldTitle, oldDescription, newsId, indexNum } = metaData

	const { user } = useAuthContext();

	const [newsImg, setNewsImg] = useState('');
	const [imagePreviewUrl, setImagePreviewUrl] = useState(oldNewsImg);
	const [title, setTitle] = useState(oldTitle);
	const [description, setDescription] = useState(oldDescription);

	useEffect(() => {
		setImagePreviewUrl(oldNewsImg);
		setTitle(oldTitle);
		setDescription(oldDescription);
	}, [isVisible])

	const handleImageChange = (e) => {
	  if (e.target.files && e.target.files[0]) {
	    let reader = new FileReader();
	    let file = e.target.files[0];

	    reader.onloadend = () => {
	      setNewsImg(file);
	      setImagePreviewUrl(reader.result);
	    };

	    reader.readAsDataURL(file);
	  }
	};

	const handleUpdateNews = async (e) => {
	  e.preventDefault();

	  // Start by determining whether a new image has been selected
	  let imgUrl = oldNewsImg; // Default to the old image URL
	  if (newsImg) {
	    // If a new image is selected, upload it and get the new URL
	    const filename = `newsImages/${user.uid}/${Date.now()}/${newsImg.name}`;
	    try {
	      const imageRef = storageRef(storage, filename);
	      const snapshot = await uploadBytes(imageRef, newsImg);
	      imgUrl = await getDownloadURL(snapshot.ref);
	    } catch (error) {
	      console.error("Error uploading image:", error);
	      toast.error("Failed to upload image.");
	      return; // Exit the function if image upload fails
	    }
	  }

	  // Construct the news data object with the potentially new image URL
	  const newsDataToUpdate = {
	    ...(title !== oldTitle && { title }),
	    ...(description !== oldDescription && { description }),
	    ...(imgUrl !== oldNewsImg && { imgUrl }),
	    updatedAt: Date.now(),
	  };

	  // Update the specific news item in Firebase
	  try {
	    const newsItemRef = dbRef(db, `news/${newsId}`);
	    await update(newsItemRef, newsDataToUpdate);
	    toast.success("News updated successfully!");

	    // Update the local state to reflect the change
	    setNewsList(prevNewsList => {
	      const updatedNewsList = [...prevNewsList];
	      if (indexNum != null && updatedNewsList[indexNum]) {
	        updatedNewsList[indexNum] = { ...updatedNewsList[indexNum], ...newsDataToUpdate };
	      }
	      return updatedNewsList;
	    });

	    // Reset the form and image preview
	    setImagePreviewUrl('');
	    setNewsImg('');
	    toggleUpdateNewsModal();
	  } catch (error) {
	    console.error("Error updating news:", error);
	    toast.error("Failed to update news.");
	  }
	};

	const handleDeleteNews = async () => {
	  try {
	    // Remove the news item from Firebase
	    const newsItemRef = dbRef(db, `news/${newsId}`);
	    await remove(newsItemRef);
	    toast.success("News deleted successfully!");

	    // Update the local state to reflect the change
	    setNewsList(prevNewsList => prevNewsList.filter((_, index) => index !== indexNum));

	    // Close the modal
	    toggleUpdateNewsModal();
	  } catch (error) {
	    console.error("Error deleting news:", error);
	    toast.error("Failed to delete news.");
	  }
	};

	if(!isVisible) return null

	return (

		<div className={`fixed z-50 inset-0 flex items-start justify-center overflow-y-auto backdrop-blur-sm py-8`}>

			<div className="flex lg:flex-row flex-col max-w-[440px] lg:max-w-[1080px] w-full bg-white rounded-lg shadow-2xl mx-2 my-auto p-4 gap-4">
				
				<div className="lg:w-1/2 w-full">
					<div className="relative w-full pt-[56.25%] border-2 border-secondary-gray rounded-lg overflow-hidden hover:bg-primary-gray hover:cursor-pointer">
		                {
		                	!imagePreviewUrl?
		                	<img
		                	  className="absolute top-0 left-0 bottom-0 right-0 object-fit h-full w-full"
		                	  src={BG}
		                	/>
		                	:
		                	<img
		                	  className="absolute top-0 left-0 bottom-0 right-0 object-fit h-full w-full"
		                	  src={imagePreviewUrl}
		                	/>
		                }
		                <div className="absolute inset-0 group flex place-content-center hover:bg-black/50">
		                  <MdAddPhotoAlternate
		                    className={`self-center w-10 h-10 rounded-[5px] group-hover:opacity-100 ${
		                      imagePreviewUrl? "opacity-0" : null
		                    }`}
		                  />

		                  <input
		                    type="file"
		                    id="imageInput"
		                    name="imageInput"
		                    accept="image/*"
		                    className={`absolute left-0 top-0 opacity-0 hover:cursor-pointer w-full h-full`}
		                    onChange={handleImageChange}
		                  />
		                </div>
		              </div>
				</div>

				<form onSubmit={handleUpdateNews} className="flex flex-col lg:w-1/2 w-full">
					<label className="font-semibold">Title:</label>
					<input
						className="border rounded-lg pl-2 outline-none"
						value={title}
						onChange={(e) => {setTitle(e.target.value)}}
						type="text"
						required
					/>
					<label className="font-semibold mt-4">Description:</label>
					<textarea
						onChange={(e) => {setDescription(e.target.value)}}
						value={description}
						className="border rounded-lg pl-2 outline-none h-full"
						type="text"
						required
					/>
					<div className="flex flex-row w-full gap-4">
						<button 
						type="button" 
						onClick={handleDeleteNews} 
						className="flex justify-center items-center min-w-[40px] bg-primary-gray min-h-[40px] 
						rounded-xl font-bold mt-4 hover:opacity-70">
							<FaRegTrashAlt />
						</button>
						<button 
						type="button" 
						onClick={() => toggleUpdateNewsModal()} 
						className="w-full border-2 border-primary-green min-h-[40px] rounded-xl px-4 font-bold 
						text-primary-green mt-4 hover:opacity-70">
							Cancel
						</button>
						<button type="submit" className="w-full bg-primary-green min-h-[40px] rounded-xl px-4 font-bold text-white mt-4 hover:opacity-70">
							Update
						</button>
					</div>
				</form>

			</div>

		</div>

	)
}

export default function AdminDashboard() {

	const [showAddNewsModal, setShowAddNewsModal] = useState(false);
	const [showUpdateNewsModal, setShowUpdateNewsModal] = useState(false);
	const [metaData, setMetaData] = useState({
		oldNewsImg: '',
		oldTitle: '',
		oldDescription: '',
		newId: '',
		indexNum: 0,
	});
	const [newsList, setNewsList] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	function formatTimestamp(timestamp) {
	  const date = new Date(timestamp);
	  const year = date.getFullYear().toString().slice(-2); // Get the last two digits
	  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
	  const day = date.getDate().toString().padStart(2, '0');

	  // Convert 24h time to 12h time format
	  let hours = date.getHours();
	  const ampm = hours >= 12 ? 'PM' : 'AM';
	  hours = hours % 12;
	  hours = hours ? hours.toString().padStart(2, '0') : '12'; // the hour '0' should be '12'
	  
	  const minutes = date.getMinutes().toString().padStart(2, '0');
	  const seconds = date.getSeconds().toString().padStart(2, '0');

	  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${ampm}`;
	}

	const toggleAddNewsModal = () => {
		setShowAddNewsModal(!showAddNewsModal);
	};

	const toggleUpdateNewsModal = () => {
		setShowUpdateNewsModal(!showUpdateNewsModal);
	};

	useEffect(() => {
	  const fetchNews = async () => {
	    const newsRef = dbRef(db, 'news');
	  
	    try {
	      setLoading(true);
	      const snapshot = await get(newsRef);
	  
	      if (snapshot.exists()) {
	        const newsArray = Object.entries(snapshot.val()).map(([key, value]) => ({
	          id: key,
	          ...value,
	        }));
	        // Sort news items by createdAt in descending order
	        newsArray.sort((a, b) => b.createdAt - a.createdAt);
	        setNewsList(newsArray);
	      } else {
	        setNewsList([]);
	        console.log("No data available");
	      }
	    } catch (error) {
	      console.error(error);
	      setError(error);
	    } finally {
	      setLoading(false);
	    }
	  };
	  
	  fetchNews();
	}, []);
	
	return (

		<div className="w-full px-4">
			<AddNewsModal isVisible={showAddNewsModal} toggleAddNewsModal={toggleAddNewsModal} setNewsList={setNewsList} />
			<UpdateNewsModal isVisible={showUpdateNewsModal} toggleUpdateNewsModal={toggleUpdateNewsModal} setNewsList={setNewsList} metaData={metaData}/>
			<h1 className="text-center mt-6">News Board</h1>
			<div className="w-[60px] mx-auto border-2 border-primary-green rounded-xl"></div>

			<div className="w-full flex justify-end my-4">
				<button onClick={toggleAddNewsModal} className="bg-primary-green h-10 rounded-xl px-4 font-bold text-white">+ Add News</button>
			</div>

			<div className="overflow-y-auto overflow-auto mx-4">
			{
				newsList.map((news, index) => {
					return(
						<div className="mb-6" key={index}>
							<div className="flex lg:flex-row flex-col max-w-[440px] rounded-lg p-4 w-full lg:max-w-[1080px] mx-auto bg-white shadow-xl gap-4">
								<div className="lg:w-1/2 w-full">
									<div className="relative w-full pt-[56.25%] border-secondary-gray rounded-lg overflow-hidden hover:bg-primary-gray hover:cursor-pointer">
										<img
										  className="absolute top-0 left-0 bottom-0 right-0 object-fit h-full w-full"
										  src={news.imgUrl}
										  onError={(e) => {
										  	e.target.src = `${BG}`
										  }}
										/>
									</div>
								</div>

								<div className="lg:w-1/2 w-full">
									<div className="flex justify-end w-full">
										<button 
										onClick={() => {
											toggleUpdateNewsModal()
											setMetaData({
												oldNewsImg: news.imgUrl,
												oldTitle: news.title,
												oldDescription: news.description,
												newsId: news.id,
												indexNum: index,
											})
										}}>
											<FaPencil className="text-secondary-green"/>
										</button>
									</div>
									<h2 className="">{news.title}</h2>
									<p className="text-primary-gray text-[12px] mb-4">last updated: {formatTimestamp(news.updatedAt)}</p>
									<p>{news.description}</p>
								</div>
							</div>
						</div>
					)
				})
			}
			</div>
		</div>

	)

}