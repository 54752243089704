// AuthContext.js
import { useState, useEffect, createContext, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../configs/firebase";
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../configs/firebase";
import { get, ref, set} from "firebase/database";
import { toast } from "react-toastify";

const AuthContext = createContext({});

export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [userType, setUserType] = useState('');
  const [loading, setLoading] = useState(true);

  const createUserAndSaveData = async (email, password, additionalData, profileImgFile, image, image2, image3) => {
    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const uid = userCredential.user.uid;

      // Upload profile image to Firebase Storage and get the download URL
      let imgUrl = '';
      let img1 = '';
      let img2 = '';
      let img3 = '';
      if (profileImgFile) {
        const imgStorageRef = storageRef(storage, `profileImages/${uid}/${profileImgFile.name}`);
        const uploadResult = await uploadBytes(imgStorageRef, profileImgFile);
        imgUrl = await getDownloadURL(uploadResult.ref);
      }

      if (image && image2) {
        const imgStorage1Ref = storageRef(storage, `requiredImg/${uid}/${image.name}`);
        const uploadResult1 = await uploadBytes(imgStorage1Ref, image);
        img1 = await getDownloadURL(uploadResult1.ref);

        const imgStorage2Ref = storageRef(storage, `requiredImg/${uid}/${image2.name}`);
        const uploadResult2 = await uploadBytes(imgStorage2Ref, image2);
        img2 = await getDownloadURL(uploadResult2.ref);

        const imgStorage3Ref = storageRef(storage, `requiredImg/${uid}/${image3.name}`);
        const uploadResult3 = await uploadBytes(imgStorage3Ref, image3);
        img3 = await getDownloadURL(uploadResult3.ref);
      }

      // Set the user data in Firebase Realtime Database, including the image URL
      const userData = {
        ...additionalData,
        email,
        profileImg: imgUrl,
        primaryId: img1? img1 : '',
        secondaryId: img2? img2 : '',
        eSignature: img3? img3 : '',
      };
      await set(ref(db, 'users/' + uid), userData);

      return { uid, ...userData }; // Return the complete userData including uid
    } catch (error) {
      console.error("Error in createUserAndSaveData:", error);
      throw error; // Rethrow the error to be handled by the caller
    }
  };

  const signIn = async (email, password) => {
      try {
          const userCredential = await signInWithEmailAndPassword(auth, email, password);
          const uid = userCredential.user.uid;

          const userRef = ref(db, 'users/' + uid);

          get(userRef).then((snapshot) => {
              if (snapshot.exists()) {
   
                  if(snapshot.val().userType === "Admin"){
                    toast.success(`Hello There Admin!`);
                  } else if ( snapshot.val().userType === "stallOwner" || snapshot.val().userType === "approvedStallOwner" ){
                    toast.success(`Hello There Stallholder!`);
                  } else {
                    toast.success(`Hello There Trader!`);
                  }
              } else {
                toast.success("Please check your credentials");
              }
          });

          // Redirect or other logic
      } catch (error) {
          console.error("SignIn Error: ", error);
          toast.error("Please check your credentials");
      }
  };

  const logout = () => {
    return signOut(auth);
  };

  useEffect(() => {
    // This will set up the listener for the authenticated user's state
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser); // Set the user or null based on authentication state
      if (currentUser) {
        // Fetch the user type from the database
        const userRef = ref(db, 'users/' + currentUser.uid);
        const snapshot = await get(userRef);
        if (snapshot.exists() && snapshot.val().userType) {
          setUserType(snapshot.val().userType);
          setUserDetails(snapshot.val())
        }
      } else {
        setUserDetails({})
        setUserType(''); // If not logged in, reset userType
      }
      setLoading(false); // Set loading to false as we now have the auth state
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  const contextValue = {
    auth,
    user,
    userDetails,
    userType,
    loading,
    createUserAndSaveData,
    signIn,
    logout,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
