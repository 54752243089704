import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { FaCircleUser } from "react-icons/fa6";
import { RiProfileLine } from "react-icons/ri";
import Tooltip from "@mui/material/Tooltip";
import { useAuthContext } from '../contexts/AuthContext'

import { getDatabase, ref, get, query, orderByChild, equalTo, update } from "firebase/database";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { updateProfile } from "firebase/auth"; // or your custom updateProfile method
import { db, storage } from "../configs/firebase";

import { toast } from 'react-toastify';

export default function RegisterStall(){

	const navigate = useNavigate();

	const { createUserAndSaveData } = useAuthContext();

	const [stallOwnerInfo, setStallOwnerInfo] = useState({
		firstName: '',
		middleName: '',
		lastName: '',
		email: '',
		contactNo: '',
		userType: 'stallOwner',
		userName: '',
		address: '',
		gender: 'Male',
		birthDate: '',
		emergencyContactName: '',
		emergencyContactNo: '',
	});
  	
	const [password, setPassword] = useState('');
	const [password2, setPassword2] = useState('');
	const [profileImg, setProfileImg] = useState('');
	const [validId, setValidId] = useState('');
	const [validId2, setValidId2] = useState('');
	const [eSignature, setESignature] = useState('');
	const [imagePreviewUrl, setImagePreviewUrl] = useState('');
	const [image3, setImage3] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [letter, setLetter] = useState('');

	const [stalls, setStalls] = useState([]);
	const [loading, setLoading] = useState(false);
	const [stallNumber, setStallNumber] = useState();

	// password conditons
	const [showConditions, setShowConditions] = useState("hidden");
	const [hasEnoughCharacters, setHasEnoughCharacters] = useState(false);
	const [hasUpperCase, setHasUpperCase] = useState(false);
	const [hasNumber, setHasNumber] = useState(false);
	const [hasSpecialCharacters, setHasSpecialCharacters] = useState(false);

	// passwords fields are matched
	const [isPasswordMatched, setIsPasswordMatched] = useState(false);

	//pageController
	const [currentPage, setCurrentPage] = useState(1);

	const [selectedFiles, setSelectedFiles] = useState([]);

	const twelveYearsAgo = new Date(new Date().setFullYear(new Date().getFullYear() - 12)).toISOString().split('T')[0];

	const handleImageChangeArr = (e) => {

	  const newFilesArray = Array.from(e.target.files).slice(0, 2 - selectedFiles.length);
	  const combinedFiles = [...selectedFiles, ...newFilesArray].slice(0, 2);
	  setSelectedFiles(combinedFiles);
	  setValidId(combinedFiles[0]);
	  setValidId2(combinedFiles[1]);
	};

	const removeImage = (index) => {
	  // Remove image from state by index
	  setSelectedFiles(selectedFiles.filter((_, idx) => idx !== index));
	};

	//handlers
	const handleChange = (e) => {
		const { name, value } = e.target;
		setStallOwnerInfo(prevState => ({
		  ...prevState,
		  [name]: value
		}));
	};

	const handleImageChange = (e) => {
	  if (e.target.files && e.target.files[0]) {
	    let reader = new FileReader();
	    let file = e.target.files[0];

	    reader.onloadend = () => {
	      setProfileImg(file);
	      setImagePreviewUrl(reader.result);
	    };

	    reader.readAsDataURL(file);
	  }
	};

	const handleImageChange2 = (e) => {
	  if (e.target.files && e.target.files[0]) {
	    let reader = new FileReader();
	    let file = e.target.files[0];

	    reader.onloadend = () => {
	      setESignature(file);
	      setImage3(reader.result);
	    };

	    reader.readAsDataURL(file);
	  }
	};

	function passwordValidator(value) {
	  let hasEnoughCharacters = value.length >= 8;
	  let hasUpperCase = /[A-Z]/.test(value);
	  let hasNumber = /\d/.test(value);
	  let hasSpecialCharacters = /[!@#$%^&*()_,.?\-'"|:;<>]/.test(value);

	  setHasEnoughCharacters(hasEnoughCharacters);
	  setHasUpperCase(hasUpperCase);
	  setHasNumber(hasNumber);
	  setHasSpecialCharacters(hasSpecialCharacters);

	  const isValid =
	    hasEnoughCharacters && hasUpperCase && hasNumber && hasSpecialCharacters;

	  setShowConditions(!isValid);
	  setPassword(value);

	  // Check if the passwords match
	  const isMatched = value === password2;
	  setIsPasswordMatched(isMatched);
	  if (!isMatched) {
	    setShowConditions(true);
	  }
	}

	function passwordMatching(value) {
	  setPassword2(value);
	  if (value === password) {
	    setIsPasswordMatched(true);
	  } else {
	    setIsPasswordMatched(false);
	  }

	  const isValid =
	    value.length >= 8 &&
	    /[A-Z]/.test(value) &&
	    /\d/.test(value) &&
	    /[!@#$%^&*()_,.?\-'"|:;<>]/.test(value);
	  setShowConditions(!isValid);
	}

	const handleSubmitFirst = async (e) => {
		e.preventDefault()
		setCurrentPage(2)
	};

	const handleSubmitSecond = async (e) => {
	  e.preventDefault();
	  if (hasEnoughCharacters && hasUpperCase && hasNumber && hasSpecialCharacters && isPasswordMatched && stallOwnerInfo.userName.length > 3) {
	    try {
	      const userData = await createUserAndSaveData(stallOwnerInfo.email, password, {
	        firstName: stallOwnerInfo.firstName,
	        middleName: stallOwnerInfo.middleName,
	        lastName: stallOwnerInfo.lastName,
	        contactNo: stallOwnerInfo.contactNo,
	        userType: 'stallOwner',
	        status: 'Pending',
	        userName: stallOwnerInfo.userName,
	        address: stallOwnerInfo.address,
	        gender: stallOwnerInfo.gender,
	        emergencyContactName: stallOwnerInfo.emergencyContactName,
	        emergencyContactNo: stallOwnerInfo.emergencyContactNo,
	      }, profileImg, validId, validId2, eSignature);

	      // Navigate to the home page or dashboard after successful registration
	      window.location.href = "/"
	    } catch (error) {
	      console.error("Registration Error: ", error);
	      toast.error("Registration failed: " + error.message);
	    }
	  } else {
	    setCurrentPage(3);
	  }
	};

	const handleSubmit = async (e) => {
	  e.preventDefault();
	  if (hasEnoughCharacters && hasUpperCase && hasNumber && hasSpecialCharacters && isPasswordMatched && stallOwnerInfo.userName.length > 3) {
	    try {
	      const userData = await createUserAndSaveData(stallOwnerInfo.email, password, {
	        firstName: stallOwnerInfo.firstName,
	        middleName: stallOwnerInfo.middleName,
	        lastName: stallOwnerInfo.lastName,
	        contactNo: stallOwnerInfo.contactNo,
	        userType: 'stallOwner',
	        status: 'Pending',
	        stallApplications: [stallNumber],
	        letter: letter,
	        stallsOwned: [],
	        userName: stallOwnerInfo.userName,
	        address: stallOwnerInfo.address,
	        gender: stallOwnerInfo.gender,
	        birthDate: stallOwnerInfo.birthDate,
	        emergencyContactName: stallOwnerInfo.emergencyContactName,
	        emergencyContactNo: stallOwnerInfo.emergencyContactNo,
	      }, profileImg, validId, validId2, eSignature);

	      // Navigate to the home page or dashboard after successful registration
	      window.location.href = "/"
	    } catch (error) {
	      console.error("Registration Error: ", error);
	      toast.error("Registration failed: " + error.message);
	    }
	  } else {
	    setCurrentPage(1);
	  }
	};

	useEffect(() => {
	  const db = getDatabase();
	  const stallsRef = ref(db, 'stalls');
	  setLoading(true);

	  get(stallsRef).then((snapshot) => {
	    if (snapshot.exists()) {
	      let stallsArray = [];
	      snapshot.forEach((childSnapshot) => {
	        const key = childSnapshot.key;
	        const value = childSnapshot.val();
	        stallsArray.push({ uid: key, ...value });
	      });
	      stallsArray = stallsArray.filter(stall => 
	      	stall.status !== 'Unavailable'
	      )
	      setStalls(stallsArray)
	    } else {
	      console.log('No users available');
	    }
	  }).catch((error) => {
	    console.error('Error fetching users:', error);
	  }).finally(() => {
	    setLoading(false);
	  });
	}, []);


	const Test = (
	  <ul
	    className={` text-[#FFFFFF] list-disc text-left mx-2`}
	  >
	    <li className={`text-[10px] ${hasEnoughCharacters ? "hidden" : null}`}>
	      Password must have at least 8 characters in length.
	    </li>
	    <li className={`text-[10px] ${hasUpperCase ? "hidden" : null} `}>
	      Password must contain at least 1 uppercase.
	    </li>
	    <li className={`text-[10px] ${hasNumber ? "hidden" : null}`}>
	      Password must contain at least 1 digit.
	    </li>
	    <li className={`text-[10px] ${hasSpecialCharacters ? "hidden" : null}`}>
	      Password must contain at least 1 special character.
	    </li>
	    <li className={`text-[10px] ${isPasswordMatched ? "hidden" : null} ${hasSpecialCharacters && hasNumber && hasUpperCase && hasEnoughCharacters? "" : "hidden"} ${showConditions}`}>
	      {password2.length === 0? "Please re-type your password" : "Passwords do not match."}
	    </li>
	    <li className={`text-[10px] ${isPasswordMatched ? null : "hidden"} ${showConditions}`}>
	      Passwords Matched
	    </li>
	  </ul>
	);

	const Test2 = (
	  <ul className={`list-disc text-[#FFFFFF]`}>
	    <li className={`text-[10px] ${isPasswordMatched ? "hidden" : null}`}>
	      {password.length !== 0? "Passwords do not match." : "Please enter your password."}
	    </li>
	    <li className={`text-[10px] ${isPasswordMatched ? null : "hidden"}`}>
	      Passwords Matched
	    </li>
	  </ul>
	);


	if(currentPage === 1){
		return(
			<div className="flex flex-row grow w-full h-full px-4 lg:px-36 overflow-x-auto">
			<div className="flex flex-col w-full">
				<h1 className="md:mb-6 text-center pt-12">BE A STALL HOLDER</h1>

				<div className="flex flex-row place-items-center gap-2 w-[60%] md:w-[40%] mx-auto mb-4">
					<div className="flex flex-col items-center justify-center h-8 w-8 rounded-full bg-primary-green text-white text-[18px] font-bold">1</div>
					<div className={`${currentPage === 2? "bg-primary-green" : "bg-secondary-green opacity-60"} grow rounded-[25px] h-[4px] mx-auto`}></div>
					<div className={`flex flex-col items-center justify-center h-8 w-8 rounded-full ${currentPage === 2? "bg-primary-green" : "bg-secondary-green opacity-60"} text-white text-[18px] font-bold`}>2</div>
					<div className={`${currentPage === 2? "bg-primary-green" : "bg-secondary-green opacity-60"} grow rounded-[25px] h-[4px] mx-auto`}></div>
					<div className={`flex flex-col items-center justify-center h-8 w-8 rounded-full ${currentPage === 3? "bg-primary-green" : "bg-secondary-green opacity-60"} text-white text-[18px] font-bold`}>3</div>
				</div>

				<div className="grow mb-10 flex flex-col pt-10 sm:pt-14 w-full justify-center bg-white shadow-2xl rounded-[10px]">

					<form className="grow mx-8 md:mx-24 lg:mx-40 pb-4 flex flex-col" onSubmit={handleSubmitFirst}>
						<div className="grow flex flex-col gap-4 md:gap-6">

							<div className="flex flex-col md:flex-row gap-6 md:gap-4">
								<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
								    <input type="text" id="firstName" name="firstName" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={handleChange} value={stallOwnerInfo.firstName} required />
								    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">First Name:</p>
							    </div>

						    	<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
						    	    <input type="text" id="middleName" name="middleName" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" placeholder="(Optional)" onChange={handleChange} value={stallOwnerInfo.middleName} />
						    	    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Middle Name:</p>
						        </div>

					        	<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
					        	    <input type="text" id="lastName" name="lastName" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={handleChange} value={stallOwnerInfo.lastName} required />
					        	    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Last Name:</p>
					            </div>
							</div>

							<div className="flex flex-col md:flex-row gap-6 md:gap-4">
								<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
								    <input type="email" id="email" name="email" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={handleChange} value={stallOwnerInfo.email} required />
								    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Email:</p>
							    </div>

					        	<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
					        	    <input type="number" id="contactNo" name="contactNo" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={handleChange} value={stallOwnerInfo.contactNo} required />
					        	    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Contact No:</p>
					            </div>
							</div>

							<div className="flex flex-col md:flex-row gap-4 md:gap-6">
								<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
								    <input type="date" id="birthDate" name="birthDate" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={handleChange} max={twelveYearsAgo} value={stallOwnerInfo.birthDate} required />
								    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Birthdate:</p>
							    </div>

								<div className="flex flex-col md:flex-row md:items-center space-x-4 w-full">
								  <p className="font-bold text-primary-green">Gender:</p>
								  <label className="inline-flex items-center">
								    <input type="radio" className="form-radio" name="gender" value="Male" checked={stallOwnerInfo.gender === 'Male'}  onChange={handleChange}/>
								    <span className="ml-2">Male</span>
								  </label>
								  <label className="inline-flex items-center">
								    <input type="radio" className="form-radio" name="gender" value="Female" checked={stallOwnerInfo.gender === 'Female'}  onChange={handleChange}/>
								    <span className="ml-2">Female</span>
								  </label>
								  <label className="inline-flex items-center">
								    <input type="radio" className="form-radio" name="gender" value="Others" checked={stallOwnerInfo.gender === 'Others'}  onChange={handleChange}/>
								    <span className="ml-2">Others</span>
								  </label>
								</div>
							</div>

							<div className="flex flex-col md:flex-row gap-4 md:gap-6">
								<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
								    <input type="text" id="emergencyContactName" name="emergencyContactName" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={handleChange} value={stallOwnerInfo.emergencyContactName} required />
								    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Emergency Contact FullName:</p>
							    </div>

								<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
								    <input type="text" id="emergencyContactNo" name="emergencyContactNo" className="h-full bg-white rounded-[10px] outline-none w-full pl-4"onChange={handleChange} value={stallOwnerInfo.emergencyContactNo} required/>
								    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Emergency Contact No:</p>
							    </div>
							</div>

							<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
							    <input type="text" id="address" name="address" className="h-full bg-white rounded-[10px] outline-none w-full pl-4"onChange={handleChange} value={stallOwnerInfo.address} required/>
							    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Address:</p>
						    </div>

						</div>
						<div className="flex flex-row justify-end">
							<button
								type="submit"
								className="rounded-[10px] h-[40px] mt-10 bg-primary-green bg-[#BDC4CB] text-white font-bold w-[70px]"
							>
							Next
							</button>
						</div>
					</form>

				</div>

			</div>
			</div>
		
		);
	}

	if(currentPage === 2){

		return(
			<div className="flex flex-row grow w-full h-full px-4 lg:px-36 overflow-x-auto">
			<div className="flex flex-col w-full">
				<h1 className="md:mb-6 text-center pt-12">BE A STALL HOLDER</h1>

				<div className="flex flex-row place-items-center gap-2 w-[60%] md:w-[40%] mx-auto mb-4">
					<div className="flex flex-col items-center justify-center h-8 w-8 rounded-full bg-primary-green text-white text-[18px] font-bold">1</div>
					<div className={`${currentPage === 2? "bg-primary-green" : "bg-secondary-green opacity-60"} grow rounded-[25px] h-[4px] mx-auto`}></div>
					<div className={`flex flex-col items-center justify-center h-8 w-8 rounded-full ${currentPage === 2? "bg-primary-green" : "bg-secondary-green opacity-60"} text-white text-[18px] font-bold`}>2</div>
					<div className={`${currentPage === 3? "bg-primary-green" : "bg-secondary-green opacity-60"} grow rounded-[25px] h-[4px] mx-auto`}></div>
					<div className={`flex flex-col items-center justify-center h-8 w-8 rounded-full ${currentPage === 3? "bg-primary-green" : "bg-secondary-green opacity-60"} text-white text-[18px] font-bold`}>3</div>
				</div>

				<div className="grow mb-10 flex flex-col pt-10 sm:pt-14 w-full justify-center bg-white shadow-2xl rounded-[10px]">

					<form className="grow mx-8 md:mx-24 lg:mx-40 pb-4 flex flex-col" onSubmit={handleSubmitSecond}>
						<div className="grow flex flex-col gap-4 md:gap-6">

							<div className="flex flex-col gap-6 md:gap-4 my-auto">

								<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-max mx-auto">
									<select
									  name="stallNumber"	
									  className="h-full bg-white rounded-[10px] outline-none w-full pl-4"
									  onChange={(e) => setStallNumber(e.target.value)}
									  value={stallNumber}
									  required
									>
									  <option value="">Select Stall Number</option>
									  {
									  	stalls.map(stall => {
									  		return <option value={`${stall.stallNumber}`}>{stall.stallNumber}</option>
									  	})
									  }	
									</select>
									<label className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Stall Number:</label>
								</div>

								<div className="flex flex-col items-center w-full lg:w-max lg:mx-auto border p-8">
								  <p className="text-[14px] mb-4">Upload your Primary and Secondary IDs here:</p>
								  <input
								    type="file"
								    accept="image/*"
								    multiple
								    onChange={handleImageChangeArr}
								    disabled={selectedFiles.length >= 2}
								    required
								  />
								  {selectedFiles.length > 0 && (
								    <div className="image-preview-container flex flex-col lg:flex-row">
								      {selectedFiles.map((file, index) => (
								        <div key={file.name} className="image-preview">
								          <img
								            src={URL.createObjectURL(file)}
								            alt={`Preview ${index + 1}`}
								            style={{ width: '150px', height: '150px' }}
								          />
								          <button onClick={() => removeImage(index)}>Remove</button>
								        </div>
								      ))}
								    </div>
								  )}
								</div>
							</div>

							<div>
								{
									image3?
					              	<img src={image3} alt="Profile Preview" className="mx-auto h-24 w-72 object-fill" />
					            	:
					            	null
					            }

							</div>

							<div className="relative top-0 mx-auto">
								<button className="bg-secondary-green w-max px-2 font-bold h-10 text-white rounded-lg">
								Upload E-Signature Image
								</button>
								<input
								type="file"
								id="imageInput2"
								name="imageInput2"
								accept="image/*"
								className="absolute top-0 left-0 w-full h-full opacity-0"
								onChange={handleImageChange2}
								required
								/>
							</div>

							<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full min-h-[150px]">
							    <textarea type="text" className="h-full max-h-full bg-white rounded-[10px] outline-none w-full pl-4 pt-2" placeholder="Please fill out this form to write your application letter" onChange={(e) => setLetter(e.target.value)} required />
							    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Application Letter:</p>
						    </div>

						</div>
						<div className="flex flex-row justify-end gap-6">
							<button
								type="submit"
								className="rounded-[10px] h-[40px] mt-10 bg-primary-green bg-[#BDC4CB] text-white font-bold w-[70px]"
							>
							Next
							</button>
						</div>
					</form>

				</div>

			</div>
			</div>
		);

	}

	return(
		<div className="flex flex-row grow w-full h-full px-4 lg:px-36 overflow-x-auto">
		<div className="flex flex-col w-full">
			<h1 className="md:mb-6 text-center pt-12">BE A STALL HOLDER</h1>

			<div className="flex flex-row place-items-center gap-2 w-[60%] md:w-[40%] mx-auto mb-4">
				<div className="flex flex-col items-center justify-center h-8 w-8 rounded-full bg-primary-green text-white text-[18px] font-bold">1</div>
				<div className={`${currentPage === 3? "bg-primary-green" : "bg-secondary-green opacity-60"} grow rounded-[25px] h-[4px] mx-auto`}></div>
				<div className={`flex flex-col items-center justify-center h-8 w-8 rounded-full ${currentPage === 3? "bg-primary-green" : "bg-secondary-green opacity-60"} text-white text-[18px] font-bold`}>2</div>
				<div className={`${currentPage === 3? "bg-primary-green" : "bg-secondary-green opacity-60"} grow rounded-[25px] h-[4px] mx-auto`}></div>
				<div className={`flex flex-col items-center justify-center h-8 w-8 rounded-full ${currentPage === 3? "bg-primary-green" : "bg-secondary-green opacity-60"} text-white text-[18px] font-bold`}>3</div>
			</div>

			<div className="grow mb-10 flex flex-col pt-10 sm:pt-14 w-full justify-center bg-white shadow-2xl rounded-[10px]">

				<form className="grow mx-8 md:mx-24 lg:mx-40 pb-4 flex flex-col" onSubmit={handleSubmit}>
					<div className="grow flex flex-col gap-4 md:gap-6">

						<div className="flex flex-col gap-6 md:gap-4 my-auto">

							<div>
								{
									imagePreviewUrl?
					              	<img src={imagePreviewUrl} alt="Profile Preview" className="mx-auto h-20 w-20 object-cover rounded-full" />
					            	:
					            	<FaCircleUser className="mx-auto h-20 w-20 object-cover rounded-full" />
					            }

							</div>

							<div className="relative top-0 mx-auto">
								<p className="text-center">2x2 Image</p>
								<button className="bg-secondary-green w-40 h-10 text-white rounded-lg">
								Upload Image
								</button>
								<input
								type="file"
								id="imageInput"
								name="imageInput"
								accept="image/*"
								className="absolute top-0 left-0 w-full h-full opacity-0"
								onChange={handleImageChange}
								/>
							</div>

							<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full md:w-1/2 mx-auto">
							    <input type="text" id="userName" name="userName" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={handleChange} value={stallOwnerInfo.userName} />
							    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">User Name:</p>
						    </div>

					    	<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full md:w-1/2 mx-auto">
					    	    <Tooltip title={Test} arrow>
					    	    	<input type={showPassword? "text" : "password"} id="password" name="password" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={(e) => passwordValidator(e.target.value)} value={password} />
					    	    </Tooltip>
					    	    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Password:</p>
					        </div>

				        	<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full md:w-1/2 mx-auto">
				        	    <Tooltip title={Test2} arrow>
				        	    	<input type={showPassword? "text" : "password"} id="password2" name="password2" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={(e) => passwordMatching(e.target.value)} value={password2} />
				        	    </Tooltip>
				        	    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Re-Type Password:</p>
				            </div>
			            	<label className="md:w-1/2 md:mx-auto">
			                    <input 
			                      type="checkbox" 
			                      className="form-checkbox" 
			                      checked={showPassword}
			                      onChange={() => setShowPassword(!showPassword)}
			                    />
			                    <span className="ml-2">Show Password</span>
			                </label>
						</div>

					</div>
					<div className="flex flex-row justify-end gap-6">
						<button
							type="submit"
							className="rounded-[10px] h-[40px] mt-10 bg-primary-gray text-white font-bold w-[80px]"
							// onClick={() => setCurrentPage(1)}
						>
						Go Back
						</button>
						{
							hasEnoughCharacters && hasUpperCase && hasNumber && hasSpecialCharacters && isPasswordMatched && stallOwnerInfo.userName.length > 3?
							<button
								type="submit"
								className="rounded-[10px] h-[40px] mt-10 bg-primary-green bg-[#BDC4CB] text-white font-bold w-[70px]"					
							>
							Submit
							</button>
							:
							<button
								type="submit"
								disabled
								className="rounded-[10px] h-[40px] mt-10 bg-primary-green opacity-70 text-white font-bold w-[70px]"
							>
							Submit
							</button>
						}
					</div>
				</form>

			</div>

		</div>
		</div>
	);

};