import { useNavigate , useMatch } from "react-router-dom";
import { FaRegNewspaper } from "react-icons/fa6";
import { VscGraphLine, VscFeedback } from "react-icons/vsc";
import { FaUser } from "react-icons/fa";
import { HiMiniBuildingStorefront } from "react-icons/hi2";
import { GrMoney } from "react-icons/gr";
import { MdFeedback } from "react-icons/md";
import { GiFruitBowl } from "react-icons/gi";
import { BsClipboard2DataFill } from "react-icons/bs";
import { RiLogoutBoxLine } from "react-icons/ri";
import { FaMapMarkedAlt } from "react-icons/fa";
import { MdOutlinePayment } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";

import { useAuthContext } from '../contexts/AuthContext';

import { useEffect } from 'react';

export default function Sidebar({ hide }){

	const navigate = useNavigate();
	const { userType, logout } = useAuthContext();

	const isNewsBoard = useMatch("/")
	const isPrice = useMatch("/price-monitoring");
	const isAccounts = useMatch("/manage-accounts");
	const isStalls = useMatch("/manage-stalls");
	const isPayments = useMatch("/manage-payments");
	const isFeedbacks = useMatch("/manage-feedbacks");
	const isGoods = useMatch("/select-goods");
	const isReports = useMatch("/reports");

	const isMap = useMatch("/map");
	const isPayment = useMatch("/payment");
	const isOwnedStalls = useMatch("/stalls");
	const isFeedback = useMatch("/feedback");
	const isProfile = useMatch("/profile")

	if(hide)return null

	return (

		<div className="sticky top-0 bg-secondary-green min-h-full min-w-[220px] py-8 hidden lg:flex flex-col gap-4">

			<div className="flex flex-row">
				<div className={`w-[5px] ${isNewsBoard? "bg-white" : "hidden"} rounded-tr-md rounded-br-md`}></div>
				<button 
				onClick={() => {navigate("/")}} 
				className="flex flex-row items-center gap-4 pl-6 text-white font-bold w-full h-10 hover:opacity-70">
					<FaRegNewspaper />
					<p >News Board</p>
				</button>
			</div>

			{
				userType === "Admin"?
				<>
					<div className="flex flex-row">
						<div className={`w-[5px] ${isPrice? "bg-white" : "hidden"} rounded-tr-md rounded-br-md`}></div>
						<button 
						onClick={() => {navigate("/price-monitoring")}} 
						className="flex flex-row items-center gap-4 pl-6 text-white font-bold w-full h-10 hover:opacity-70">
							<VscGraphLine />
							<p >Price Monitoring</p>
						</button>
					</div>

					<div className="flex flex-row">
						<div className={`w-[5px] ${isAccounts? "bg-white" : "hidden"} rounded-tr-md rounded-br-md`}></div>
						<button 
						onClick={() => {navigate("/manage-accounts")}}  
						className="flex flex-row items-center gap-4 pl-6 text-white font-bold w-full h-10 hover:opacity-70">
							<FaUser />
							<p >Manage Accounts</p>
						</button>
					</div>

					<div className="flex flex-row">
						<div className={`w-[5px] ${isStalls? "bg-white" : "hidden"} rounded-tr-md rounded-br-md`}></div>
						<button 
						onClick={() => {navigate("/manage-stalls")}} 
						className="flex flex-row items-center gap-4 pl-6 text-white font-bold w-full h-10 hover:opacity-70">
							<HiMiniBuildingStorefront />
							<p >Manage Stalls</p>
						</button>
					</div>

					<div className="flex flex-row">
						<div className={`w-[5px] ${isPayments? "bg-white" : "hidden"} rounded-tr-md rounded-br-md`}></div>
						<button 
						onClick={() => {navigate("/manage-payments")}} 
						className="flex flex-row items-center gap-4 pl-6 text-white font-bold w-full h-10 hover:opacity-70">
							<GrMoney />
							<p >Manage Payments</p>
						</button>
					</div>

					<div className="flex flex-row">
						<div className={`w-[5px] ${isFeedbacks? "bg-white" : "hidden"} rounded-tr-md rounded-br-md`}></div>
						<button 
						onClick={() => {navigate("/manage-feedbacks")}} 
						className="flex flex-row items-center gap-4 pl-6 text-white font-bold w-full h-10 hover:opacity-70">
							<MdFeedback />
							<p >Manage Feedbacks</p>
						</button>
					</div>

					<div className="flex flex-row">
						<div className={`w-[5px] ${isGoods? "bg-white" : "hidden"} rounded-tr-md rounded-br-md`}></div>
						<button 
						onClick={() => {navigate("/select-goods")}} 
						className="flex flex-row items-center gap-4 pl-6 text-white font-bold w-full h-10 hover:opacity-70">
							<GiFruitBowl />
							<p >Select Goods</p>
						</button>
					</div>

					<div className="flex flex-row">
						<div className={`w-[5px] ${isReports? "bg-white" : "hidden"} rounded-tr-md rounded-br-md`}></div>
						<button 
						onClick={() => {navigate("/reports")}} 
						className="flex flex-row items-center gap-4 pl-6 text-white font-bold w-full h-10 hover:opacity-70">
							<BsClipboard2DataFill />
							<p>Reports</p>
						</button>
					</div>
				</>
				:
				<>
					<div className="flex flex-row">
						<div className={`w-[5px] ${isMap? "bg-white" : "hidden"} rounded-tr-md rounded-br-md`}></div>
						<button 
						onClick={() => {navigate("/map")}} 
						className="flex flex-row items-center gap-4 pl-6 text-white font-bold w-full h-10 hover:opacity-70">
							<FaMapMarkedAlt />
							<p >Map</p>
						</button>
					</div>

					{
						userType === "stallOwner" || userType === "approvedStallOwner"?
						<>
							<div className="flex flex-row">
								<div className={`w-[5px] ${isOwnedStalls? "bg-white" : "hidden"} rounded-tr-md rounded-br-md`}></div>
								<button 
								onClick={() => {navigate("/stalls")}}  
								className="flex flex-row items-center gap-4 pl-6 text-white font-bold w-full h-10 hover:opacity-70">
									<HiMiniBuildingStorefront />
									<p >Stalls</p>
								</button>
							</div>
							<div className="flex flex-row">
								<div className={`w-[5px] ${isPayment? "bg-white" : "hidden"} rounded-tr-md rounded-br-md`}></div>
								<button 
								onClick={() => {navigate("/payment")}}  
								className="flex flex-row items-center gap-4 pl-6 text-white font-bold w-full h-10 hover:opacity-70">
									<MdOutlinePayment />
									<p >Payment</p>
								</button>
							</div>
						</>
						:
						<div className="flex flex-row">
							<div className={`w-[5px] ${isProfile? "bg-white" : "hidden"} rounded-tr-md rounded-br-md`}></div>
							<button 
							onClick={() => {navigate("/profile")}}  
							className="flex flex-row items-center gap-4 pl-6 text-white font-bold w-full h-10 hover:opacity-70">
								<FaUserCircle />
								<p >Trader's ID</p>
							</button>
						</div>
					}

					<div className="flex flex-row">
						<div className={`w-[5px] ${isFeedback? "bg-white" : "hidden"} rounded-tr-md rounded-br-md`}></div>
						<button 
						onClick={() => {navigate("/feedback")}} 
						className="flex flex-row items-center gap-4 pl-6 text-white font-bold w-full h-10 hover:opacity-70">
							<VscFeedback />
							<p >Feedback</p>
						</button>
					</div>
				</>
			}

			<div className="grow flex items-end justify-center">

				<button 
				onClick={() => {
					navigate("/")
					logout()
				}}
				className="font-bold text-white flex items-center gap-2 hover:opacity-70">
					<RiLogoutBoxLine /> 
					Logout
				</button>
			</div>

		</div>

	);
}