import { useState, useEffect } from 'react';
import { ref as dbRef, query, orderByChild, equalTo, get, push, set as dbSet } from "firebase/database";
import { db } from "../configs/firebase";
import { toast } from 'react-toastify';
import { useAuthContext } from '../contexts/AuthContext';
import { LimitText } from '../components/LimitText'
import { MdSms } from "react-icons/md";


const AddItemModal = ({isVisible, toggleAddItemModal, setRows}) => {

	const { user } = useAuthContext();

	const [title, setTitle] = useState('');
	const [message, setMessage] = useState(0);
	const handleSaveItem = async (e) => {
		e.preventDefault();

		const feedbackData = {
		  title,
		  message,
		  userId: user.uid,
		  response: '',
		  isRead: false,
		  adminRead: false,
		  createdAt: Date.now(),
		  updatedAt: Date.now(),
		};

		try {
		  const feedbackRef = dbRef(db, 'feedbacks');
		  const newFeedbackRef = push(feedbackRef);
		  await dbSet(newFeedbackRef, feedbackData);
		  toast.success("Commodity saved successfully!");
		  const newFeedbackData = { ...feedbackData, id: newFeedbackRef.key };
		  setRows(prevRows => [newFeedbackData, ...prevRows]);
		  toggleAddItemModal();
		} catch (error) {
		  console.error("Error saving commodities:", error);
		  toast.error("Failed to save commodities.");
		}
	}

	if(!isVisible)return null

	return (

		<div className={`fixed z-50 inset-0 flex items-center justify-center overflow-y-auto backdrop-blur-sm py-8`}>

			<form onSubmit={handleSaveItem} className="flex flex-col max-w-[500px] w-full bg-white rounded-lg shadow-2xl mx-2 my-auto p-4 gap-4">
				<h2 className="text-center">Add Feedback</h2>

				<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
				    <input type="text" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={(e) => setTitle(e.target.value)} required />
				    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Title:</p>
			    </div>

		    	<div className="relative top-0 border shadow-md rounded-[10px] outline-none w-full min-h-[200px]">
		    	    <textarea type="text" className="h-full min-h-[200px] max-h-full bg-white rounded-[10px] outline-none w-full pl-4 pt-2" onChange={(e) => setMessage(e.target.value)} required />
		    	    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Message:</p>
		        </div>


		        <div className="flex flex-row w-full gap-4">
		        	<button 
		        	type="button" 
		        	onClick={() => toggleAddItemModal()} 
		        	className="w-full border-2 border-primary-green min-h-[40px] rounded-xl px-4 font-bold 
		        	text-primary-green mt-4 hover:opacity-70">
		        		Cancel
		        	</button>
		        	<button type="submit" className="w-full bg-primary-green min-h-[40px] rounded-xl px-4 font-bold text-white mt-4 hover:opacity-70">
		        		Send
		        	</button>
		        </div>

			</form>

		</div>

	)
}

const ViewModal = ({isVisible, toggleViewModal, feedback}) => {

	if(!isVisible)return null

	return (

		<div className={`fixed z-50 inset-0 flex items-center justify-center overflow-y-auto backdrop-blur-sm py-8`}>

			<div className="flex flex-col max-w-[500px] min-h-[300px] w-full bg-white rounded-lg shadow-2xl mx-2 my-auto p-4 gap-4">
				{
					feedback.response?
					<div className="grow font-semibold">{feedback.response}</div>
					:
					<div className="grow flex items-center justify-center font-bold text-primary-gray">Please wait for the admin's response.</div>
				}
				<div className="flex flex-row w-full gap-4">
					<button onClick={toggleViewModal} className="w-full border-2 border-primary-green min-h-[40px] rounded-xl px-4 font-bold 
			        	text-primary-green mt-4 hover:opacity-70">
						Close
					</button>
				</div>
			</div>
		</div>
	)
}

export default function Feedback(){

	const [addModal, setAddModal] = useState(false);
	const [viewModal, setViewModal] = useState(false);
	const [feedbackData, setFeedbackData] = useState({});
	const [rows, setRows] = useState([]);

	const { user } = useAuthContext();

	const toggleAddModal = () => {
		setAddModal(!addModal);
	}

	const toggleViewModal = () => {
		setViewModal(!viewModal);
	}

	function formatTimestamp(timestamp) {
	  const date = new Date(timestamp);
	  const year = date.getFullYear().toString().slice(-2); // Get the last two digits
	  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
	  const day = date.getDate().toString().padStart(2, '0');

	  // Convert 24h time to 12h time format
	  let hours = date.getHours();
	  const ampm = hours >= 12 ? 'PM' : 'AM';
	  hours = hours % 12;
	  hours = hours ? hours.toString().padStart(2, '0') : '12'; // the hour '0' should be '12'
	  
	  const minutes = date.getMinutes().toString().padStart(2, '0');
	  const seconds = date.getSeconds().toString().padStart(2, '0');

	  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${ampm}`;
	}

	useEffect(() => {
	  if (user && user.uid) {
	    const fetchFeedback = async () => {
	      const feedbackRef = dbRef(db, 'feedbacks');
	      // Order by 'createdAt' and filter by 'userId'
	      const feedbackQuery = query(feedbackRef, orderByChild('userId'), equalTo(user.uid));

	      try {
	        const snapshot = await get(feedbackQuery);
	        if (snapshot.exists()) {
	          const feedbackData = [];
	          snapshot.forEach(childSnapshot => {
	            feedbackData.push({ id: childSnapshot.key, ...childSnapshot.val() });
	          });
	          // Since Firebase orders ascendingly, reverse to get newest first
	          setRows(feedbackData.reverse());
	        } else {
	          console.log("No feedback available");
	        }
	      } catch (error) {
	        console.error("Error fetching feedback:", error);
	      }
	    };

	    fetchFeedback();
	  }
	}, [user]);

	return (

		<div className="flex flex-col items-center w-full px-4">
			<AddItemModal isVisible={addModal} toggleAddItemModal={toggleAddModal} setRows={setRows}/>
			<ViewModal isVisible={viewModal} toggleViewModal={toggleViewModal} feedback={feedbackData}/>

		    <h1 className="text-center mt-6">Feedback</h1>
		    <div className="w-[60px] mx-auto border-2 border-primary-green rounded-xl mb-6"></div>

		    <div className="w-full flex justify-end my-4">
		    	<button onClick={toggleAddModal} className="bg-primary-green h-10 rounded-xl px-4 font-bold text-white hover:opacity-70">+ Add Feedback</button>
		    </div>

		    <div className="flex flex-col items-center justify-center w-full">
		    {
		    	rows.map((feedback) => {
		    		return(
		    			<div
		    			className="flex flex-col shadow-xl bg-white w-full max-w-[800px] overflow-hidden p-4 rounded-xl mb-4" 
		    			onClick={() => {
		    				setFeedbackData(feedback)
		    				toggleViewModal();
		    			}} 
		    			>
		    				<div className="flex w-full">
			    				<div className="w-full overflow-hidden">
			    					<LimitText text={feedback.title} classNameProp={`text-[20px] font-semibold single-line-ellipsis`} isOpen={false}/>
			    				</div>
			    				{
			    					feedback.response?
			    					<MdSms className="w-7 h-7 text-primary-green"/>
			    					:
			    					null
			    				}
		    				</div>
		    				<p className="text-[12px] text-primary-gray mb-2">{formatTimestamp(feedback.createdAt)}</p>
		    				<div className="w-full overflow-hidden">
		    					<LimitText text={feedback.message} classNameProp={`single-line-ellipsis`} isOpen={false}/>
		    				</div>
		    			</div>
		    		)
		    	})
		    }
		    </div>

		    {
		    	rows.length === 0?
		    	<div className="grow flex items-center border-t border-primary-gray w-full justify-center">
		    		<div className="bg-white p-8 rounded-xl shadow-xl">
		    			<p className="text-primary-gray font-bold">Share us your feedback!</p>
		    		</div>
		    	</div>
		    	:
		    	null
		    }

		</div>

	);
};