import React, { useState, useEffect } from 'react';
import { getDatabase, ref, get } from "firebase/database";
import { db } from "../configs/firebase";
import defaultStallImg from '../assets/next-two.png';
import stallGuide from '../assets/stallGuide.jpg';


const StallModal = ({ isVisible, toggleModal, modalData, setRoad, selectedGrid ,setSelectedGrid }) => {

  const [userDetails, setUserDetails] = useState({});

	const { metaData, road, gridNum } = modalData

  const fetchUserDetails = async (userId) => {
    const userRef = ref(db, 'users/' + userId);
    try {
      const snapshot = await get(userRef);
      if (snapshot.exists()) {
        return snapshot.val();
      } else {
        console.log("No data available for user: " + userId);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchAllUserDetails = async () => {
      const allUserDetails = {};
      const stalls = [metaData]
      const fetchPromises = stalls.map(async (stall) => {
        if (stall.stallOwner) {
          const details = await fetchUserDetails(stall.stallOwner);
          allUserDetails[stall.stallOwner] = details;
        }
      });
      await Promise.all(fetchPromises);
      setUserDetails(allUserDetails);
    };

    if(metaData){
      fetchAllUserDetails();
    }
  }, [isVisible]);

	if(!isVisible){
		return null
	}
	return (
		<div className={`fixed z-50 inset-0 flex items-start justify-center overflow-y-auto backdrop-blur-sm py-8`}>
      <div className="flex flex-col max-w-[500px] min-h-[400px] w-full bg-white rounded-lg shadow-2xl mx-2 my-auto p-4 gap-4">

        <div className="flex flex-col gap-2  grow">

          <div className="relative w-full pt-[56.25%] overflow-hidden bg-white shadow-xl group">
            <img 
            src={metaData.stallImg} 
            alt="Stall Img" 
            className="absolute top-0 left-0 bottom-0 right-0 object-cover rounded-xl h-full w-full"
            onError={(e) => {
              e.target.src = `${defaultStallImg}`
              e.target.className = "absolute top-0 left-0 bottom-0 right-0 object-cover rounded-xl h-full w-full opacity-40"
            }}
            />
           </div>

          <p className="text-center">{metaData.stallName? metaData.stallName : `# ${metaData.stallNumber}`}</p>
          <div className="flex flex-row gap-2">
            <div className="w-max rounded-md px-2 bg-primary-gray"><p className="text-[14px] text-white"># {metaData.stallNumber}</p></div>
            <div className="w-max rounded-md px-2 bg-primary-gray"><p className="text-[14px] text-white">{metaData.stallSize}</p></div>
            <div className={`w-max rounded-md px-2 ${metaData.status === "Unavailable"? "bg-danger/[.70]" : "bg-primary-green/[.70]"}`}><p className="text-[14px] text-white">{metaData.status}</p></div>
          </div>
          <p>Amount: Php {metaData.stallAmount}</p>
          <p>Stall Owner: {metaData.stallOwner? userDetails[metaData.stallOwner]?.email : "N/A"}</p>
          <p>Prodcue: {metaData.stallProduce? metaData.stallProduce : "N/A"}</p>
          <p>Description: {metaData.stallDescription}</p>

        </div>
            <div className="flex flex-row w-full gap-4">
              <button 
              type="button" 
              onClick={() => toggleModal()} 
              className="w-full border-2 border-primary-green min-h-[40px] rounded-xl px-4 font-bold 
              text-primary-green mt-4 hover:opacity-70">
                Close
              </button>
              {
                selectedGrid === gridNum?
                <button 
                type="button" 
                onClick={() => {
                  toggleModal()
                  setRoad([])
                  setSelectedGrid('')
                }} 
                className="w-full bg-danger/[.80] min-h-[40px] rounded-xl px-4 font-bold 
                text-white mt-4 hover:opacity-70">
                  Remove Direction
                </button>
                :
                <button 
                type="button" 
                onClick={() => {
                  toggleModal()
                  setRoad(road)
                  setSelectedGrid(gridNum)
                }} 
                className="w-full bg-primary-green min-h-[40px] rounded-xl px-4 font-bold 
                text-white mt-4 hover:opacity-70">
                  Directions
                </button>
              }
            </div>
      </div>

    </div>
	)
}

export default function Map() {
  // Initialize the gridData state with an empty array
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [stallsData, setStallsData] = useState([]);
  const [selectedGrid, setSelectedGrid] = useState();	
  const [gridData, setGridData] = useState([]);
  const [stalls, setStalls] = useState([129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142, 
  	143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 99, 40, 41, 42, 43, 44, 
  	45, 46, 47, 48, 49, 306, 307, 308, 309, 310, 311, 312, 313, 314, 315, 316, 317, 318, 319, 320, 321, 322, 
  	323, 324, 325, 326, 327, 328, 329, 330, 331, 332, 333, 573, 572, 571, 570, 569, 568, 567, 566, 565, 564, 
  	563, 561, 562, 560, 559, 558, 557, 556, 555, 554, 553, 552, 551, 549, 550, 546, 547, 548, 722, 723, 724, 
  	725, 726, 727, 728, 729, 730, 731, 732, 733, 734, 735, 736, 737, 738, 739, 740, 741, 742, 743, 744, 745, 
  	746, 807, 927, 867, 987, 1047, 1107, 1167, 1227, 1287, 1347, 1407, 1467, 1527, 1587, 1647, 1707, 1767, 
  	1827, 1887, 1947, 2007, 2067, 2127, 770, 769, 768, 767, 766, 765, 764, 763, 762, 761, 760, 759, 758, 757, 756, 
  	110, 170, 230, 350, 290, 816, 876, 936, 996, 1056, 1116, 1176, 1236, 1296, 1356, 1416, 1476, 1536, 
  	1596, 1656, 1716, 1776, 1836, 1896, 1956, 2016, 2076, 2136, 2196, 2256, 2316, 2376, 2436, 2496]);

  const [miniStalls, setMiniStalls] = useState([
  	305, 334, 545, 574, 306, 307, 308, 309, 310, 311, 312, 313, 314, 315, 316, 317, 318, 319, 320, 321, 322, 323, 324, 325, 326,
  	327, 328, 329, 330, 331, 332, 333, 573, 572, 571, 570, 569, 568, 
  	567, 566, 565, 564, 563, 561, 562, 560, 559, 558, 557, 556, 555, 554, 553, 552, 551, 549, 550, 546, 547, 548, 
  ]);

  const [red, setRed] = useState([
  	305, 334, 545, 574, 245, 246, 247, 248, 249, 250, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261, 
  	262, 382, 381, 380, 379, 378, 377, 376, 375, 374, 373, 372, 371, 370, 369, 368, 367, 366, 365, 485, 486, 
  	487, 488, 489, 490, 491, 492, 493, 494, 495, 496, 497, 498, 499, 500, 501, 502, 622, 621, 620, 619, 618, 
  	617, 616, 615, 614, 613, 612, 611, 610, 609, 608, 606, 607, 605, 425, 442
  ]);

  const [gray, setGray] = useState([
  	121, 122, 181, 182, 241, 242, 301, 302, 361, 362, 421, 422, 481, 482, 541, 542, 601, 602, 344, 345, 346, 
  	347, 441, 440, 439, 438, 437, 436, 435, 434, 433, 432, 431, 430, 429, 428, 427, 426, 407, 406, 405, 404, 
  	403, 402, 401, 400, 399, 398
  ]);

  const [road, setRoad] = useState([]);

  const stallDetails = [
    {
      stallNumber: 1,
      gridNum: 333,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 444, 384, 383, 382, 381]
    },
    {
      stallNumber: 2,
      gridNum: 332,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 444, 384, 383, 382, 381]
    },
    {
      stallNumber: 3,
      gridNum: 331,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 444, 384, 383, 382, 381, 380]
    },
    {
      stallNumber: 4,
      gridNum: 330,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 444, 384, 383, 382, 381, 380]
    },
    {
      stallNumber: 5,
      gridNum: 329,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 444, 384, 383, 382, 381, 380, 379]
    },
    {
      stallNumber: 6,
      gridNum: 328,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 444, 384, 383, 382, 381, 380, 379]
    },
    {
      stallNumber: 7,
      gridNum: 327,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 444, 384, 383, 382, 381, 380, 379, 378]
    },
    {
      stallNumber: 8,
      gridNum: 326,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 444, 384, 383, 382, 381, 380, 379, 378, 377]
    },
    {
      stallNumber: 9,
      gridNum: 325,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 444, 384, 383, 382, 381, 380, 379, 378, 377]
    },
    {
      stallNumber: 10,
      gridNum: 324,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 444, 384, 383, 382, 381, 380, 379, 378, 377, 376]
    },
    {
      stallNumber: 11,
      gridNum: 323,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 444, 384, 383, 382, 381, 380, 379, 378, 377, 376]
    },
    {
      stallNumber: 12,
      gridNum: 322,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 444, 384, 383, 382, 381, 380, 379, 378, 377, 376, 375]
    },
    {
      stallNumber: 13,
      gridNum: 321,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 444, 384, 383, 382, 381, 380, 379, 378, 377, 376, 375, 374]
    },
    {
      stallNumber: 14,
      gridNum: 320,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 444, 384, 383, 382, 381, 380, 379, 378, 377, 376, 375, 374]
    },
    {
      stallNumber: 15,
      gridNum: 319,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 444, 384, 383, 382, 381, 380, 379, 378, 377, 376, 375, 374, 373]
    },
    {
      stallNumber: 16,
      gridNum: 318,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 444, 384, 383, 382, 381, 380, 379, 378, 377, 376, 375, 374, 373]
    },
    {
      stallNumber: 17,
      gridNum: 317,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 444, 384, 383, 382, 381, 380, 379, 378, 377, 376, 375, 374, 373, 372]
    },
    {
      stallNumber: 18,
      gridNum: 316,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 444, 384, 383, 382, 381, 380, 379, 378, 377, 376, 375, 374, 373, 372, 371]
    },
    {
      stallNumber: 19,
      gridNum: 315,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 444, 384, 383, 382, 381, 380, 379, 378, 377, 376, 375, 374, 373, 372, 371]
    },
    {
      stallNumber: 20,
      gridNum: 314,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 444, 384, 383, 382, 381, 380, 379, 378, 377, 376, 375, 374, 373, 372, 371, 370]
    },
    {
      stallNumber: 21,
      gridNum: 313,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 444, 384, 383, 382, 381, 380, 379, 378, 377, 376, 375, 374, 373, 372, 371, 370]
    },
    {
      stallNumber: 22,
      gridNum: 312,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 444, 384, 383, 382, 381, 380, 379, 378, 377, 376, 375, 374, 373, 372, 371, 370, 369]
    },
    {
      stallNumber: 23,
      gridNum: 311,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 444, 384, 383, 382, 381, 380, 379, 378, 377, 376, 375, 374, 373, 372, 371, 370, 369, 368]
    },
    {
      stallNumber: 24,
      gridNum: 310,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 444, 384, 383, 382, 381, 380, 379, 378, 377, 376, 375, 374, 373, 372, 371, 370, 369, 368]
    },
    {
      stallNumber: 25,
      gridNum: 309,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 444, 384, 383, 382, 381, 380, 379, 378, 377, 376, 375, 374, 373, 372, 371, 370, 369, 368, 367]
    },
    {
      stallNumber: 26,
      gridNum: 308,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 444, 384, 383, 382, 381, 380, 379, 378, 377, 376, 375, 374, 373, 372, 371, 370, 369, 368, 367]
    },
    {
      stallNumber: 27,
      gridNum: 307,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 444, 384, 383, 382, 381, 380, 379, 378, 377, 376, 375, 374, 373, 372, 371, 370, 369, 368, 367, 366]
    },
    {
      stallNumber: 28,
      gridNum: 306,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 444, 384, 383, 382, 381, 380, 379, 378, 377, 376, 375, 374, 373, 372, 371, 370, 369, 368, 367, 366, 365]
    },
    {
      stallNumber: 29,
      gridNum: 546,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 503, 502, 501, 500, 499, 498, 497, 496, 495, 494, 493, 492, 491, 490, 489, 488, 487, 486, 485]
    },
    {
      stallNumber: 30,
      gridNum: 547,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 503, 502, 501, 500, 499, 498, 497, 496, 495, 494, 493, 492, 491, 490, 489, 488, 487, 486]
    },
    {
      stallNumber: 31,
      gridNum: 548,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 503, 502, 501, 500, 499, 498, 497, 496, 495, 494, 493, 492, 491, 490, 489, 488, 487]
    },
    {
      stallNumber: 32,
      gridNum: 549,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 503, 502, 501, 500, 499, 498, 497, 496, 495, 494, 493, 492, 491, 490, 489, 488, 487]
    },
    {
      stallNumber: 33,
      gridNum: 550,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 503, 502, 501, 500, 499, 498, 497, 496, 495, 494, 493, 492, 491, 490, 489, 488]
    },
    {
      stallNumber: 34,
      gridNum: 551,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 503, 502, 501, 500, 499, 498, 497, 496, 495, 494, 493, 492, 491, 490, 489, 488]
    },
    {
      stallNumber: 35,
      gridNum: 552,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 503, 502, 501, 500, 499, 498, 497, 496, 495, 494, 493, 492, 491, 490, 489]
    },
    {
      stallNumber: 36,
      gridNum: 553,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 503, 502, 501, 500, 499, 498, 497, 496, 495, 494, 493, 492, 491, 490]
    },
    {
      stallNumber: 37,
      gridNum: 554,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 503, 502, 501, 500, 499, 498, 497, 496, 495, 494, 493, 492, 491, 490]
    },
    {
      stallNumber: 38,
      gridNum: 555,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 503, 502, 501, 500, 499, 498, 497, 496, 495, 494, 493, 492, 491]
    },
    {
      stallNumber: 39,
      gridNum: 556,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 503, 502, 501, 500, 499, 498, 497, 496, 495, 494, 493, 492, 491]
    },
    {
      stallNumber: 40,
      gridNum: 557,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 503, 502, 501, 500, 499, 498, 497, 496, 495, 494, 493, 492]
    },
    {
      stallNumber: 41,
      gridNum: 558,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 503, 502, 501, 500, 499, 498, 497, 496, 495, 494, 493]
    },
    {
      stallNumber: 42,
      gridNum: 559,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 503, 502, 501, 500, 499, 498, 497, 496, 495, 494, 493]
    },
    {
      stallNumber: 43,
      gridNum: 560,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 503, 502, 501, 500, 499, 498, 497, 496, 495, 494]
    },
    {
      stallNumber: 44,
      gridNum: 561,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 503, 502, 501, 500, 499, 498, 497, 496, 495, 494]
    },
    {
      stallNumber: 45,
      gridNum: 562,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 503, 502, 501, 500, 499, 498, 497, 496, 495]
    },
    {
      stallNumber: 46,
      gridNum: 563,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 503, 502, 501, 500, 499, 498, 497, 496]
    },
    {
      stallNumber: 47,
      gridNum: 564,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 503, 502, 501, 500, 499, 498, 497, 496]
    },
    {
      stallNumber: 48,
      gridNum: 565,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 503, 502, 501, 500, 499, 498, 497]
    },
    {
      stallNumber: 49,
      gridNum: 566,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 503, 502, 501, 500, 499, 498]
    },
    {
      stallNumber: 50,
      gridNum: 567,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 503, 502, 501, 500, 499, 498]
    },
    {
      stallNumber: 51,
      gridNum: 568,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 503, 502, 501, 500, 499]
    },
    {
      stallNumber: 52,
      gridNum: 569,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 503, 502, 501, 500, 499]
    },
    {
      stallNumber: 53,
      gridNum: 570,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 503, 502, 501, 500]
    },
    {
      stallNumber: 54,
      gridNum: 571,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 503, 502, 501, 500]
    },
    {
      stallNumber: 55,
      gridNum: 572,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 503, 502, 501]
    },
    {
      stallNumber: 56,
      gridNum: 573,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 584, 583, 582, 581, 580, 579, 578, 577, 576, 504, 503, 502]
    },
    {
      stallNumber: 57,
      gridNum: 746,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 692, 691, 690, 689, 688, 687, 686]
    },
    {
      stallNumber: 58,
      gridNum: 745,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 692, 691, 690, 689, 688, 687, 686, 685]
    },
    {
      stallNumber: 59,
      gridNum: 744,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 692, 691, 690, 689, 688, 687, 686, 685, 684]
    },
    {
      stallNumber: 60,
      gridNum: 743,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 692, 691, 690, 689, 688, 687, 686, 685, 684, 683]
    },
    {
      stallNumber: 61,
      gridNum: 742,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 692, 691, 690, 689, 688, 687, 686, 685, 684, 683, 682]
    },
    {
      stallNumber: 62,
      gridNum: 741,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 692, 691, 690, 689, 688, 687, 686, 685, 684, 683, 682, 681]
    },
    {
      stallNumber: 63,
      gridNum: 740,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 692, 691, 690, 689, 688, 687, 686, 685, 684, 683, 682, 681, 680]
    },
    {
      stallNumber: 64,
      gridNum: 739,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 692, 691, 690, 689, 688, 687, 686, 685, 684, 683, 682, 681, 680, 679]
    },
    {
      stallNumber: 65,
      gridNum: 738,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 692, 691, 690, 689, 688, 687, 686, 685, 684, 683, 682, 681, 680, 679, 678]
    },
    {
      stallNumber: 66,
      gridNum: 737,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 692, 691, 690, 689, 688, 687, 686, 685, 684, 683, 682, 681, 680, 679, 678, 677]
    },
    {
      stallNumber: 67,
      gridNum: 736,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 692, 691, 690, 689, 688, 687, 686, 685, 684, 683, 682, 681, 680, 679, 678, 677, 676]
    },
    {
      stallNumber: 68,
      gridNum: 735,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 692, 691, 690, 689, 688, 687, 686, 685, 684, 683, 682, 681, 680, 679, 678, 677, 676, 675]
    },
    {
      stallNumber: 69,
      gridNum: 734,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 692, 691, 690, 689, 688, 687, 686, 685, 684, 683, 682, 681, 680, 679, 678, 677, 676, 675, 674]
    },
    {
      stallNumber: 70,
      gridNum: 733,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 692, 691, 690, 689, 688, 687, 686, 685, 684, 683, 682, 681, 680, 679, 678, 677, 676, 675, 674, 673]
    },
    {
      stallNumber: 71,
      gridNum: 732,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 692, 691, 690, 689, 688, 687, 686, 685, 684, 683, 682, 681, 680, 679, 678, 677, 676, 675, 674, 673, 672]
    },
    {
      stallNumber: 72,
      gridNum: 731,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 692, 691, 690, 689, 688, 687, 686, 685, 684, 683, 682, 681, 680, 679, 678, 677, 676, 675, 674, 673, 672, 671]
    },
    {
      stallNumber: 73,
      gridNum: 730,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 692, 691, 690, 689, 688, 687, 686, 685, 684, 683, 682, 681, 680, 679, 678, 677, 676, 675, 674, 673, 672, 671, 670]
    },
    {
      stallNumber: 74,
      gridNum: 729,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 692, 691, 690, 689, 688, 687, 686, 685, 684, 683, 682, 681, 680, 679, 678, 677, 676, 675, 674, 673, 672, 671, 670, 669]
    },
    {
      stallNumber: 75,
      gridNum: 728,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 692, 691, 690, 689, 688, 687, 686, 685, 684, 683, 682, 681, 680, 679, 678, 677, 676, 675, 674, 673, 672, 671, 670, 669, 668]
    },
    {
      stallNumber: 76,
      gridNum: 727,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 692, 691, 690, 689, 688, 687, 686, 685, 684, 683, 682, 681, 680, 679, 678, 677, 676, 675, 674, 673, 672, 671, 670, 669, 668, 667]
    },
    {
      stallNumber: 77,
      gridNum: 726,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 692, 691, 690, 689, 688, 687, 686, 685, 684, 683, 682, 681, 680, 679, 678, 677, 676, 675, 674, 673, 672, 671, 670, 669, 668, 667, 666]
    },
    {
      stallNumber: 78,
      gridNum: 725,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 692, 691, 690, 689, 688, 687, 686, 685, 684, 683, 682, 681, 680, 679, 678, 677, 676, 675, 674, 673, 672, 671, 670, 669, 668, 667, 666, 665]
    },
    {
      stallNumber: 79,
      gridNum: 724,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 692, 691, 690, 689, 688, 687, 686, 685, 684, 683, 682, 681, 680, 679, 678, 677, 676, 675, 674, 673, 672, 671, 670, 669, 668, 667, 666, 665, 664]
    },
    {
      stallNumber: 80,
      gridNum: 723,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 692, 691, 690, 689, 688, 687, 686, 685, 684, 683, 682, 681, 680, 679, 678, 677, 676, 675, 674, 673, 672, 671, 670, 669, 668, 667, 666, 665, 664, 663]
    },
    {
      stallNumber: 81,
      gridNum: 722,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 692, 691, 690, 689, 688, 687, 686, 685, 684, 683, 682, 681, 680, 679, 678, 677, 676, 675, 674, 673, 672, 671, 670, 669, 668, 667, 666, 665, 664, 663, 662]
    },
  	{
      stallNumber: 82,
  		gridNum: 129,
  		road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 513, 453, 393, 342, 273, 272, 271, 270, 269, 268, 267, 266, 265, 264, 263, 203, 202, 201, 200, 199, 198, 197, 196, 195, 194, 193, 192, 191, 190, 189]
  	},
    {
      stallNumber: 83,
      gridNum: 130,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 513, 453, 393, 342, 273, 272, 271, 270, 269, 268, 267, 266, 265, 264, 263, 203, 202, 201, 200, 199, 198, 197, 196, 195, 194, 193, 192, 191, 190]
    },
    {
      stallNumber: 84,
      gridNum: 131,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 513, 453, 393, 342, 273, 272, 271, 270, 269, 268, 267, 266, 265, 264, 263, 203, 202, 201, 200, 199, 198, 197, 196, 195, 194, 193, 192, 191]
    },
    {
      stallNumber: 85,
      gridNum: 132,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 513, 453, 393, 342, 273, 272, 271, 270, 269, 268, 267, 266, 265, 264, 263, 203, 202, 201, 200, 199, 198, 197, 196, 195, 194, 193, 192]
    },
    {
      stallNumber: 86,
      gridNum: 133,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 513, 453, 393, 342, 273, 272, 271, 270, 269, 268, 267, 266, 265, 264, 263, 203, 202, 201, 200, 199, 198, 197, 196, 195, 194, 193]
    },
    {
      stallNumber: 87,
      gridNum: 134,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 513, 453, 393, 342, 273, 272, 271, 270, 269, 268, 267, 266, 265, 264, 263, 203, 202, 201, 200, 199, 198, 197, 196, 195, 194]
    },
    {
      stallNumber: 88,
      gridNum: 135,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 513, 453, 393, 342, 273, 272, 271, 270, 269, 268, 267, 266, 265, 264, 263, 203, 202, 201, 200, 199, 198, 197, 196, 195]
    },
    {
      stallNumber: 89,
      gridNum: 136,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 513, 453, 393, 342, 273, 272, 271, 270, 269, 268, 267, 266, 265, 264, 263, 203, 202, 201, 200, 199, 198, 197, 196]
    },
    {
      stallNumber: 90,
      gridNum: 137,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 513, 453, 393, 342, 273, 272, 271, 270, 269, 268, 267, 266, 265, 264, 263, 203, 202, 201, 200, 199, 198, 197]
    },
    {
      stallNumber: 91,
      gridNum: 138,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 513, 453, 393, 342, 273, 272, 271, 270, 269, 268, 267, 266, 265, 264, 263, 203, 202, 201, 200, 199, 198]
    },
    {
      stallNumber: 92,
      gridNum: 139,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 513, 453, 393, 342, 273, 272, 271, 270, 269, 268, 267, 266, 265, 264, 263, 203, 202, 201, 200, 199]
    },
    {
      stallNumber: 93,
      gridNum: 140,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 513, 453, 393, 342, 273, 272, 271, 270, 269, 268, 267, 266, 265, 264, 263, 203, 202, 201, 200]
    },
    {
      stallNumber: 94,
      gridNum: 141,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 513, 453, 393, 342, 273, 272, 271, 270, 269, 268, 267, 266, 265, 264, 263, 203, 202, 201]
    },
    {
      stallNumber: 95,
      gridNum: 142,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 513, 453, 393, 342, 273, 272, 271, 270, 269, 268, 267, 266, 265, 264, 263, 203, 202]
    },
    {
      stallNumber: 96,
      gridNum: 143,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 513, 453, 393, 342, 273, 272, 271, 270, 269, 268, 267, 266, 265, 264, 263, 203]
    },
    {
      stallNumber: 97,
      gridNum: 144,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 513, 453, 393, 342, 273, 272, 271, 270, 269, 268, 267, 266, 265, 264, 204]
    },
    {
      stallNumber: 98,
      gridNum: 145,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 513, 453, 393, 342, 273, 272, 271, 270, 269, 268, 267, 266, 265, 205]
    },
    {
      stallNumber: 99,
      gridNum: 146,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 513, 453, 393, 342, 273, 272, 271, 270, 269, 268, 267, 266, 206]
    },
    {
      stallNumber: 100,
      gridNum: 147,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 513, 453, 393, 342, 273, 272, 271, 270, 269, 268, 267, 207]
    },
    {
      stallNumber: 101,
      gridNum: 148,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 513, 453, 393, 342, 273, 272, 271, 270, 269, 268, 208]
    },
    {
      stallNumber: 102,
      gridNum: 149,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 513, 453, 393, 342, 273, 272, 271, 270, 269, 209]
    },
    {
      stallNumber: 103,
      gridNum: 150,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 513, 453, 393, 342, 273, 272, 271, 270, 210]
    },
    {
      stallNumber: 104,
      gridNum: 151,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 513, 453, 393, 342, 273, 272, 271, 211]
    },
    {
      stallNumber: 105,
      gridNum: 152,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 513, 453, 393, 342, 273, 272, 212]
    },
    {
      stallNumber: 106,
      gridNum: 153,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 513, 453, 393, 342, 273, 213]
    },
    {
      stallNumber: 107,
      gridNum: 154,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 513, 453, 393, 342, 273, 274, 214]
    },
    {
      stallNumber: 108,
      gridNum: 155,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 513, 453, 393, 342, 273, 274, 275, 215]
    },
    {
      stallNumber: 109,
      gridNum: 156,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 513, 453, 393, 342, 273, 274, 275, 276, 216]
    },
    {
      stallNumber: 110,
      gridNum: 157,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 513, 453, 393, 342, 343, 277, 217]
    },
    {
      stallNumber: 111,
      gridNum: 158,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 585, 513, 453, 393, 342, 343, 277, 278, 218]
    },
    {
      stallNumber: 112,
      gridNum: 99,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 159, 219, 585, 513, 453, 393, 342, 343, 277, 278, 279]
    },
    {
      stallNumber: 113,
      gridNum: 40,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 219, 585, 513, 453, 393, 342, 343, 277, 278, 279, 220, 160, 100]
    },
    {
      stallNumber: 114,
      gridNum: 41,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 219, 585, 513, 453, 393, 342, 343, 277, 278, 279, 220, 160, 161, 101]
    },
    {
      stallNumber: 115,
      gridNum: 42,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 219, 585, 513, 453, 393, 342, 343, 277, 278, 279, 220, 160, 161, 162, 102]
    },
    {
      stallNumber: 116,
      gridNum: 43,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 219, 585, 513, 453, 393, 342, 343, 277, 278, 279, 220, 160, 161, 162, 163, 103]
    },
    {
      stallNumber: 117,
      gridNum: 44,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 219, 585, 513, 453, 393, 342, 343, 277, 278, 279, 220, 160, 161, 162, 163, 164, 104]
    },
    {
      stallNumber: 118,
      gridNum: 45,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 219, 585, 513, 453, 393, 342, 343, 277, 278, 279, 220, 160, 161, 162, 163, 164, 165, 105]
    },
    {
      stallNumber: 119,
      gridNum: 46,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 219, 585, 513, 453, 393, 342, 343, 277, 278, 279, 220, 160, 161, 162, 163, 164, 165, 166, 106]
    },
    {
      stallNumber: 120,
      gridNum: 47,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 219, 585, 513, 453, 393, 342, 343, 277, 278, 279, 220, 160, 161, 162, 163, 164, 165, 166, 167, 107]
    },
    {
      stallNumber: 121,
      gridNum: 48,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 219, 585, 513, 453, 393, 342, 343, 277, 278, 279, 220, 160, 161, 162, 163, 164, 165, 166, 167, 168, 108]
    },
    {
      stallNumber: 122,
      gridNum: 49,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 634, 635, 636, 637, 638, 639, 640, 641, 642, 643, 644, 645, 646, 647, 648, 594, 528, 468, 408, 348, 288, 228, 168, 108, 109]
    },
    {
      stallNumber: 123,
      gridNum: 110,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 634, 635, 636, 637, 638, 639, 640, 641, 642, 643, 644, 645, 646, 647, 648, 594, 528, 468, 408, 348, 288, 228, 168, 108, 109]
    },
    {
      stallNumber: 124,
      gridNum: 170,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 634, 635, 636, 637, 638, 639, 640, 641, 642, 643, 644, 645, 646, 647, 648, 594, 528, 468, 408, 348, 288, 228, 168, 169]
    },
    {
      stallNumber: 125,
      gridNum: 230,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 634, 635, 636, 637, 638, 639, 640, 641, 642, 643, 644, 645, 646, 647, 648, 594, 528, 468, 408, 348, 288, 228, 229]
    },
    {
      stallNumber: 126,
      gridNum: 290,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 634, 635, 636, 637, 638, 639, 640, 641, 642, 643, 644, 645, 646, 647, 648, 594, 528, 468, 408, 348, 288, 289]
    },
    {
      stallNumber: 127,
      gridNum: 350,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 634, 635, 636, 637, 638, 639, 640, 641, 642, 643, 644, 645, 646, 647, 648, 594, 528, 468, 408, 348, 349]
    },
    {
      stallNumber: 128,
      gridNum: 770,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 634, 635, 636, 637, 638, 639, 640, 641, 642, 643, 644, 645, 646, 647, 648, 649, 650, 710]
    },
    {
      stallNumber: 129,
      gridNum: 769,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 634, 635, 636, 637, 638, 639, 640, 641, 642, 643, 644, 645, 646, 647, 648, 649, 709]
    },
    {
      stallNumber: 130,
      gridNum: 768,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 634, 635, 636, 637, 638, 639, 640, 641, 642, 643, 644, 645, 646, 647, 648, 708]
    },
    {
      stallNumber: 131,
      gridNum: 767,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 634, 635, 636, 637, 638, 639, 640, 641, 642, 643, 644, 645, 646, 647, 707]
    },
    {
      stallNumber: 132,
      gridNum: 766,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 634, 635, 636, 637, 638, 639, 640, 641, 642, 643, 644, 645, 646, 706]
    },
    {
      stallNumber: 133,
      gridNum: 765,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 634, 635, 636, 637, 638, 639, 640, 641, 642, 643, 644, 645, 705]
    },
    {
      stallNumber: 134,
      gridNum: 764,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 634, 635, 636, 637, 638, 639, 640, 641, 642, 643, 644, 704]
    },
    {
      stallNumber: 135,
      gridNum: 763,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 634, 635, 636, 637, 638, 639, 640, 641, 642, 643, 703]
    },
    {
      stallNumber: 136,
      gridNum: 762,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 634, 635, 636, 637, 638, 639, 640, 641, 642, 702]
    },
    {
      stallNumber: 137,
      gridNum: 761,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 634, 635, 636, 637, 638, 639, 640, 641, 701]
    },
    {
      stallNumber: 138,
      gridNum: 760,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 634, 635, 636, 637, 638, 639, 640, 700]
    },
    {
      stallNumber: 139,
      gridNum: 759,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 634, 635, 636, 637, 638, 639, 699]
    },
    {
      stallNumber: 140,
      gridNum: 758,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 634, 635, 636, 637, 638, 698]
    },
    {
      stallNumber: 141,
      gridNum: 757,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 693, 633, 634, 635, 636, 637, 697]
    },
    {
      stallNumber: 142,
      gridNum: 756,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 753, 754, 755]
    },
    {
      stallNumber: 143,
      gridNum: 816,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 813, 814, 815]
    },
    {
      stallNumber: 144,
      gridNum: 876,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 873, 874, 875]
    },
    {
      stallNumber: 145,
      gridNum: 936,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 933, 934, 935]
    },
    {
      stallNumber: 146,
      gridNum: 996,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 993, 994, 995]
    },
    {
      stallNumber: 147,
      gridNum: 1056,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1053, 1054, 1055]
    },
    {
      stallNumber: 148,
      gridNum: 1116,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1113, 1114, 1115]
    },
    {
      stallNumber: 149,
      gridNum: 1176,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1173, 1174, 1175]
    },
    {
      stallNumber: 150,
      gridNum: 1236,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1233, 1234, 1235]
    },
    {
      stallNumber: 151,
      gridNum: 1296,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1293, 1294, 1295]
    },
    {
      stallNumber: 152,
      gridNum: 1356,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1353, 1354, 1355]
    },
    {
      stallNumber: 153,
      gridNum: 1416,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1413, 1414, 1415]
    },
    {
      stallNumber: 154,
      gridNum: 1476,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1473, 1474, 1475]
    },
    {
      stallNumber: 155,
      gridNum: 1536,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1533, 1534, 1535]
    },
    {
      stallNumber: 156,
      gridNum: 1596,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1593, 1594, 1595]
    },
    {
      stallNumber: 157,
      gridNum: 1656,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1773, 1833, 1713, 1653, 1654, 1655]
    },
    {
      stallNumber: 158,
      gridNum: 1716,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1833, 1773, 1713, 1714, 1715]
    },
    {
      stallNumber: 159,
      gridNum: 1776,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1833, 1773, 1774, 1775]
    },
    {
      stallNumber: 160,
      gridNum: 1836,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1833, 1834, 1835]
    },
    {
      stallNumber: 161,
      gridNum: 1896,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1893, 1894, 1895]
    },
    {
      stallNumber: 162,
      gridNum: 1956,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 1953, 1954, 1955]
    },
    {
      stallNumber: 163,
      gridNum: 2016,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2013, 2014, 2015]
    },
    {
      stallNumber: 164,
      gridNum: 2076,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2073, 2074, 2075]
    },
    {
      stallNumber: 165,
      gridNum: 2136,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2134, 2135]
    },
    {
      stallNumber: 166,
      gridNum: 2196,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2194, 2195]
    },
    {
      stallNumber: 167,
      gridNum: 2256,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2254, 2255]
    },
    {
      stallNumber: 168,
      gridNum: 2316,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2314, 2315]
    },
    {
      stallNumber: 169,
      gridNum: 2376,
      road: [2613, 2553, 2493, 2433, 2373, 2374, 2375]
    },
    {
      stallNumber: 170,
      gridNum: 2436,
      road: [2613, 2553, 2493, 2433, 2434, 2435]
    },
    {
      stallNumber: 171,
      gridNum: 2496,
      road: [2613, 2553, 2493, 2494, 2495]
    },
    {
      stallNumber: 172,
      gridNum: 2127,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2132, 2131, 2130, 2129, 2128]
    },
    {
      stallNumber: 173,
      gridNum: 2067,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2132, 2131, 2130, 2129, 2069, 2068]
    },
    {
      stallNumber: 174,
      gridNum: 2007,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2132, 2131, 2130, 2129, 2069, 2009, 2008]
    },
    {
      stallNumber: 175,
      gridNum: 1947,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2132, 2131, 2130, 2129, 2069, 2009, 1949, 1948]
    },
    {
      stallNumber: 176,
      gridNum: 1887,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2132, 2131, 2130, 2129, 2069, 2009, 1949, 1889, 1888]
    },
    {
      stallNumber: 177,
      gridNum: 1827,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2132, 2131, 2130, 2129, 2069, 2009, 1949, 1889, 1829, 1828]
    },
    {
      stallNumber: 178,
      gridNum: 1767,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2132, 2131, 2130, 2129, 2069, 2009, 1949, 1889, 1829, 1769, 1768]
    },
    {
      stallNumber: 179,
      gridNum: 1707,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2132, 2131, 2130, 2129, 2069, 2009, 1949, 1889, 1829, 1769, 1709, 1708]
    },
    {
      stallNumber: 180,
      gridNum: 1647,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2132, 2131, 2130, 2129, 2069, 2009, 1949, 1889, 1829, 1769, 1709, 1649, 1648]
    },
    {
      stallNumber: 181,
      gridNum: 1587,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2132, 2131, 2130, 2129, 2069, 2009, 1949, 1889, 1829, 1769, 1709, 1649, 1589, 1588]
    },
    {
      stallNumber: 182,
      gridNum: 1527,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2132, 2131, 2130, 2129, 2069, 2009, 1949, 1889, 1829, 1769, 1709, 1649, 1589, 1529, 1528]
    },
    {
      stallNumber: 183,
      gridNum: 1467,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2132, 2131, 2130, 2129, 2069, 2009, 1949, 1889, 1829, 1769, 1709, 1649, 1589, 1529, 1469, 1468]
    },
    {
      stallNumber: 184,
      gridNum: 1407,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2132, 2131, 2130, 2129, 2069, 2009, 1949, 1889, 1829, 1769, 1709, 1649, 1589, 1529, 1469, 1409, 1408]
    },
    {
      stallNumber: 185,
      gridNum: 1347,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2132, 2131, 2130, 2129, 2069, 2009, 1949, 1889, 1829, 1769, 1709, 1649, 1589, 1529, 1469, 1409, 1349, 1348]
    },
    {
      stallNumber: 186,
      gridNum: 1287,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2132, 2131, 2130, 2129, 2069, 2009, 1949, 1889, 1829, 1769, 1709, 1649, 1589, 1529, 1469, 1409, 1349, 1289, 1288]
    },
    {
      stallNumber: 187,
      gridNum: 1227,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2132, 2131, 2130, 2129, 2069, 2009, 1949, 1889, 1829, 1769, 1709, 1649, 1589, 1529, 1469, 1409, 1349, 1289, 1229, 1228]
    },
    {
      stallNumber: 188,
      gridNum: 1167,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2132, 2131, 2130, 2129, 2069, 2009, 1949, 1889, 1829, 1769, 1709, 1649, 1589, 1529, 1469, 1409, 1349, 1289, 1229, 1169, 1168]
    },
    {
      stallNumber: 189,
      gridNum: 1107,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2132, 2131, 2130, 2129, 2069, 2009, 1949, 1889, 1829, 1769, 1709, 1649, 1589, 1529, 1469, 1409, 1349, 1289, 1229, 1169, 1109, 1108]
    },
    {
      stallNumber: 190,
      gridNum: 1047,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2132, 2131, 2130, 2129, 2069, 2009, 1949, 1889, 1829, 1769, 1709, 1649, 1589, 1529, 1469, 1409, 1349, 1289, 1229, 1169, 1109, 1049, 1048]
    },
    {
      stallNumber: 191,
      gridNum: 987,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2132, 2131, 2130, 2129, 2069, 2009, 1949, 1889, 1829, 1769, 1709, 1649, 1589, 1529, 1469, 1409, 1349, 1289, 1229, 1169, 1109, 1049, 989, 988]
    },
    {
      stallNumber: 192,
      gridNum: 927,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2132, 2131, 2130, 2129, 2069, 2009, 1949, 1889, 1829, 1769, 1709, 1649, 1589, 1529, 1469, 1409, 1349, 1289, 1229, 1169, 1109, 1049, 989, 929, 928]
    },
    {
      stallNumber: 193,
      gridNum: 867,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2132, 2131, 2130, 2129, 2069, 2009, 1949, 1889, 1829, 1769, 1709, 1649, 1589, 1529, 1469, 1409, 1349, 1289, 1229, 1169, 1109, 1049, 989, 929, 869, 868]
    },
    {
      stallNumber: 194,
      gridNum: 807,
      road: [2613, 2553, 2493, 2433, 2373, 2313, 2253, 2193, 2133, 2132, 2131, 2130, 2129, 2069, 2009, 1949, 1889, 1829, 1769, 1709, 1649, 1589, 1529, 1469, 1409, 1349, 1289, 1229, 1169, 1109, 1049, 989, 929, 869, 809, 808]
    },
  ]

  const sideRed = [343, 335, 346, 347, 586, 587, 599, 600];

  function getStallDetailsByGridNum(gridNumber) {
    const stall = stallDetails.find(stallDetail => stallDetail.gridNum === gridNumber);
    return stall || null;
  }

  useEffect(() => {

    const createGridData = () => {
      const rows = 50;
      const cols = 60;
      const newGridData = [];
      for (let row = 0; row < rows; row++) {
        const gridRow = [];
        for (let col = 1; col <= cols; col++) {
        	const gridNum = row * cols + col
        	const details = getStallDetailsByGridNum(gridNum);
          gridRow.push({
            gridNum: gridNum,
            isStall: stalls.includes(gridNum)? true : false,
            isMini: miniStalls.includes(gridNum)? true : false,
            stallNumber: details? details.stallNumber : null,
            road: details? details.road : null,
            status: true,
            isRoad: road.includes(gridNum)? true : false,
            isGray: gray.includes(gridNum)? true : false,
            isRed: red.includes(gridNum)? true : false,
            isSideRed: sideRed.includes(gridNum)? true : false,
          });
        }
        newGridData.push(gridRow);
      }
      return newGridData;
    };

    setGridData(createGridData());
  }, [road]);

  useEffect(() => {
    async function fetchAllStalls() {
      const db = getDatabase();
      const stallsRef = ref(db, 'stalls'); 

      try {
        const snapshot = await get(stallsRef);
        if (snapshot.exists()) {
          const stallsData = snapshot.val();
          const stallsArray = Object.entries(stallsData).map(([key, value]) => ({
            id: key,
            ...value
          }));
          let tempArr = gridData.map(rowData => {
            return rowData.map(data => {
              // If data has a stallNumber, attempt to find the corresponding stall data
              if (data.stallNumber) {
                const stall = stallsArray.find(item => item.stallNumber == data.stallNumber);
                return {
                  ...data,
                  metaData: stall || {}, // Provide an empty object if no stall is found
                };
              }
              // If data does not have a stallNumber, return it unchanged
              return data;
            });
          });
          setStallsData(tempArr);
        } else {
          console.log("No stalls available");
          return [];
        }
      } catch (error) {
        console.error("Error fetching stalls:", error);
        return [];
      }
    }

    if(gridData){
      fetchAllStalls()
    }
  },[gridData])

  const copyArrayToClipboard = async () => {
    try {
      const formattedArray = '[' + road.join(', ') + ']';
      await navigator.clipboard.writeText(formattedArray);
    } catch (error) {
      console.error('Failed to copy: ', error);
    }
  };


  const toggleStall = (cell) => {

    if (road.includes(cell)) {
      setRoad(road.filter(stall => stall !== cell));
    } else {
      setRoad([...road, cell]);
    }
  };

  const handleStall = (cell) => {
  		setModalData(cell);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  }

  // useEffect(() => {
  // 	console.log(road);
  // }, [road]);

  // Render the grid
  return (
  	<div className="flex flex-row grow w-full h-full overflow-x-auto">
    <div className="bg-[#d9d9d9] p-8 h-max">
      <StallModal isVisible={showModal} toggleModal={toggleModal} modalData={modalData} setRoad={setRoad} selectedGrid={selectedGrid} setSelectedGrid={setSelectedGrid}/>

      {stallsData.map((row, rowIndex) => (
        <div key={rowIndex} className="flex flex-row">
          {row.map((cell, cellIndex) => (
            <div className="relative">
              <div
                key={cellIndex}
                // onClick={() => toggleStall(cell.gridNum)}
                onClick={() => {
                  handleStall(cell)
                  toggleModal()
                }}
                className={`
                	ahover:max-w-[60px] ahover:bg-black border-black text-center relative
                  ${selectedGrid === cell.gridNum? "bg-[#00ffff]" : ""}
                	${cell.isMini? "min-w-[15px] h-[25px]" : "min-w-[25px] h-[25px]"}
                	${cell.isStall? "border cursor-pointer" : "pointer-events-none"}
                  ${cell.metaData?.stallOwner && cell.isStall && selectedGrid !== cell.gridNum? "bg-danger text-white" : cell.isStall && !cell.metaData?.stallOwner && cell.metaData?.status === "Unavailable" && selectedGrid !== cell.gridNum ? "bg-danger/[.50]" : cell.isStall && selectedGrid !== cell.gridNum ? "bg-primary-green text-white" : null}
                	${cell.isSideRed? "min-w-100px" : null}    	
                	${cell.isGray? "bg-primary-gray" : null} 
                	${cell.isRed? "bg-danger" : null}
                	${cell.isRoad? "bg-yellow" : null}
                	`}
              >
                 {/*<span className={`${cell.isStall? "" : "hidden"}`}>{cell.gridNum}</span> */}
                 {/*<span className={`${cell.gridNum === 2613? "text-[12px] text-black font-bold" : "hidden"}`}>S</span>*/}
                 <span className={`${cell.isMini? "text-[10px]" : "text-[12px]"}`}>{cell.stallNumber}</span> 
              </div>
              {
                cell.gridNum === 850?
                <img src={stallGuide} className="h-auto min-w-[360px] absolute top-0 z-10"/>
                :
                null
              }
            </div>
          ))}
        </div>
      ))}
{/*      <button onClick={copyArrayToClipboard} className="mt-2 p-2 bg-blue-500 text-white">
	      Copy to Clipboard
	    </button>*/}
    </div>
    </div>
  );
}


