import { useAuthContext } from '../contexts/AuthContext';
import { useState, useEffect } from 'react';

import { getDatabase, ref, get, query, orderByChild, equalTo, update } from "firebase/database";
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../configs/firebase";
import { toast } from 'react-toastify';
import { IoCloseSharp } from "react-icons/io5";


const StallApplicationModal = ({isVisible, toggleStallApplicationModal}) => {

	const { user, userDetails } = useAuthContext();
	const [letter, setLetter] = useState();
	const [stallNumber, setStallNumber] = useState();
	const [selectedStall, setSelectedStall] = useState([]);
	const [stalls, setStalls] = useState([]);
	const [stallAvailable, setStallAvailable] = useState(false)
	const [disableButton, setDisableButton] = useState(false)
	const [addStallButton, setAddStallButton] = useState(false)
	const [loading, setLoading] = useState(false);
	const [reapply, setReapply] = useState(userDetails.applicationStatus && userDetails.applicationStatus === "Rejected"? false : true);

	const handleAddStall = () => {
	    if (stallNumber && !selectedStall.some(stall => stall.stallNumber === stallNumber)) {
	        setSelectedStall([...selectedStall, stallNumber]);
	        setStallNumber('');
	    }
	};

	const handleRemoveStall = (stallNumberToRemove) => {
	    setSelectedStall(selectedStall.filter(stall => stall !== stallNumberToRemove));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		let dataToUpdate = {
			letter: letter,
			stallApplications: selectedStall,
			applicationStatus: "Pending",
		}

		try {
		  const dataItemRef = ref(db, `users/${user.uid}`);
		  await update(dataItemRef, dataToUpdate);
		  toast.success("Application sent successfully!");
		  toggleStallApplicationModal();
		} catch (error) {
		  console.error("Error updating response:", error);
		  toast.error("Failed to update response.");
		}

	}

	useEffect(() => {
		console.log(userDetails)
	    setDisableButton(selectedStall.length === 0);
	    setAddStallButton(stalls.some(stall => stall.stallNumber == stallNumber));
	}, [stallNumber, selectedStall, stalls]);

	useEffect(() => {
	  const db = getDatabase();
	  const stallsRef = ref(db, 'stalls');
	  setLoading(true);

	  get(stallsRef).then((snapshot) => {
	    if (snapshot.exists()) {
	      let stallsArray = [];
	      snapshot.forEach((childSnapshot) => {
	        const key = childSnapshot.key;
	        const value = childSnapshot.val();
	        stallsArray.push({ uid: key, ...value });
	      });
	      stallsArray = stallsArray.filter(stall => 
	      	stall.status !== 'Unavailable'
	      )
	      setStalls(stallsArray)
	    } else {
	      console.log('No stalls available');
	    }
	  }).catch((error) => {
	    console.error('Error fetching stalls:', error);
	  }).finally(() => {
	    setLoading(false);
	  });
	}, []);

	if(!isVisible) return null

	if(loading) return <p>Loading...</p>

	if(userDetails.applicationStatus && userDetails.applicationStatus === "Rejected" && !reapply){
		return (

			<div className={`fixed z-50 inset-0 flex items-start justify-center overflow-y-auto backdrop-blur-sm py-8`}>

				<div className="flex flex-col max-w-[500px] min-h-[400px] w-full bg-white rounded-lg shadow-2xl mx-2 my-auto p-4 gap-4">

					<div className="flex items-center justify-center grow font-semibold">
							{userDetails.statusMessage}
					</div>

					<div className="flex flex-row w-full gap-4">
						<button 
						type="button" 
						onClick={() => toggleStallApplicationModal()} 
						className="w-full border-2 border-primary-green min-h-[40px] rounded-xl px-4 font-bold 
						text-primary-green mt-4 hover:opacity-70">
							Close
						</button>
						<button 
						onClick={() => setReapply(true)}
						className={`w-full bg-primary-green min-h-[40px] rounded-xl px-4 font-bold text-white mt-4 hover:opacity-70`}
						>
							Re-Apply
						</button>
					</div>

				</div>

			</div>
		)
	}	

	if(userDetails.stallApplications && userDetails.stallApplications.length !== 0){
		return (

			<div className={`fixed z-50 inset-0 flex items-start justify-center overflow-y-auto backdrop-blur-sm py-8`}>

				<div className="flex flex-col max-w-[500px] min-h-[400px] w-full bg-white rounded-lg shadow-2xl mx-2 my-auto p-4 gap-4">

					<div className="flex items-center justify-center grow font-semibold">
							You currently have an existing application.
					</div>

					<div className="flex flex-row w-full gap-4">
						<button 
						type="button" 
						onClick={() => toggleStallApplicationModal()} 
						className="w-full border-2 border-primary-green min-h-[40px] rounded-xl px-4 font-bold 
						text-primary-green mt-4 hover:opacity-70">
							Close
						</button>
					</div>

				</div>

			</div>
		)
	}	

	return (

		<div className={`fixed z-50 inset-0 flex items-start justify-center overflow-y-auto backdrop-blur-sm py-8`}>

			<form onSubmit={handleSubmit} className="flex flex-col max-w-[500px] min-h-[400px] w-full bg-white rounded-lg shadow-2xl mx-2 my-auto p-4 gap-4">

				<h2 className="text-center mt-4">Stall Application</h2>
				<div className="w-[60px] mx-auto border-2 border-primary-green rounded-xl mb-4"></div>

				<div className="grow">
					<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full min-h-[150px]">
					    <textarea type="text" className="h-full max-h-full bg-white rounded-[10px] outline-none w-full pl-4 pt-2" placeholder="Please fill out this form to write your application letter" onChange={(e) => setLetter(e.target.value)} required />
					    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Application Letter:</p>
				    </div>
				</div>

				<p className="font-bold bg-white text-primary-green text-[14px]">Stalls:</p>

				<div className="flex flex-row gap-4">
					<input type="number" min="1" className="border rounded-lg w-16 px-2" value={stallNumber} onChange={(e) => setStallNumber(e.target.value)}/>
					<button 
					type="button"
					onClick={handleAddStall} 
					disabled={!addStallButton}
					className={`${addStallButton? "bg-primary-green" : "bg-danger/[.70]"} px-2 text-white rounded-lg`}
					>{addStallButton? "Add Stall" : "Not Available"}</button>
				</div>

				<div className="flex flex-wrap gap-4">
		            {selectedStall.map((stall, index) => (
		                <div key={index} className="w-max h-max rounded-md px-2 bg-primary-gray flex flex-row items-center gap-2">
		                    <p className="text-[14px] text-white"># {stall}</p>
		                    <IoCloseSharp onClick={() => handleRemoveStall(stall)} className="text-white cursor-pointer" />
		                </div>
		            ))}
		        </div>

				<div className="flex flex-row w-full gap-4">
					<button 
					type="button" 
					onClick={() => toggleStallApplicationModal()} 
					className="w-full border-2 border-primary-green min-h-[40px] rounded-xl px-4 font-bold 
					text-primary-green mt-4 hover:opacity-70">
						Cancel
					</button>
					<button 
					type="submit" 
					className={`w-full bg-primary-green min-h-[40px] rounded-xl px-4 font-bold text-white mt-4 ${disableButton? "opacity-40" : "hover:opacity-70"}`}
					disabled={disableButton}
					>
						
						Apply
					</button>
				</div>

			</form>

		</div>
	)
}

export default function PendingStallOwner(){

	const { logout, userDetails } = useAuthContext();
	const [showStallApplicationModal, setShowStallApplicationModal] = useState(true);

	const toggleStallApplicationModal = () => {
		setShowStallApplicationModal(!showStallApplicationModal)
	}

	return (

		<div className="flex items-center justify-center w-full px-4">	
			{
				userDetails.applicationStatus && userDetails.applicationStatus === "Rejected" &&  showStallApplicationModal?
				<StallApplicationModal isVisible={showStallApplicationModal} toggleStallApplicationModal={toggleStallApplicationModal} />
				: userDetails.applicationStatus && userDetails.applicationStatus === "Rejected"?
				<div className="flex flex-col items-center bg-white p-8 rounded-xl shadow-xl">
					<p className="text-center lg:text-[24px]">Click view to see the result.</p>
					<div className="flex w-full items-center justify-center gap-4">
						<button onClick={() => {logout()}} className="border-2 border-primary-green text-primary-green h-10 px-4 font-bold rounded-xl mt-6 text-[14px] lg:text-[16px]">Logout</button>
						<button onClick={toggleStallApplicationModal} className="bg-primary-green text-white h-10 px-4 font-bold rounded-xl mt-6 hover:opacity-70 text-[14px] lg:text-[16px]">View Result</button>
					</div>
				</div>
				:
				<div className="flex flex-col items-center bg-white p-8 rounded-xl shadow-xl">
					<p className="text-center lg:text-[24px]">Your application is currently being reviewed.</p>
					<div className="flex w-full items-center justify-center gap-4">
						<button onClick={() => {logout()}} className="border-2 border-primary-green text-primary-green h-10 px-4 font-bold rounded-xl mt-6 text-[14px] lg:text-[16px] hover:bg-primary-green hover:text-white">Logout</button>
					</div>
				</div>
			}
		</div>
	)
}