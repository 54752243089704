import { useState, useEffect } from 'react';
import { getDatabase, ref, get, query, orderByChild, update, set, push } from "firebase/database";
import { db } from "../configs/firebase";
import defaultStallImg from '../assets/next-two.png'
import { toast } from 'react-toastify';

// mui styling
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: `#40916C`,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const AddItemModal = ({isVisible, toggleAddItemModal, metaData, userDetails, toggleReloadTable}) => {
	useEffect(() => {
		console.log(isVisible);
	}, [isVisible])

	const [selectedInput, setSelectedInput] = useState([0]);

	function isPastDue(dateString) {
	  const givenDate = new Date(dateString);
	  const currentDate = new Date();

	  givenDate.setHours(0, 0, 0, 0);
	  currentDate.setHours(0, 0, 0, 0);

	  return givenDate < currentDate;
	}

	function handleCheckboxChange(e, index, data) {
	  if(e.target.checked){
	  	setSelectedInput([...selectedInput, index])
	  } else {
	  	let tempArr = selectedInput.filter(input => input !== index);
	  	setSelectedInput(tempArr);
	  }
	}

	function verifier(index){
		return selectedInput.includes(index);
	}

	function calculateTotalAmount(selectedInput, metaData){
		let totalAmount = 0;

		selectedInput.map((input, index) => {
			let penalty = isPastDue(metaData.dueDates[input])? metaData.stallAmount * 0.25 : 0
			totalAmount += metaData.stallAmount + penalty

		})
		return totalAmount
	}

	async function paymentAction(){
		let newDue = '';
		const currentDate = new Date();
		const currentYear = currentDate.getFullYear();
		const currentMonth = currentDate.getMonth() + 1;
		const currentDay = currentDate.getDate();

		let dataToUpdate = {};

		const calculateNextDueDate = (lastPaidDate) => {
		  const date = new Date(lastPaidDate);
		  const year = date.getFullYear();
		  const month = date.getMonth();

		  const nextMonth = month === 11 ? 0 : month + 1;
		  const nextYear = month === 11 ? year + 1 : year;

		  const nextDueDate = new Date(nextYear, nextMonth, 20);

		  return `${nextDueDate.getFullYear()}-${(nextDueDate.getMonth() + 1).toString().padStart(2, '0')}-${nextDueDate.getDate().toString().padStart(2, '0')}`;
		};


		function getAllPaidDueData(){
			const tempArr = []
			selectedInput.map(input => {
				tempArr.push(metaData.dueDates[input])
			})
			return tempArr
		}

		if(selectedInput.length === metaData.dueDates.length){
			dataToUpdate = {
			  dueDates: [calculateNextDueDate(metaData.dueDates[metaData.dueDates.length - 1])]
			} 
		} else {
			let remainingDueDates = metaData.dueDates.filter((_, index) => !selectedInput.includes(index));

			dataToUpdate = {
			  dueDates: remainingDueDates
			}
		}

		const dataItemRef = ref(db, `stalls/${metaData.uid}`);
		await update(dataItemRef, dataToUpdate);

		function getAllPenalty(arrayOfDue){
			let totalPenalty = 0;
			arrayOfDue.map(due => {
				let penalty = isPastDue(due)? metaData.stallAmount * 0.25 : 0
				totalPenalty += penalty
			})
			return totalPenalty;
		}

		const transactionData = {
		  dues: getAllPaidDueData(),
		  userId: metaData.stallOwner,
		  amount: metaData.stallAmount,
		  stallNumber: metaData.stallNumber,
		  penalty: getAllPenalty(getAllPaidDueData()),
		  totalAmount: getAllPaidDueData().length * metaData.stallAmount + getAllPenalty(getAllPaidDueData()),
		  createdAt: Date.now(),
		  updatedAt: Date.now(),
		};

		const transactionRef = ref(db, 'transactions');
		const newTransactionRef = push(transactionRef);
		await set(newTransactionRef, transactionData);

		toggleReloadTable()
		toast.success("Paid successfully!");
		toggleAddItemModal();

	}

	if(!isVisible)return null	

	return (

		<div className={`fixed z-50 inset-0 flex items-start justify-center overflow-y-auto backdrop-blur-sm py-8`}>
			<div className="flex flex-col max-w-[500px] min-h-[400px] w-full bg-white rounded-lg shadow-2xl mx-2 my-auto p-4 gap-4">

				<div className="flex flex-col gap-2  grow">

					<p className="text-center text-[18px] lg:text-[24px] font-bold mb-4">{metaData.stallName? metaData.stallName : `Stall # ${metaData.stallNumber}`}</p>
					<p><span className="font-semibold">Owner:</span> {userDetails[metaData.stallOwner]?.firstName} {userDetails[metaData.stallOwner]?.middleName} {userDetails[metaData.stallOwner]?.lastName}</p>
					<p><span className="font-semibold">Email:</span>  {userDetails[metaData.stallOwner]?.email}</p>
					<p><span className="font-semibold">Contact No:</span>  {userDetails[metaData.stallOwner]?.contactNo}</p>
					<div className="mx-4 grid grid-cols-4 justify-center items-center">
						<div></div>
						<p className="font-semibold text-[14px]">Cut-off</p>
						<p className="font-semibold text-[14px]">Amount</p>
						<p className="font-semibold text-[14px]">Penalty</p>
					</div>
					<div className="border mx-4"></div>
					<div className="mx-4 min-h-[120px] grow flex flex-col items-start gap-4">
						{
							metaData.dueDates.map((due, index) => {
								let penalty = isPastDue(due)? metaData.stallAmount * 0.25 : 0
								let data = { due:due, amount: metaData.stallAmount, penalty: penalty } 
								return(
									<div key={index} className={`grid grid-cols-4 w-full items-center justify-center ${verifier(index)? "" : "text-primary-gray"}`}>
										<input type="checkbox"  className="h-max justify-self-end mr-4" onChange={(e) => handleCheckboxChange(e, index, data)} checked={verifier(index)} disabled={index === 0 ? false : index === 1 && selectedInput.length >= 1 ? false : index === 2 && selectedInput.length >= 2? false : true } />
										<p className="font-semibold">{due}</p>
										<p className="font-semibold">{metaData.stallAmount}</p>
										<p className="font-semibold">{penalty}</p>
									</div>
								)
							})
						}
					</div>
					<div className="border-2"></div>
					<p className="font-semibold">Total Amount: {calculateTotalAmount(selectedInput, metaData)}</p>
				</div>
		        <div className="flex flex-row w-full gap-4">
		        	<button 
		        	type="button" 
		        	onClick={() => toggleAddItemModal()} 
		        	className="w-full border-2 border-primary-green min-h-[40px] rounded-xl px-4 font-bold 
		        	text-primary-green mt-4 hover:opacity-70">
		        		Close
		        	</button>
		        	<button 
		        	type="button" 
		        	onClick={() => paymentAction()} 
		        	disabled={selectedInput.length === 0}
		        	className={`w-full bg-primary-green min-h-[40px] rounded-xl px-4 font-bold 
		        	text-white mt-4 hover:opacity-70 ${selectedInput.length === 0? "opacity-40" : "" }`}>
		        		Mark as Paid
		        	</button>
		        </div>
			</div>

		</div>

	)
}


export default function ManagePayments(){

	const [showAddItemModal, setShowAddItemModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [metaData, setMetaData] = useState({});
	const [stalls, setStalls] = useState([]);
	const [search, setSearch] = useState('');
	const [userDetails, setUserDetails] = useState({}); 
	const [reloadTable, setReloadTable] = useState(false);

	// State for pagination
	const [rows, setRows] = useState([])
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	const handleChangePage = (event, newPage) => {
    	setPage(newPage);
  	};

	const handleChangeRowsPerPage = (event) => {
	  setRowsPerPage(parseInt(event.target.value, 10));
	  setPage(0);
	};

	const toggleAddItemModal = () => {
		setShowAddItemModal(!showAddItemModal)
	}

	const toggleReloadTable = () => {
		setReloadTable(!reloadTable);
	}

	function isPastDue(dateString) {
	  const givenDate = new Date(dateString);
	  const currentDate = new Date();

	  givenDate.setHours(0, 0, 0, 0);
	  currentDate.setHours(0, 0, 0, 0);

	  return givenDate < currentDate;
	}

	function isPastDueArr(dateArr) {
	  const array = [];	
	  dateArr.map(date => {
	  	const givenDate = new Date(date);
	  	const currentDate = new Date();

	  	givenDate.setHours(0, 0, 0, 0);
	  	currentDate.setHours(0, 0, 0, 0);
	  	if(givenDate < currentDate) {
	  		array.push(givenDate)
	  	}
	  })
	  return array
	}

	const fetchUserDetails = async (userId) => {
	  const userRef = ref(db, 'users/' + userId);
	  try {
	    const snapshot = await get(userRef);
	    if (snapshot.exists()) {
	      return snapshot.val();
	    } else {
	      console.log("No data available for user: " + userId);
	    }
	  } catch (error) {
	    console.error(error);
	  }
	};

	useEffect(() => {
	  const db = getDatabase();
	  const stallsRef = ref(db, 'stalls');
	  setLoading(true);

	  get(stallsRef).then((snapshot) => {
	    if (snapshot.exists()) {
	      const stallsArray = [];
	      snapshot.forEach((childSnapshot) => {
	        const key = childSnapshot.key;
	        const value = childSnapshot.val();
	        stallsArray.push({ uid: key, ...value });
	      });
	      setStalls(stallsArray)
	    } else {
	      console.log('No users available');
	    }
	  }).catch((error) => {
	    console.error('Error fetching users:', error);
	  }).finally(() => {
	    setLoading(false);
	  });
	}, [reloadTable]);

	useEffect(() => {
	  const results = stalls.filter(user =>
	    user.stallNumber.toLowerCase().includes(search.toLowerCase()) && user.dueDates && user.dueDates.length !== 0);
	  setRows(results);
	}, [search, stalls]);

	useEffect(() => {
	  const fetchAllUserDetails = async () => {
	    const allUserDetails = {};
	    const fetchPromises = stalls.map(async (stall) => {
	      if (stall.stallOwner) {
	        const details = await fetchUserDetails(stall.stallOwner);
	        allUserDetails[stall.stallOwner] = details;
	      }
	    });
	    await Promise.all(fetchPromises);
	    setUserDetails(allUserDetails);
	  };

	  fetchAllUserDetails();
	}, [stalls]);


	if (loading) return <p>Loading...</p>;

	return (

		<div className="w-full px-4">
			<AddItemModal isVisible={showAddItemModal} toggleAddItemModal={toggleAddItemModal} metaData={metaData} userDetails={userDetails} toggleReloadTable={toggleReloadTable}/>

			<h1 className="text-center mt-6">Manage Payments</h1>
			<div className="w-[60px] mx-auto border-2 border-primary-green rounded-xl"></div>

			<input
		        type="text"
		        placeholder="Search by stall number"
		        value={search}
		        onChange={(e) => setSearch(e.target.value)}
		        className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none my-4"
		    />

			<TableContainer component={Paper}>
			  <Table sx={{ minWidth: 700 }} aria-label="customized table">
			    <TableHead>
			      <TableRow>
			      	<StyledTableCell align="center">Owner</StyledTableCell>
			        <StyledTableCell align="center">Stall Number</StyledTableCell>
			        <StyledTableCell align="center">Cut-off Date</StyledTableCell>
			        <StyledTableCell align="center">Past due</StyledTableCell>
			        <StyledTableCell align="center">Penalty</StyledTableCell>
			        <StyledTableCell align="center">Amount</StyledTableCell>
			        <StyledTableCell align="center">Total Amount</StyledTableCell>
			        <StyledTableCell align="center">Manage</StyledTableCell>
			      </TableRow>
			    </TableHead>
			    <TableBody>

			    	{(rowsPerPage > 0
		              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
		              : rows
		            ).map((row, index) => (
		              <StyledTableRow key={row.uid}>
		              	<StyledTableCell align="center">
		              		{userDetails[row.stallOwner]?.email}
		              	</StyledTableCell>
		                <StyledTableCell align="center">
		                  	{row.stallNumber}
		                </StyledTableCell>
		                <StyledTableCell  align="center">
		                  	{row.dueDates.join(", ")}
		                </StyledTableCell>
		                <StyledTableCell  align="center">
		                  	{isPastDueArr(row.dueDates).length}
		                </StyledTableCell>
		                <StyledTableCell align="center">
		                  	{row.stallAmount * isPastDueArr(row.dueDates).length * .25}
		                </StyledTableCell>
		                <StyledTableCell align="center">
		                  	{row.stallAmount * (isPastDueArr(row.dueDates).length + 1)}
		                </StyledTableCell>
		                <StyledTableCell align="center">
		                  	{row.stallAmount * (isPastDueArr(row.dueDates).length + 1) + (row.stallAmount * isPastDueArr(row.dueDates).length * .25)}
		                </StyledTableCell>
		                <StyledTableCell align="center">
	              	    	<button 
	          	              onClick={() => {
	          	              	toggleAddItemModal()
	          	              	setMetaData(row)
	          	              }}
	          	              className="p-2 font-bold text-white bg-primary-green border-2 border-primary-green rounded-lg 
	          	              hover:opacity-70 mr-2">
	          	              View
	          	            </button>
		                </StyledTableCell>
		              </StyledTableRow>
		            ))}

			    	{emptyRows > 0 && (
			    	  <TableRow style={{ height: 53 * emptyRows }}>
			    	    <TableCell colSpan={6} />
			    	  </TableRow>
			    	)}
			    </TableBody>
			  </Table>
			</TableContainer>

			<TablePagination
			  rowsPerPageOptions={[5, 10, 25, 50]}
			  component="div"
			  count={rows.length}
			  rowsPerPage={rowsPerPage}
			  page={page}
			  onPageChange={handleChangePage}
			  onRowsPerPageChange={handleChangeRowsPerPage}
			/>

		</div>	

	)

}