import { useState, useEffect } from 'react';
import { getDatabase, ref, get, query, orderByChild, equalTo, update } from "firebase/database";
import { db } from "../configs/firebase";
import { toast } from 'react-toastify';
import LOGO_TANAUAN from "../assets/LOGO_TANAUAN.png";

// mui styling
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: `#40916C`,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const UpdateItemModal = ({isVisible, toggleUpdateItemModal, metaData, toggleFetch}) => {

	const [firstName, setFirstName] = useState('');
	const [middleName, setMiddleName] = useState('');
	const [lastName, setLastName] = useState('');
	const [userName, setUserName] = useState('');
	const [profileImg, setProfileImg] = useState('');
	const [userType, setUserType] = useState('');
	const [contactNo, setContactNo] = useState('');
	const [allStallAvailable, setAllStallAvailable] = useState(true);

	const [plateNo, setPlateNo] = useState('');
	const [vehicle, setVehicle] = useState('');

	const [gender, setGender] = useState('');
	const [birthDate, setBirthDate] = useState('');
	const [emergencyContactName, setEmergencyContactName] = useState('');
	const [emergencyContactNo, setEmergencyContactNo] = useState('');
	const [address, setAddress] = useState('');

	const [isRejecting, setIsRejecting] = useState(false);

	const [isEdit, setIsEdit] = useState(false);

	const [statusMessage, setStatusMessage] = useState('');

	useEffect(() => {
		setFirstName(metaData.firstName)
		setMiddleName(metaData.middleName);
		setLastName(metaData.lastName);
		setUserName(metaData.userName);
		setProfileImg(metaData.profileImg);
		setUserType(metaData.userType);
		setContactNo(metaData.contactNo);

		if(metaData.userType === "stallOwner" || metaData.userType === "approvedStallOwner"){
			setGender(metaData.gender);
			setBirthDate(metaData.birthDate);
			setEmergencyContactName(metaData.emergencyContactName);
			setEmergencyContactNo(metaData.emergencyContactNo);
			setAddress(metaData.address);
		} else {
			setPlateNo(metaData.plateNo);
			setVehicle(metaData.vehicle);
		}
	}, [isVisible]);

	const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const currentDay = currentDate.getDate();


	const calculateFirstDueDate = (currentYear, currentMonth, currentDay) => {
	    if (currentDay >= 20) {
	        if (currentMonth === 12) {
	            return `${currentYear + 1}-01-20`;
	        } else {
	            return `${currentYear}-${(currentMonth + 1).toString().padStart(2, '0')}-20`;
	        }
	    } else {
	        return `${currentYear}-${currentMonth.toString().padStart(2, '0')}-20`;
	    }
	};

	const firstDueDate = calculateFirstDueDate(currentYear, currentMonth, currentDay);


	const handleUpdateItem = async (isApproved) => {
	    let dataToUpdate = {};

	    if(isApproved){
	    		const tempArr = [];
	    		if(metaData.stallsOwned && metaData?.stallsOwned?.length !== 0){
	    			tempArr.push(...metaData.stallsOwned, ...metaData.stallApplications)
	    		} else {
	    			tempArr.push(...metaData.stallApplications)
	    		}

	        dataToUpdate = {
	            stallsOwned: tempArr,
	            stallApplications: [],
	            userType: "approvedStallOwner",
	            status: "Active"
	        };
	        try {
	            const userRef = ref(db, `users/${metaData.uid}`);
	            await update(userRef, dataToUpdate);

              const stallsRef = ref(db, 'stalls');
              const stallsSnapshot = await get(stallsRef);
              if (stallsSnapshot.exists()) {
                  const stalls = stallsSnapshot.val();
                  const stallUpdates = {};
                  
                  metaData.stallApplications.forEach((applicationIndex) => {
                      const stallKey = Object.keys(stalls).find(key => stalls[key].stallNumber === applicationIndex);
                      if (stallKey && !stalls.stallOwner && stalls.status !== "Unavailable") {
                          stallUpdates[`stalls/${stallKey}/stallOwner`] = metaData.uid;
                          stallUpdates[`stalls/${stallKey}/status`] = "Unavailable"
                          stallUpdates[`stalls/${stallKey}/dueDates`] = [firstDueDate];
                      } else {
                      	toast.error("Stall already taken!");
                      }
                  });

                  await update(ref(db), stallUpdates);
              }

	            toast.success("User approved and stalls updated successfully!");
	        } catch (error) {
	            console.error("Error updating user and stalls:", error);
	            toast.error("Failed to update user and stalls.");
	        }
	    } else {
	        dataToUpdate = {
	        		applicationStatus: "Rejected",
	            statusMessage: statusMessage,
	            stallApplications: [],
	        };

	        try {
	            const userRef = ref(db, `users/${metaData.uid}`);
	            await update(userRef, dataToUpdate);
	            toast.success("User rejected successfully!");
	        } catch (error) {
	            console.error("Error rejecting user:", error);
	            toast.error("Failed to reject user.");
	        }
	    }
	    toggleFetch();
	    toggleUpdateItemModal();
	}

	const fetchStallDetails = async () => {
	  const fetchedStallDetails = [];

	  try {
	    for (const stallNumber of metaData?.stallApplications) {
	      const stallsRef = ref(db, 'stalls');
	      const queryByStallNumber = query(stallsRef, orderByChild('stallNumber'), equalTo(stallNumber));
	      
	      const querySnapshot = await get(queryByStallNumber);
	      if (querySnapshot.exists()) {
	        querySnapshot.forEach((stallSnapshot) => {
	          const stallData = stallSnapshot.val();
	          fetchedStallDetails.push(stallData);
	        });
	      } else {
	        console.log(`No data found for stall ${stallNumber}.`);
	      }
	    }

	    if (fetchedStallDetails.length > 0) {

	      fetchedStallDetails.map(stallDetails => {

	      	if(stallDetails.stallOwner || stallDetails.status === "Unavailable"){
	      		setAllStallAvailable(false);
	      	}
	      })
	    } else {
	      console.log('No stall details were fetched.');
	    }
	  } catch (error) {
	    console.error("Error fetching stall details: ", error);
	  }
	};

	useEffect(() => {
		if(isVisible){
			fetchStallDetails();
		}
	}, [isVisible])

	if(!isVisible)return null	

	return (

		<div className={`fixed z-50 inset-0 flex items-start justify-center overflow-y-auto py-6 backdrop-blur-sm`}>

			<div className="flex flex-col max-w-[500px] w-full bg-white rounded-lg shadow-2xl mx-2 my-auto p-4 gap-4">

				{
					isRejecting?
					<>
						<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full min-h-[150px]">
					    <textarea type="text" className="h-full max-h-full bg-white rounded-[10px] outline-none w-full pl-4 pt-2" placeholder="Please fill out this form" onChange={(e) => setStatusMessage(e.target.value)} required />
					    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Message:</p>
				    </div>
						<div className="flex flex-row w-full gap-4">
							<button 
							type="button" 
							onClick={() => handleUpdateItem(false)} 
							className="order-2 w-full bg-[#FF0000]/[.80] min-h-[40px] rounded-xl px-4 font-bold 
							text-white mt-4 hover:opacity-70">
								Reject
							</button>
							<button onClick={() => setIsRejecting(false)} className={`${allStallAvailable? "bg-primary-green hover:opacity-70" : "bg-primary-green/[.60]" } w-full min-h-[40px] rounded-xl px-4 font-bold text-white mt-4`} disabled={!allStallAvailable}>
								Go Back
							</button>
						</div>
					</>
					:
					<>
						<img 
						src={metaData.profileImg} 
						alt="Profile Img" 
						className="mx-auto w-24 h-24 object-cover"
						onError={(e) => {
							e.target.src=`${LOGO_TANAUAN}`
						}}
						/>
						<p className="text-center">{metaData.userName}</p>
						<p className="text-center">{metaData.email}</p>
						<div className="flex flex-row"></div>
						<p><span className="font-semibold">Full Name:</span> {metaData.firstName} {metaData.middleName} {metaData.lastName}</p>
						<p><span className="font-semibold">Stall Application:</span> {metaData.stallApplications.join(", ")}</p>
						<p><span className="font-semibold">Gender:</span> {metaData.gender}</p>
						<p><span className="font-semibold">Address:</span> {metaData.address? metaData.address : "N/A"}</p>
						<p><span className="font-semibold">Contact No:</span> {metaData.contactNo}</p>
						<p><span className="font-semibold">Emergency Contact Name:</span> {metaData.emergencyContactName}</p>
						<p><span className="font-semibold">Emergency Contact No:</span> {metaData.emergencyContactNo}</p>
						<p>Primary ID:</p>
						<img
							className="w-full max-w-[320px] h-auto object-cover"
							src={metaData.primaryId} 
						/>
						<p>Seondary ID:</p>
						<img
							className="w-full max-w-[320px] h-auto object-cover"
							src={metaData.secondaryId} 
						/>
						<p>E Signature:</p>
						<img
							className="w-full max-w-[320px] h-auto object-cover"
							src={metaData.eSignature} 
						/>
						<p className="font-semibold">Application Letter:</p>
						<p>{metaData.letter}</p>

		        <div className="flex flex-row w-full gap-4">
		        	<button 
		        	type="button" 
		        	onClick={() => setIsRejecting(true)} 
		        	className="w-full bg-[#FF0000]/[.80] min-h-[40px] rounded-xl px-4 font-bold 
		        	text-white mt-4 hover:opacity-70">
		        		Reject
		        	</button>
		        	<button onClick={() => handleUpdateItem(true)} className={`${allStallAvailable? "bg-primary-green hover:opacity-70" : "bg-primary-green/[.60]" } w-full min-h-[40px] rounded-xl px-4 font-bold text-white mt-4`} disabled={!allStallAvailable}>
		        		Approve
		        	</button>
		        </div>
		      </>  
				}

        <button 
        type="button" 
        onClick={() => toggleUpdateItemModal()} 
        className="w-full border-2 border-primary-green min-h-[40px] rounded-xl px-4 font-bold 
        text-primary-green mt-4 hover:opacity-70">
        	Cancel
        </button>

			</div>

		</div>

	)
}

export default function StallApplications({ setApplications }){

	const [showUpdateItemModal, setShowUpdateItemModal] = useState(false);
	const [metaData, setMetaData] = useState({}); 

	const [fetchAgain, setFetchAgain] = useState(false);

	const [loading, setLoading] = useState(false);

	const [users, setUsers] = useState([]);
	const [search, setSearch] = useState('');
	const [filteredUsers, setFilteredUsers] = useState([]);

	// State for pagination
	const [rows, setRows] = useState([])
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	const toggleUpdateItemModal = () => {
		setShowUpdateItemModal(!showUpdateItemModal)
	}

	const toggleFetch = () => {
		setFetchAgain(!fetchAgain);
	}

	const handleChangePage = (event, newPage) => {
    	setPage(newPage);
  	};

	const handleChangeRowsPerPage = (event) => {
	  setRowsPerPage(parseInt(event.target.value, 10));
	  setPage(0);
	};

	function formatTimestamp(timestamp) {
	  const date = new Date(timestamp);
	  const year = date.getFullYear().toString().slice(-2);
	  const month = (date.getMonth() + 1).toString().padStart(2, '0');
	  const day = date.getDate().toString().padStart(2, '0');

	  // Convert 24h time to 12h time format
	  let hours = date.getHours();
	  const ampm = hours >= 12 ? 'PM' : 'AM';
	  hours = hours % 12;
	  hours = hours ? hours.toString().padStart(2, '0') : '12';
	  
	  const minutes = date.getMinutes().toString().padStart(2, '0');
	  const seconds = date.getSeconds().toString().padStart(2, '0');

	  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${ampm}`;
	}

	useEffect(() => {
	  const db = getDatabase();
	  const usersRef = ref(db, 'users');
	  setLoading(true);

	  get(usersRef).then((snapshot) => {
	    if (snapshot.exists()) {
	      const usersArray = [];
	      snapshot.forEach((childSnapshot) => {
	        const key = childSnapshot.key;
	        const value = childSnapshot.val();

	        if (value.userType !== 'Admin') {
	          usersArray.push({ uid: key, ...value });
	        }
	      });
	      setUsers(usersArray);
	    } else {
	      console.log('No users available');
	    }
	  }).catch((error) => {
	    console.error('Error fetching users:', error);
	  }).finally(() => {
	    setLoading(false);
	  });
	}, [fetchAgain]);

	useEffect(() => {
	  const results = users.filter(user => 
	    user?.email?.toLowerCase().includes(search.toLowerCase()) && user.userType !== 'Admin' 
	    && user.userType !== 'driver' && user.userType !== 'magdadala' && user.userType !== 'kwarthera' && user.stallApplications
	    && user.status !== "Rejected"
	  );

	  setRows(results);
	}, [search, users, fetchAgain]);

	if (loading) return <p>Loading...</p>;

	return (

		<div className="w-full px-4">
			<UpdateItemModal isVisible={showUpdateItemModal} toggleUpdateItemModal={toggleUpdateItemModal} metaData={metaData} toggleFetch={toggleFetch}/>
			<h1 className="text-center mt-6">Stall Applications</h1>
			<div className="w-[60px] mx-auto border-2 border-primary-green rounded-xl"></div>

	    	<div className="flex flex-row">
    			<input
    		        type="text"
    		        placeholder="Search by email"
    		        value={search}
    		        onChange={(e) => setSearch(e.target.value)}
    		        className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none my-4"
    		    />

	    	    <div className="w-full flex justify-end my-4">
	    	    	<button onClick={() => {setApplications(false)}} className="bg-primary-green h-10 rounded-xl px-4 font-bold text-white">View Stalls</button>
	    	    </div>
	        </div>

			<TableContainer component={Paper}>
			  <Table sx={{ minWidth: 700 }} aria-label="customized table">
			    <TableHead>
			      <TableRow>
			        <StyledTableCell>Email</StyledTableCell>
			        <StyledTableCell align="center">Stall Number</StyledTableCell>
			        <StyledTableCell align="center">Contact Number</StyledTableCell>
			        <StyledTableCell align="center">Role</StyledTableCell>
			        <StyledTableCell align="center">Action</StyledTableCell>
			      </TableRow>
			    </TableHead>
			    <TableBody>

			    	{(rowsPerPage > 0
    	              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    	              : rows
    	            ).map((user, index) => (
    	              <StyledTableRow key={user.uid}>
    	                <StyledTableCell component="th" scope="row">
    	                  {user?.email}
    	                </StyledTableCell>
    	                <StyledTableCell  align="center">
    	                  {user.stallApplications? user.stallApplications.join(", ") : ""}
    	                </StyledTableCell>
    	                <StyledTableCell  align="center">
    	                  {user.contactNo}
    	                </StyledTableCell>
    	                <StyledTableCell align="center">
    	                	{user.userType === "stallOwner" ? "Pending Stall Holder" : user.userType === "approvedStallOwner"? "Active Stall Holder" : user.userType}
    	                </StyledTableCell>
    	                <StyledTableCell align="center">
	              	    	<button 
	          	              onClick={() => {
	          	              	toggleUpdateItemModal()
	          	              	setMetaData(user)
	          	              }}
	          	              className="p-2 font-bold text-primary-green border-2 border-primary-green rounded-lg 
	          	              hover:bg-primary-green hover:text-white">
	          	              View Requirements
	          	            </button>
    	                </StyledTableCell>
    	              </StyledTableRow>
    	            ))}

			    	{emptyRows > 0 && (
			    	  <TableRow style={{ height: 53 * emptyRows }}>
			    	    <TableCell colSpan={6} />
			    	  </TableRow>
			    	)}
			    </TableBody>
			  </Table>
			</TableContainer>

			<TablePagination
			  rowsPerPageOptions={[5, 10, 25, 50]}
			  component="div"
			  count={rows.length}
			  rowsPerPage={rowsPerPage}
			  page={page}
			  onPageChange={handleChangePage}
			  onRowsPerPageChange={handleChangeRowsPerPage}
			/> 

		</div>

	)

}