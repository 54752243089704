import { useState, useEffect } from 'react';
import { getDatabase, ref, get, query, orderByChild, equalTo, update } from "firebase/database";
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../configs/firebase";
import { toast } from 'react-toastify';

import defaultStallImg from '../assets/next-two.png'
import { MdAddPhotoAlternate } from "react-icons/md";
import { useAuthContext } from '../contexts/AuthContext';

import StallApplications from './StallApplications';

// mui styling
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: `#40916C`,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const AddItemModal = ({isVisible, toggleAddItemModal, metaData, userDetails}) => {

	if(!isVisible)return null	

	return (

		<div className={`fixed z-50 inset-0 flex items-start justify-center overflow-y-auto backdrop-blur-sm py-8`}>
			<div className="flex flex-col max-w-[500px] min-h-[400px] w-full bg-white rounded-lg shadow-2xl mx-2 my-auto p-4 gap-4">

				<div className="flex flex-col gap-2  grow">

					<div className="relative w-full pt-[56.25%] overflow-hidden bg-white shadow-xl group">
					 	<img 
					 	src={metaData.stallImg} 
					 	alt="Stall Img" 
					 	className="absolute top-0 left-0 bottom-0 right-0 object-cover rounded-xl h-full w-full"
					 	onError={(e) => {
					 		e.target.src = `${defaultStallImg}`
					 		e.target.className = "absolute top-0 left-0 bottom-0 right-0 object-cover rounded-xl h-full w-full opacity-40"
					 	}}
					 	/>
					 </div>

					<p className="text-center">{metaData.stallName? metaData.stallName : `# ${metaData.stallNumber}`}</p>
					<div className="flex flex-row gap-2">
						<div className="w-max rounded-md px-2 bg-primary-gray"><p className="text-[14px] text-white"># {metaData.stallNumber}</p></div>
						<div className="w-max rounded-md px-2 bg-primary-gray"><p className="text-[14px] text-white">{metaData.stallSize}</p></div>
						<div className={`w-max rounded-md px-2 ${metaData.status === "Unavailable"? "bg-danger/[.70]" : "bg-primary-green/[.70]"}`}><p className="text-[14px] text-white">{metaData.status}</p></div>
					</div>
					<p>Amount: Php {metaData.stallAmount}</p>
					<p>Stall Owner: {metaData.stallOwner? userDetails[metaData.stallOwner]?.email : "N/A"}</p>
					<p>Description: {metaData.stallDescription}</p>

				</div>
		        <div className="flex flex-row w-full gap-4">
		        	<button 
		        	type="button" 
		        	onClick={() => toggleAddItemModal()} 
		        	className="w-full border-2 border-primary-green min-h-[40px] rounded-xl px-4 font-bold 
		        	text-primary-green mt-4 hover:opacity-70">
		        		Close
		        	</button>
		        </div>
			</div>

		</div>

	)
}


const UpdateItemModal = ({isVisible, toggleUpdateItemModal, metaData, setStalls, stalls, userDetails}) => {

	const { user } = useAuthContext();

	const [stallImg, setStallImg] = useState('');
	const [stallImgFile, setStallImgFile] = useState('');
	const [stallName, setStallName] = useState('');
	const [stallProduce, setStallProduce] = useState('');
	const [stallSize, setStallSize] = useState('');
	const [stallDescription, setStallDescription] = useState('');
	const [stallAmount, setStallAmount] = useState('');
	const [status, setStatus] = useState('');
	const [stallOwner, setStallOwner] = useState('');

	useEffect(() => {
				setStallName(metaData.stallName);
        setStallImg(metaData.stallImg);
        setStallProduce(metaData.stallProduce);
        setStallSize(metaData.stallSize);
        setStallDescription(metaData.stallDescription);
        setStallAmount(metaData.stallAmount);
        setStatus(metaData.status);
        setStallOwner(userDetails[metaData.stallOwner]?.email);
	}, [metaData]);

	const handleUpdateItem = async (e) => {
		e.preventDefault();

		let filename = ''

		if(stallImgFile){
			filename = `stallImages/${user.uid}/${Date.now()}/${stallImgFile.name}`;
		}

		let imgUrl = '';
		if (stallImgFile) {
		  try {
		    const imageRef = storageRef(storage, `${filename}`);
		    const snapshot = await uploadBytes(imageRef, stallImgFile);
		    imgUrl = await getDownloadURL(snapshot.ref);
		  } catch (error) {
		    toast.error("Failed to upload image.");
		    return;
		  }
		}

		const dataToUpdate = {
		  stallImg: stallImg,
		  stallName: stallName,	
		  stallProduce: stallProduce,
		  stallSize: stallSize,
		  stallDescription: stallDescription,
		  stallAmount: stallAmount,
		  status: status,
		};

		try {
		  const dataItemRef = ref(db, `stalls/${metaData.uid}`);
		  await update(dataItemRef, dataToUpdate);
		  toast.success("Stall updated successfully!");

		  const updatedStalls = stalls.map((stall) => {
			if (stall.uid === metaData.uid) {
			return { ...stall, ...dataToUpdate };
			}
			return stall;
		  });

		  setStalls(updatedStalls);

		  toggleUpdateItemModal();
		} catch (error) {
		  toast.error("Failed to update Stall.");
		}
	}

	const handleImageChange = (e) => {
	  if (e.target.files && e.target.files[0]) {
	    let reader = new FileReader();
	    let file = e.target.files[0];

	    reader.onloadend = () => {
	      setStallImgFile(file);
	      setStallImg(reader.result);
	    };

	    reader.readAsDataURL(file);
	  }
	};

	if(!isVisible)return null	

	return (

		<div className={`fixed z-50 inset-0 flex items-start justify-center overflow-y-auto backdrop-blur-sm py-8`}>

			<div className="flex flex-col max-w-[500px] min-h-[400px] w-full bg-white rounded-lg shadow-2xl mx-2 my-auto p-4 gap-4">

				<form onSubmit={handleUpdateItem} className="flex flex-col gap-4">
	 				<div className="relative w-full pt-[56.25%] rounded-lg overflow-hidden hover:bg-primary-gray hover:cursor-pointer">
	                 {
	                 	!stallImg?
	                 	<img
	                 	  className="absolute top-0 left-0 bottom-0 right-0 object-cover h-full w-full opacity-40"
	                 	  src={defaultStallImg}
	                 	/>
	                 	:
	                 	<img
	                 	  className="absolute top-0 left-0 bottom-0 right-0 object-cover h-full w-full"
	                 	  src={stallImg}
	                 	/>
	                 }
	                 <div className="absolute inset-0 group flex place-content-center hover:bg-black/50">
	                   <MdAddPhotoAlternate
	                     className={`self-center w-10 h-10 rounded-[5px] group-hover:opacity-100 ${
	                       stallImgFile? "opacity-0" : null
	                     }`}
	                   />

	                   <input
	                     type="file"
	                     id="imageInput"
	                     name="imageInput"
	                     accept="image/*"
	                     className={`absolute left-0 top-0 opacity-0 hover:cursor-pointer w-full h-full`}
	                     onChange={handleImageChange}
	                   />
	                 </div>
	               </div>

	               	<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
						<input type="text" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={(e) => setStallName(e.target.value)} value={stallName}/>
						<p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Name:</p>
					</div>

					<div className="flex flex-row gap-4">
		               	<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
							<input type="text" min="0" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" value={metaData.stallNumber} disabled />
							<p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Stall Number:</p>
						</div>

						<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
							<select
							  name="size"	
							  className="h-full bg-white rounded-[10px] outline-none w-full pl-4"
							  onChange={(e) => setStallSize(e.target.value)}
							  value={stallSize}
							  required
							>
							  <option value="small">Small</option>
							  <option value="large">Large</option>	
							</select>
							<label className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Size:</label>
						</div>
					</div>

					<div className="flex flex-row gap-4">
   	        <div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
   						<input type="number" min="0" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={(e) => setStallAmount(e.target.value)} value={stallAmount} required />
   						<p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Amount:</p>
   					</div>

						<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
							<select
							  name="status"	
							  className="h-full bg-white rounded-[10px] outline-none w-full pl-4"
							  onChange={(e) => setStatus(e.target.value)}
							  value={status}
							  required
							>
							  <option value="Vacant">Vacant</option>
							  <option value="Unavailable">Unavailable</option>	
							</select>
							<label className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Status:</label>
						</div>
					</div>

	        <div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
						<input type="text" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={(e) => setStallProduce(e.target.value)} value={stallProduce} />
						<p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Produce:</p>
					</div>

          <div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full pt-2 min-h-[80px] max-h-[80px]">
						<textarea type="text" className="h-full max-h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={(e) => setStallDescription(e.target.value)} value={stallDescription} />
						<p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Description:</p>
					</div>

	        <div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
						<input type="text" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={(e) => setStallOwner(e.target.value)} value={stallOwner} placeholder="N/A" disabled/>
						<p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Owner:</p>
					</div>

					<div className="flex flex-row w-full gap-4">
						<button 
						type="button" 
						onClick={() => toggleUpdateItemModal()} 
						className="w-full border-2 border-primary-green min-h-[40px] rounded-xl px-4 font-bold 
						text-primary-green mt-4 hover:opacity-70">
							Cancel
						</button>
						<button type="submit" className="w-full bg-primary-green min-h-[40px] rounded-xl px-4 font-bold text-white mt-4 hover:opacity-70">
							Update
						</button>
					</div>

				</form>

			</div>

		</div>

	)
}


export default function ManageStalls(){

	const [showAddItemModal, setShowAddItemModal] = useState(false);
	const [showUpdateItemModal, setShowUpdateItemModal] = useState(false);
	const [applications, setApplications] = useState(false);
	const [metaData, setMetaData] = useState({}); 

	const [userDetails, setUserDetails] = useState({}); 

	const [loading, setLoading] = useState(false);

	const [stalls, setStalls] = useState([]);
	const [search, setSearch] = useState('');
	const [filteredUsers, setFilteredUsers] = useState([]);

	// State for pagination
	const [rows, setRows] = useState([])
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	const fetchUserDetails = async (userId) => {
	  const userRef = ref(db, 'users/' + userId);
	  try {
	    const snapshot = await get(userRef);
	    if (snapshot.exists()) {
	      return snapshot.val();
	    } else {
	      console.log("No data available for user: " + userId);
	    }
	  } catch (error) {
	    console.error(error);
	  }
	};

	const toggleAddItemModal = () => {
		setShowAddItemModal(!showAddItemModal)
	}

	const toggleUpdateItemModal = () => {
		setShowUpdateItemModal(!showUpdateItemModal)
	}

	const handleChangePage = (event, newPage) => {
    	setPage(newPage);
  	};

	const handleChangeRowsPerPage = (event) => {
	  setRowsPerPage(parseInt(event.target.value, 10));
	  setPage(0);
	};

	function formatTimestamp(timestamp) {
	  const date = new Date(timestamp);
	  const year = date.getFullYear().toString().slice(-2); // Get the last two digits
	  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
	  const day = date.getDate().toString().padStart(2, '0');

	  // Convert 24h time to 12h time format
	  let hours = date.getHours();
	  const ampm = hours >= 12 ? 'PM' : 'AM';
	  hours = hours % 12;
	  hours = hours ? hours.toString().padStart(2, '0') : '12'; // the hour '0' should be '12'
	  
	  const minutes = date.getMinutes().toString().padStart(2, '0');
	  const seconds = date.getSeconds().toString().padStart(2, '0');

	  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${ampm}`;
	}

	useEffect(() => {
	  const db = getDatabase();
	  const stallsRef = ref(db, 'stalls');
	  setLoading(true);

	  get(stallsRef).then((snapshot) => {
	    if (snapshot.exists()) {
	      const stallsArray = [];
	      snapshot.forEach((childSnapshot) => {
	        const key = childSnapshot.key;
	        const value = childSnapshot.val();
	        stallsArray.push({ uid: key, ...value });
	      });
	      setStalls(stallsArray)
	    } else {
	      console.log('No users available');
	    }
	  }).catch((error) => {
	    console.error('Error fetching users:', error);
	  }).finally(() => {
	    setLoading(false);
	  });
	}, [applications]);

	useEffect(() => {
	  const results = stalls.filter(user =>
	    user.stallNumber.toLowerCase().includes(search.toLowerCase()));
	  setRows(results);
	}, [search, stalls]);

	useEffect(() => {
	  const fetchAllUserDetails = async () => {
	    const allUserDetails = {};
	    const fetchPromises = stalls.map(async (stall) => {
	      if (stall.stallOwner) {
	        const details = await fetchUserDetails(stall.stallOwner);
	        allUserDetails[stall.stallOwner] = details;
	      }
	    });
	    await Promise.all(fetchPromises);
	    setUserDetails(allUserDetails);
	  };

	  fetchAllUserDetails();
	}, [stalls]);

	if (loading) return <p>Loading...</p>;

	if(applications)return <StallApplications setApplications={setApplications}/>

	return (

		<div className="w-full px-4">
			<AddItemModal isVisible={showAddItemModal} toggleAddItemModal={toggleAddItemModal} metaData={metaData} userDetails={userDetails}/>
			<UpdateItemModal isVisible={showUpdateItemModal} toggleUpdateItemModal={toggleUpdateItemModal} metaData={metaData} stalls={stalls} setStalls={setStalls} userDetails={userDetails}/>

			<h1 className="text-center mt-6">Manage Stalls</h1>
			<div className="w-[60px] mx-auto border-2 border-primary-green rounded-xl"></div>

			<div className="flex flex-row">
				<input
			        type="text"
			        placeholder="Search by stall number"
			        value={search}
			        onChange={(e) => setSearch(e.target.value)}
			        className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none my-4"
			    />

			    <div className="w-full flex justify-end my-4">
			    	<button onClick={() => {setApplications(true)}} className="bg-primary-green h-10 rounded-xl px-4 font-bold text-white">View Applications</button>
			    </div>
		    </div>

			<TableContainer component={Paper}>
			  <Table sx={{ minWidth: 700 }} aria-label="customized table">
			    <TableHead>
			      <TableRow>
			        <StyledTableCell align="center">Stall Number</StyledTableCell>
			        <StyledTableCell align="center">Status</StyledTableCell>
			        <StyledTableCell align="center">Size</StyledTableCell>
			        <StyledTableCell align="center">Owner</StyledTableCell>
			        <StyledTableCell align="center">Manage</StyledTableCell>
			      </TableRow>
			    </TableHead>
			    <TableBody>

			    	{(rowsPerPage > 0
    	              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    	              : rows
    	            ).map((row, index) => (
    	              <StyledTableRow key={row.uid}>
    	                <StyledTableCell align="center">
    	                  {row.stallNumber}
    	                </StyledTableCell>
    	                <StyledTableCell  align="center">
    	                  {row.status}
    	                </StyledTableCell>
    	                <StyledTableCell  align="center">
    	                  {row.stallSize}
    	                </StyledTableCell>
    	                <StyledTableCell align="center">
    	                	 {row.stallOwner ? userDetails[row.stallOwner]?.email ?? "---" : "---"}
    	                </StyledTableCell>
    	                <StyledTableCell align="center">
	              	    	<button 
	          	              onClick={() => {
	          	              	toggleAddItemModal()
	          	              	setMetaData(row)
	          	              }}
	          	              className="p-2 font-bold text-white bg-primary-green border-2 border-primary-green rounded-lg 
	          	              hover:opacity-70 mr-2">
	          	              View
	          	            </button>
                	    	<button 
            	              onClick={() => {
            	              	toggleUpdateItemModal()
            	              	setMetaData(row)
            	              }}
            	              className="p-2 font-bold text-primary-green border-2 border-primary-green rounded-lg 
            	              hover:bg-primary-green hover:text-white">
            	              Edit
            	            </button>
    	                </StyledTableCell>
    	              </StyledTableRow>
    	            ))}

			    	{emptyRows > 0 && (
			    	  <TableRow style={{ height: 53 * emptyRows }}>
			    	    <TableCell colSpan={6} />
			    	  </TableRow>
			    	)}
			    </TableBody>
			  </Table>
			</TableContainer>

			<TablePagination
			  rowsPerPageOptions={[5, 10, 25, 50]}
			  component="div"
			  count={rows.length}
			  rowsPerPage={rowsPerPage}
			  page={page}
			  onPageChange={handleChangePage}
			  onRowsPerPageChange={handleChangeRowsPerPage}
			/>

		</div>

	)

}