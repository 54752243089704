import { HiBars3 } from "react-icons/hi2";
import { useNavigate } from "react-router-dom"
import { useAuthContext } from '../contexts/AuthContext';

//assets
import LOGO_TANAUAN from "../assets/LOGO_TANAUAN.png";

export default function Navbar({toggleSidebar}){

	const navigate = useNavigate();

	const { user, userType } = useAuthContext();

	return(

		<div className="flex flex-row gap-4 bg-primary-green h-[90px] p-2 shadow-xl">
			<img className="w-20 h-auto cursor-pointer" onClick={() => navigate("/")} src={LOGO_TANAUAN} alt="Logo"/>
			<div className="flex items-center justify-end gap-4 text-white grow px-2">
				{
					!user?
					<>
						<div onClick={() => navigate("/")}>
							<button className="hidden md:inline">NEWS BOARD</button>
						</div>
						<div onClick={() => navigate("/mapping")}>
							<button className="hidden md:inline">MAPPING</button>
						</div>
						<div onClick={() => navigate("/about")}>
							<button className="hidden md:inline">ABOUT</button>
						</div>
						<div onClick={() => navigate("/login")}>
							<button className="hidden md:inline">LOGIN</button>
						</div>
					</>
					: 
					null
				}
				
				<button 
					onClick={toggleSidebar}
					className="md:hidden"
				>
					<HiBars3 className="w-10 h-auto"/>
				</button>

			</div>
		</div>

	)

}