import { useState, useEffect } from 'react';
import { getDatabase, ref, get, query, orderByChild, equalTo, update } from "firebase/database";
import { db } from "../configs/firebase";
import { toast } from 'react-toastify';
import LOGO_TANAUAN from "../assets/LOGO_TANAUAN.png";

// mui styling
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: `#40916C`,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const UpdateItemModal = ({isVisible, toggleUpdateItemModal, metaData, toggleReloadTable}) => {

	const [firstName, setFirstName] = useState('');
	const [middleName, setMiddleName] = useState('');
	const [lastName, setLastName] = useState('');
	const [userName, setUserName] = useState('');
	const [profileImg, setProfileImg] = useState('');
	const [userType, setUserType] = useState('');
	const [contactNo, setContactNo] = useState('');

	const [plateNo, setPlateNo] = useState('');
	const [vehicle, setVehicle] = useState('');

	const [gender, setGender] = useState('');
	const [birthDate, setBirthDate] = useState('');
	const [emergencyContactName, setEmergencyContactName] = useState('');
	const [emergencyContactNo, setEmergencyContactNo] = useState('');
	const [address, setAddress] = useState('');

	const [isEdit, setIsEdit] = useState(false);

	useEffect(() => {
		setFirstName(metaData.firstName)
		setMiddleName(metaData.middleName);
		setLastName(metaData.lastName);
		setUserName(metaData.userName);
		setProfileImg(metaData.profileImg);
		setUserType(metaData.userType);
		setContactNo(metaData.contactNo);

		if(metaData.userType === "stallOwner" || metaData.userType === "approvedStallOwner"){
			setGender(metaData.gender);
			setBirthDate(metaData.birthDate);
			setEmergencyContactName(metaData.emergencyContactName);
			setEmergencyContactNo(metaData.emergencyContactNo);
			setAddress(metaData.address);
		} else {
			setPlateNo(metaData.plateNo);
			setVehicle(metaData.vehicle);
		}
	}, [isVisible]);

	const handleUpdateItem = async (e) => {
		e.preventDefault();

		let dataToUpdate = {}

		if(metaData.userType === "stallOwner" || metaData.userType === "approvedStallOwner"){
			dataToUpdate = {
				firstName,
				middleName,
				lastName,
				userName,
				contactNo,
				gender,
				birthDate,
				emergencyContactName,
				emergencyContactNo,
				address
			} 
		}	else {
			dataToUpdate = {
				firstName,
				middleName,
				lastName,
				userName,
				contactNo,
				plateNo,
				vehicle
			}		
		}

		try {
		  const dataItemRef = ref(db, `users/${metaData.uid}`);
		  await update(dataItemRef, dataToUpdate);
		  toast.success("Response sent successfully!");
		  toggleReloadTable();
		  toggleUpdateItemModal();
		} catch (error) {
		  console.error("Error updating response:", error);
		  toast.error("Failed to update response.");
		}
	}

	if(!isVisible)return null	

	return (

		<div className={`fixed z-50 inset-0 flex items-center justify-center overflow-y-auto backdrop-blur-sm py-8`}>

			<form onSubmit={handleUpdateItem} className="flex flex-col max-w-[500px] w-full bg-white rounded-lg shadow-2xl mx-2 my-auto p-4 gap-4">

				<img 
				src={metaData.profileImg} 
				alt="Profile Img" 
				className="mx-auto w-24 h-24 object-cover rounded-full"
				onError={(e) => {
					e.target.src=`${LOGO_TANAUAN}`
				}}
				/>
				<p className="text-center">{metaData.email}</p>
				<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
			    <input type="text" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={(e) => setFirstName(e.target.value)} value={firstName} required />
			    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">First Name:</p>
		    </div>

    		<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
    	    <input type="text" placeholder="(Optional)" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={(e) => setMiddleName(e.target.value)} value={middleName} />
    	    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Middle Name:</p>
        </div>

    		<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
    	    <input type="text" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={(e) => setLastName(e.target.value)} value={lastName} required />
    	    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Last Name:</p>
        </div>

        <div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
    	    <input type="text" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={(e) => setContactNo(e.target.value)} value={contactNo} required />
    	    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Contact No:</p>
        </div>

        {
        	userType === 'stallOwner' || userType === "approvedStallOwner"?
        	<>
        		<div className="flex flex-col md:flex-row md:items-center space-x-4 w-full">
        		  <p className="font-bold text-primary-green">Gender:</p>
        		  <label className="inline-flex items-center">
        		    <input type="radio" className="form-radio" name="gender" value="Male" checked={gender === 'Male'}  onChange={(e) => setGender(e.target.value)}/>
        		    <span className="ml-2">Male</span>
        		  </label>
        		  <label className="inline-flex items-center">
        		    <input type="radio" className="form-radio" name="gender" value="Female" checked={gender === 'Female'} onChange={(e) => setGender(e.target.value)}/>
        		    <span className="ml-2">Female</span>
        		  </label>
        		  <label className="inline-flex items-center">
        		    <input type="radio" className="form-radio" name="gender" value="Others" checked={gender === 'Others'} onChange={(e) => setGender(e.target.value)}/>
        		    <span className="ml-2">Others</span>
        		  </label>
        		</div>
        		
      			<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
    			    <input type="date" id="birthDate" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={(e) => setBirthDate(e.target.value)} value={birthDate} required />
    			    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Birthdate:</p>
    		    </div>

    		    <div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
    			    <input type="text" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={(e) => setEmergencyContactName(e.target.value)} value={emergencyContactName} required />
    			    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Emerdency Contact Name:</p>
    		    </div>

    		    <div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
    			    <input type="text" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={(e) => setEmergencyContactNo(e.target.value)} value={emergencyContactNo} required />
    			    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Emerdency Contact No:</p>
    		    </div>

		        <div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
		    	    <input type="text" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={(e) => setAddress(e.target.value)} value={address} />
		    	    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Address:</p>
		        </div>
        	</>
        	:
        	<>
        		<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
        			<select
        			  name="vehicle"	
        			  className="h-full bg-white rounded-[10px] outline-none w-full pl-4"
        			  value={vehicle}
        			  onChange={(e) => setVehicle(e.target.value)}
        			  required
        			>
        			  <option value="">Type Of Vehicle</option>	
        			  <option value="Truck">Truck</option>
        			  <option value="Car">Car</option>
        			  <option value="Tricycle">Tricycle</option>
        			  <option value="Cariton">Cariton</option>
        			  <option value="Jeep">Jeep</option>
        			</select>
        			<label className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Vehicle Type:</label>
        		</div>

    		    <div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
    			    <input type="text" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={(e) => setPlateNo(e.target.value)} value={plateNo} />
    			    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Plate No:</p>
    		    </div>
        	</>
        }


        <div className="flex flex-row w-full gap-4">
        	<button 
        	type="button" 
        	onClick={() => toggleUpdateItemModal()} 
        	className="w-full border-2 border-primary-green min-h-[40px] rounded-xl px-4 font-bold 
        	text-primary-green mt-4 hover:opacity-70">
        		Cancel
        	</button>
        	<button type="submit" className="w-full bg-primary-green min-h-[40px] rounded-xl px-4 font-bold text-white mt-4 hover:opacity-70">
        		Update
        	</button>
        </div>

			</form>

		</div>

	)
}

export default function ManageAccounts(){

	const [showUpdateItemModal, setShowUpdateItemModal] = useState(false);
	const [metaData, setMetaData] = useState({}); 

	const [loading, setLoading] = useState(false);
	const [reloadTable, setReloadTable] = useState(false);

	const [users, setUsers] = useState([]);
	const [search, setSearch] = useState('');
	const [filteredUsers, setFilteredUsers] = useState([]);

	// State for pagination
	const [rows, setRows] = useState([])
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	// Avouid a layout jump when reaching the last page with empty rows.
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	const toggleUpdateItemModal = () => {
		setShowUpdateItemModal(!showUpdateItemModal)
	}

	const toggleReloadTable = () => {
		setReloadTable(!reloadTable);
	}

	const handleChangePage = (event, newPage) => {
    	setPage(newPage);
  	};

	const handleChangeRowsPerPage = (event) => {
	  setRowsPerPage(parseInt(event.target.value, 10));
	  setPage(0);
	};

	function formatTimestamp(timestamp) {
	  const date = new Date(timestamp);
	  const year = date.getFullYear().toString().slice(-2); // Get the last two digits
	  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
	  const day = date.getDate().toString().padStart(2, '0');

	  // Convert 24h time to 12h time format
	  let hours = date.getHours();
	  const ampm = hours >= 12 ? 'PM' : 'AM';
	  hours = hours % 12;
	  hours = hours ? hours.toString().padStart(2, '0') : '12'; // the hour '0' should be '12'
	  
	  const minutes = date.getMinutes().toString().padStart(2, '0');
	  const seconds = date.getSeconds().toString().padStart(2, '0');

	  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${ampm}`;
	}

	useEffect(() => {
	  const db = getDatabase();
	  const usersRef = ref(db, 'users');
	  setLoading(true);

	  get(usersRef).then((snapshot) => {
	    if (snapshot.exists()) {
	      const usersArray = [];
	      snapshot.forEach((childSnapshot) => {
	        const key = childSnapshot.key;
	        const value = childSnapshot.val();
	        // Exclude users with userType 'Admin'
	        if (value.userType !== 'Admin') {
	          usersArray.push({ uid: key, ...value });
	        }
	      });
	      setUsers(usersArray); // Save the original users array excluding Admins
	    } else {
	      console.log('No users available');
	    }
	  }).catch((error) => {
	    console.error('Error fetching users:', error);
	  }).finally(() => {
	    setLoading(false);
	  });
	}, [reloadTable]);

	useEffect(() => {
	  // Filter users based on the search input and exclude Admins
	  const results = users.filter(user =>
	    user.email && user.email.toLowerCase().includes(search.toLowerCase()) && user.userType !== 'Admin'
	  );
	  setRows(results);
	}, [search, users, reloadTable]);


	if (loading) return <p>Loading...</p>;

	return (

		<div className="w-full px-4">
			<UpdateItemModal isVisible={showUpdateItemModal} toggleUpdateItemModal={toggleUpdateItemModal} metaData={metaData} toggleReloadTable={toggleReloadTable}/>
			<h1 className="text-center mt-6">Manage Accounts</h1>
			<div className="w-[60px] mx-auto border-2 border-primary-green rounded-xl"></div>

			<input
		        type="text"
		        placeholder="Search by email"
		        value={search}
		        onChange={(e) => setSearch(e.target.value)}
		        className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none my-4"
		    />

			<TableContainer component={Paper}>
			  <Table sx={{ minWidth: 700 }} aria-label="customized table">
			    <TableHead>
			      <TableRow>
			        <StyledTableCell>Email</StyledTableCell>
			        <StyledTableCell align="center">Contact Number</StyledTableCell>
			        <StyledTableCell align="center">Role</StyledTableCell>
			        <StyledTableCell align="center">Action</StyledTableCell>
			      </TableRow>
			    </TableHead>
			    <TableBody>

			    	{(rowsPerPage > 0
    	              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    	              : rows
    	            ).map((user, index) => (
    	              <StyledTableRow key={user.uid}>
    	                <StyledTableCell component="th" scope="row">
    	                  {user.email}
    	                </StyledTableCell>
    	                <StyledTableCell  align="center">
    	                  {user.contactNo}
    	                </StyledTableCell>
    	                <StyledTableCell align="center">
    	                	{user.userType === "stallOwner" ? "Pending Stall Holder" : user.userType === "approvedStallOwner"? "Stall Holder" : user.userType}
    	                </StyledTableCell>
    	                <StyledTableCell align="center">
	              	    	<button 
	          	              onClick={() => {
	          	              	toggleUpdateItemModal()
	          	              	setMetaData(user)
	          	              }}
	          	              className="p-2 font-bold text-primary-green border-2 border-primary-green rounded-lg 
	          	              hover:bg-primary-green hover:text-white">
	          	              Edit
	          	            </button>
    	                </StyledTableCell>
    	              </StyledTableRow>
    	            ))}

			    	{emptyRows > 0 && (
			    	  <TableRow style={{ height: 53 * emptyRows }}>
			    	    <TableCell colSpan={6} />
			    	  </TableRow>
			    	)}
			    </TableBody>
			  </Table>
			</TableContainer>

			<TablePagination
			  rowsPerPageOptions={[5, 10, 25, 50]}
			  component="div"
			  count={rows.length}
			  rowsPerPage={rowsPerPage}
			  page={page}
			  onPageChange={handleChangePage}
			  onRowsPerPageChange={handleChangeRowsPerPage}
			/> 

		</div>

	)

}