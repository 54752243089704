import { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection after login
import { useAuthContext } from '../contexts/AuthContext';

import { FaUser, FaLock } from "react-icons/fa";

export default function Login(){

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const { signIn } = useAuthContext();
	const navigate = useNavigate();

	const handleLogin = async (event) => {
		event.preventDefault();
		try {
			await signIn(email, password);
			navigate('/');
		} catch (error) {
			alert('Failed to log in, please check your credentials and try again.');
		}
	}

	return(
		<div className="flex flex-row grow w-full h-full px-4 lg:px-36 overflow-x-auto">
			<div className="flex items-center justify-center w-full">
				<div className="flex flex-col items-center gap-4 w-full max-w-[360px] md:w-[360px] bg-white rounded-lg p-4 shadow-2xl">
					<div className="text-center">
					<h1 className="text-primary-green">LOGIN</h1>
					<p className="text-primary-gray text-[14px]">Be updated on the latest news and trends</p>
					</div>
					<div className="flex flex-col items-center justify-center">
		                <form onSubmit={handleLogin} className="w-full">
		                    <div className="flex flex-row gap-4 border-b-2 px-10 pb-2 border-[#b6b6b6]">
		                        <FaUser className="w-8 h-auto text-secondary-green"/>
		                        <input 
		                            type="email" 
		                            id="email" 
		                            value={email} 
		                            placeholder="Email"
		                            onChange={(e) => setEmail(e.target.value)} 
		                            className="w-full p-2 mt-1 outline-none bg-white" 
		                            required
		                        />
		                    </div>
		                    <div className="flex flex-row gap-4 border-b-2 px-10 pb-2 border-[#b6b6b6] mt-4">
		                        <FaLock className="w-8 h-auto text-secondary-green"/>
		                        <input 
		                            type="password" 
		                            id="password" 
		                            value={password} 
		                            placeholder="Password"
		                            onChange={(e) => setPassword(e.target.value)} 
		                            className="w-full p-2 mt-1 outline-none bg-white"
		                            required
		                        />
		                    </div>
		                    <div className="flex flex-col gap-2 items-center justify-center mt-10 mb-5">
		                    <button type="submit" className="w-full font-bold text-safe-white bg-primary-green text-white p-2 mt-4 rounded-full">
		                        Sign In
		                    </button>
		                    {/*<p className="text-[14px] cursor-pointer">Forgot Password?</p>*/}
		                    </div>
		                </form>
					</div>
				</div>
			</div>
		</div>

	)

}