import React, { useState, useEffect } from 'react';

const generateOptions = (start, end) => {
  const options = [];
  for (let i = start; i <= end; i++) {
    options.push(i);
  }
  return options;
};

const isLeapYear = (year) => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

const DateSelector = ({ onDateChange, filter, hideFilter, defaultFilter }) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const currentDay = currentDate.getDate();

  const [filterType, setFilterType] = useState(!defaultFilter? 'all' : defaultFilter);
  const [selectedDate, setSelectedDate] = useState({
    year: currentYear,
    month: currentMonth,
    day: currentDay,
  });

  const [days, setDays] = useState(generateOptions(1, currentDay));
  const [months, setMonths] = useState(generateOptions(1, currentMonth)); // Initialize with 12 months

  const years = generateOptions(2000, currentYear);

  useEffect(() => {
    let numOfDays;
    let numOfMonth = 12; // Default to 12 months

    if (selectedDate.year && selectedDate.month) {
      if (selectedDate.year === currentYear) {
        numOfMonth = currentMonth;
        if (selectedDate.month === currentMonth) {
          numOfDays = currentDay;
        } else {
          if (selectedDate.month === 2) {
            numOfDays = isLeapYear(selectedDate.year) ? 29 : 28;
          } else if ([4, 6, 9, 11].includes(selectedDate.month)) {
            numOfDays = 30;
          } else {
            numOfDays = 31;
          }
        }
      } else {
        if (selectedDate.month === 2) {
          numOfDays = isLeapYear(selectedDate.year) ? 29 : 28;
        } else if ([4, 6, 9, 11].includes(selectedDate.month)) {
          numOfDays = 30;
        } else {
          numOfDays = 31;
        }
      }

      setDays(generateOptions(1, numOfDays));
      setMonths(generateOptions(1, numOfMonth)); // Update months here
    }
  }, [selectedDate.year, selectedDate.month, currentDay, currentMonth, currentYear]);

  const handleFilterTypeChange = (e) => {
    const value = e.target.value;
    setFilterType(value);
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setSelectedDate({
      ...selectedDate,
      [name]: parseInt(value, 10),
    });
  };

  useEffect(() => {
    if (onDateChange) {
      onDateChange(selectedDate, filterType);
    }
  }, [selectedDate, onDateChange, filterType]);

  return (
    <div className="flex flex-row gap-4">
      <select className="border-2 rounded-lg h-[40px] px-4" onChange={handleFilterTypeChange} hidden={hideFilter}>
        <option value="all">All</option>
        <option value="year">Year</option>
        <option value="yearMonth">Year-Month</option>
        <option value="yearMonthDay">Year-Month-Day</option>
      </select>
      {
        filterType !== 'all' &&
        <>
          <select name="year" className="border-2 rounded-lg h-[40px] px-4" value={selectedDate.year} onChange={handleDateChange}>
            {years.map((year) => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
          {filterType !== 'year' && (
            <select name="month" className="border-2 rounded-lg h-[40px] px-4" value={selectedDate.month} onChange={handleDateChange}>
              {months.map((month) => (
                <option key={month} value={month}>{month}</option>
              ))}
            </select>
          )}
          {filterType === 'yearMonthDay' && (
            <select name="day" className="border-2 rounded-lg h-[40px] px-4" value={selectedDate.day} onChange={handleDateChange}>
              {days.map((day) => (
                <option key={day} value={day}>{day}</option>
              ))}
            </select>
          )}
        </>
      }
    </div>
  );
};

export default DateSelector;
