import { Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react"
import { useAuthContext } from './contexts/AuthContext';
import './App.css';

import { ref as dbRef, push, set as dbSet, get, child, update, remove } from "firebase/database";
import { db, storage } from "./configs/firebase";

//helpers
import ProtectedRoute from './helpers/ProtectedRoute'

//components
import Navbar from "./components/Navbar";
import OffCanvas from "./components/OffCanvas";
import Sidebar from "./components/Sidebar";

//pages
import About from './pages/About';
import AdminDashboard from './pages/AdminDashboard';
import Feedback from './pages/Feedback';
import RegisterTrader from "./pages/RegisterTrader";
import RegisterStall from "./pages/RegisterStall";
import Login from "./pages/Login";
import NewsBoard from "./pages/NewsBoard";
import Map from "./pages/Map";
import ManageAccounts from "./pages/ManageAccounts";
import ManageStalls from "./pages/ManageStalls";
import ManagePayments from "./pages/ManagePayments";
import ManageFeedbacks from "./pages/ManageFeedbacks";
import OwnedStalls from "./pages/OwnedStalls";
import Payment from "./pages/Payment";
import PendingStallOwner from "./pages/PendingStallOwner";
import PriceMonitoring from "./pages/PriceMonitoring";
import Reports from "./pages/Reports";
import SelectGoods from "./pages/SelectGoods";
import TraderDashboard from "./pages/TraderDashboard";
import TradersProfile from "./pages/TradersProfile";

//external imports
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  // For Off Canvas
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };
  // <div className="flex flex-row grow w-full h-full px-4 lg:px-36 overflow-x-auto">

  const { user, userType, loading, logout } = useAuthContext();

  function createStallsData(totalStalls) {
    const stallsData = [];
    for (let number = 1; number <= totalStalls; number++) {
      let stall = {};

      if(number > 56){
        stall = {
          stallNumber: number.toString(),
          stallImg: '',
          stallName: '',
          stallProduce: '',
          stallSize: 'large',
          stallDescription: '4x6 Stall Size',
          stallAmount: 3650,
          transactions: [],
          status: 'Vacant',
          stallOwner: '',
        };
      } else {
        stall = {
          stallNumber: number.toString(),
          stallImg: '',
          stallName: '',
          stallProduce: '',
          stallSize: 'small',
          stallDescription: '3x6 Stall Size',
          stallAmount: 2738,
          transactions: [],
          status: 'Vacant',
          stallOwner: '',
        };
      }
      stallsData.push(stall);
    }
    return stallsData;
  }

  // useEffect(() => {
  //   const createAndPushStallsData = async () => {
  //     const stallsData = createStallsData(194);
  //     const stallsRef = dbRef(db, 'stalls');

  //     for (const stall of stallsData) {
  //       await push(stallsRef, stall);
  //     }
  //   };

  //   createAndPushStallsData();

  // }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col min-h-screen lg:max-h-screen bg-white custom-bg">
        <Navbar toggleSidebar={toggleSidebar}/>
        <ToastContainer />
        {
          !user?
          <>
          <OffCanvas  isVisible={isSidebarVisible} toggleSidebar={toggleSidebar}/>
          <Routes>
            <Route path="/" element={<NewsBoard />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<RegisterTrader />} />
            <Route path="/application" element={<RegisterStall />} />
            <Route path="/mapping" element={<Map />} />
            <Route path="/about" element={<About />} />
          </Routes>
          </>
          : userType === "Admin"?
          <div className="flex flex-row grow overflow-auto"> 
          <OffCanvas  isVisible={isSidebarVisible} toggleSidebar={toggleSidebar}/>
          <Sidebar />
          <Routes>
            <Route path="/" element={<ProtectedRoute allowedRoles={['Admin']}><AdminDashboard /></ProtectedRoute>} />
            <Route path="/price-monitoring" element={<ProtectedRoute allowedRoles={['Admin']}><PriceMonitoring /></ProtectedRoute>} />
            <Route path="/manage-accounts" element={<ProtectedRoute allowedRoles={['Admin']}><ManageAccounts /></ProtectedRoute>} />
            <Route path="/manage-stalls" element={<ProtectedRoute allowedRoles={['Admin']}><ManageStalls /></ProtectedRoute>} />
            <Route path="/manage-payments" element={<ProtectedRoute allowedRoles={['Admin']}><ManagePayments /></ProtectedRoute>} />
            <Route path="/manage-feedbacks" element={<ProtectedRoute allowedRoles={['Admin']}><ManageFeedbacks /></ProtectedRoute>} />
            <Route path="/reports" element={<ProtectedRoute allowedRoles={['Admin']}><Reports /></ProtectedRoute>} />
            <Route path="/select-goods" element={<ProtectedRoute allowedRoles={['Admin']}><SelectGoods /></ProtectedRoute>} />
          </Routes>
          </div>
          : userType === "driver" || userType === "magdadala" ||  userType === "kwartahera"?
          <div className="flex flex-row grow overflow-auto"> 
          <OffCanvas  isVisible={isSidebarVisible} toggleSidebar={toggleSidebar}/>
          <Sidebar />
          <Routes>
            <Route path="/" element={<ProtectedRoute allowedRoles={['driver', 'magdadala', 'kwartahera']}><TraderDashboard /></ProtectedRoute>} />
            <Route path="/map" element={<ProtectedRoute allowedRoles={['driver', 'magdadala', 'kwartahera']}><Map /></ProtectedRoute>} />
            <Route path="/profile" element={<ProtectedRoute allowedRoles={['driver', 'magdadala', 'kwartahera']}><TradersProfile /></ProtectedRoute>} />
            <Route path="/feedback" element={<ProtectedRoute allowedRoles={['driver', 'magdadala', 'kwartahera']}><Feedback /></ProtectedRoute>} />
          </Routes>
          </div>
          : userType === "stallOwner" || userType === "approvedStallOwner"?
          <div className="flex flex-row grow overflow-auto"> 
          <Sidebar hide={userType === "stallOwner"? true : false} />
          <OffCanvas  isVisible={isSidebarVisible} toggleSidebar={toggleSidebar} hide={userType === "stallOwner"? true : false}/>
          <Routes>
            <Route path="/" element={<ProtectedRoute allowedRoles={['approvedStallOwner', 'stallOwner']}>{userType === "stallOwner"? <PendingStallOwner/> : <TraderDashboard /> }</ProtectedRoute>} />
            <Route path="/map" element={<ProtectedRoute allowedRoles={['approvedStallOwner']}><Map /></ProtectedRoute>} />
            <Route path="/payment" element={<ProtectedRoute allowedRoles={['approvedStallOwner']}><Payment /></ProtectedRoute>} />
            <Route path="/stalls" element={<ProtectedRoute allowedRoles={['approvedStallOwner']}><OwnedStalls /></ProtectedRoute>} />
            <Route path="/feedback" element={<ProtectedRoute allowedRoles={['approvedStallOwner']}><Feedback /></ProtectedRoute>} />
          </Routes>
          </div>
          :
          null
        }
    </div>
  );
}

export default App;

/*
transactionsTable
userId
payment
date



*/