import { useState, useEffect } from 'react';

import { ref as dbRef, push, set as dbSet, get, child, update, remove } from "firebase/database";
import { db, storage } from "../configs/firebase";


export default function TraderDashboard(){

	const [newsList, setNewsList] = useState([]);
	const [loading, setLoading] = useState(true);

	function formatTimestamp(timestamp) {
	  const date = new Date(timestamp);
	  const year = date.getFullYear().toString().slice(-2); // Get the last two digits
	  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
	  const day = date.getDate().toString().padStart(2, '0');

	  // Convert 24h time to 12h time format
	  let hours = date.getHours();
	  const ampm = hours >= 12 ? 'PM' : 'AM';
	  hours = hours % 12;
	  hours = hours ? hours.toString().padStart(2, '0') : '12'; // the hour '0' should be '12'
	  
	  const minutes = date.getMinutes().toString().padStart(2, '0');
	  const seconds = date.getSeconds().toString().padStart(2, '0');

	  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${ampm}`;
	}

	useEffect(() => {
	  const fetchNews = async () => {
	    const newsRef = dbRef(db, 'news');
	  
	    try {
	      setLoading(true);
	      const snapshot = await get(newsRef);
	  
	      if (snapshot.exists()) {
	        const newsArray = Object.entries(snapshot.val()).map(([key, value]) => ({
	          id: key,
	          ...value,
	        }));
	        // Sort news items by createdAt in descending order
	        newsArray.sort((a, b) => b.createdAt - a.createdAt);
	        setNewsList(newsArray);
	      } else {
	        setNewsList([]);
	        console.log("No data available");
	      }
	    } catch (error) {
	      console.error(error);
	    } finally {
	      setLoading(false);
	    }
	  };
	  
	  fetchNews();
	}, []);

	return (

		<div className="w-full px-4">

			<h1 className="text-center mt-6">News Board</h1>
			<div className="w-[60px] mx-auto border-2 border-primary-green rounded-xl mb-6"></div>

			<div className="overflow-y-auto overflow-auto mx-4">
			{
				newsList.map((news, index) => {
					return(
						<div className="mb-6" key={index}>
							<div className="flex lg:flex-row flex-col max-w-[440px] rounded-lg p-4 w-full lg:max-w-[1080px] mx-auto bg-white shadow-xl gap-4">
								<div className="lg:w-1/2 w-full">
									<div className="relative w-full pt-[56.25%] border-secondary-gray rounded-lg overflow-hidden hover:bg-primary-gray hover:cursor-pointer">
										<img
										  className="absolute top-0 left-0 bottom-0 right-0 object-fit h-full w-full"
										  src={news.imgUrl}
										/>
									</div>
								</div>

								<div className="lg:w-1/2 w-full">
									<h2 className="">{news.title}</h2>
									<p className="text-primary-gray text-[12px] mb-4">last updated: {formatTimestamp(news.updatedAt)}</p>
									<p>{news.description}</p>
								</div>
							</div>
						</div>
					)
				})
			}
			</div>
		</div>

	)
};