import { useState, useEffect } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import { getDatabase, ref, get, query, orderByChild, equalTo, update } from "firebase/database";
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../configs/firebase";
import { toast } from 'react-toastify';
import { FaPencil } from "react-icons/fa6";
import { IoCloseSharp } from "react-icons/io5";

import { MdAddPhotoAlternate } from "react-icons/md";

import LOGO_TANAUAN from "../assets/LOGO_TANAUAN.png";
import defaultStallImg from '../assets/next-two.png'



const AddItemModal = ({isVisible, toggleAddItemModal, metaData, userDetails, toggleReloadTable}) => {

	if(!isVisible)return null	

	return (

		<div className={`fixed z-50 inset-0 flex items-start justify-center overflow-y-auto backdrop-blur-sm py-8`}>
			<div className="flex flex-col max-w-[500px] min-h-[400px] w-full bg-white rounded-lg shadow-2xl mx-2 my-auto p-4 gap-4">

				<div className="flex flex-col gap-2  grow">

					<div className="relative w-full pt-[56.25%] overflow-hidden bg-white shadow-xl group">
					 	<img 
					 	src={metaData.stallImg} 
					 	alt="Stall Img" 
					 	className="absolute top-0 left-0 bottom-0 right-0 object-cover rounded-xl h-full w-full"
					 	onError={(e) => {
					 		e.target.src = `${defaultStallImg}`
					 		e.target.className = "absolute top-0 left-0 bottom-0 right-0 object-cover rounded-xl h-full w-full opacity-40"
					 	}}
					 	/>
					 </div>

					<p className="text-center">{metaData.stallName? metaData.stallName : `# ${metaData.stallNumber}`}</p>
					<div className="flex flex-row gap-2">
						<div className="w-max rounded-md px-2 bg-primary-gray"><p className="text-[14px] text-white"># {metaData.stallNumber}</p></div>
						<div className="w-max rounded-md px-2 bg-primary-gray"><p className="text-[14px] text-white">{metaData.stallSize}</p></div>
					</div>
					<p>Amount: Php {metaData.stallAmount}</p>
					<p>Stall Owner: {userDetails.email}</p>
					<p>Description: {metaData.stallDescription}</p>

				</div>
		        <div className="flex flex-row w-full gap-4">
		        	<button 
		        	type="button" 
		        	onClick={() => toggleAddItemModal()} 
		        	className="w-full border-2 border-primary-green min-h-[40px] rounded-xl px-4 font-bold 
		        	text-primary-green mt-4 hover:opacity-70">
		        		Close
		        	</button>
		        </div>
			</div>

		</div>

	)
}


const UpdateItemModal = ({isVisible, toggleUpdateItemModal, metaData, userDetails, toggleReloadTable}) => {

	const { user } = useAuthContext();

	const [stallImg, setStallImg] = useState('');
	const [stallImgFile, setStallImgFile] = useState('');
	const [stallName, setStallName] = useState('');
	const [stallProduce, setStallProduce] = useState('');
	const [stallSize, setStallSize] = useState('');
	const [stallDescription, setStallDescription] = useState('');
	const [stallAmount, setStallAmount] = useState('');
	const [status, setStatus] = useState('');
	const [stallOwner, setStallOwner] = useState('');

	useEffect(() => {
		setStallName(metaData.stallName);
        setStallImg(metaData.stallImg);
        setStallProduce(metaData.stallProduce);
        setStallSize(metaData.stallSize);
        setStallDescription(metaData.stallDescription);
        setStallAmount(metaData.stallAmount);
        setStatus(metaData.status);
        setStallOwner(userDetails.email);
	}, [metaData]);

	const handleUpdateItem = async (e) => {
		e.preventDefault();

		let filename = ''

		if(stallImgFile){
			filename = `stallImages/${user.uid}/${Date.now()}/${stallImgFile.name}`;
		}

		let imgUrl = '';
		if (stallImgFile) {
		  try {
		    const imageRef = storageRef(storage, `${filename}`);
		    const snapshot = await uploadBytes(imageRef, stallImgFile);
		    imgUrl = await getDownloadURL(snapshot.ref);
		  } catch (error) {
		    toast.error("Failed to upload image.");
		    return;
		  }
		}

		const dataToUpdate = {
		  stallImg: stallImg,
		  stallName: stallName,	
		  stallProduce: stallProduce,
		  stallSize: stallSize,
		  stallDescription: stallDescription,
		  stallAmount: stallAmount,
		  status: status,
		};

		try {
		  const dataItemRef = ref(db, `stalls/${metaData.uid}`);
		  await update(dataItemRef, dataToUpdate);
		  toast.success("Stall updated successfully!");
		  toggleReloadTable();
		  toggleUpdateItemModal();
		} catch (error) {
		  toast.error("Failed to update Stall.");
		}
	}

	const handleImageChange = (e) => {
	  if (e.target.files && e.target.files[0]) {
	    let reader = new FileReader();
	    let file = e.target.files[0];

	    reader.onloadend = () => {
	      setStallImgFile(file);
	      setStallImg(reader.result);
	    };

	    reader.readAsDataURL(file);
	  }
	};

	if(!isVisible)return null	

	return (

		<div className={`fixed z-50 inset-0 flex items-start justify-center overflow-y-auto backdrop-blur-sm py-8`}>

			<div className="flex flex-col max-w-[500px] min-h-[400px] w-full bg-white rounded-lg shadow-2xl mx-2 my-auto p-4 gap-4">

				<form onSubmit={handleUpdateItem} className="flex flex-col gap-4">
	 				<div className="relative w-full pt-[56.25%] rounded-lg overflow-hidden hover:bg-primary-gray hover:cursor-pointer">
	                 {
	                 	!stallImg?
	                 	<img
	                 	  className="absolute top-0 left-0 bottom-0 right-0 object-cover h-full w-full opacity-40"
	                 	  src={defaultStallImg}
	                 	/>
	                 	:
	                 	<img
	                 	  className="absolute top-0 left-0 bottom-0 right-0 object-cover h-full w-full"
	                 	  src={stallImg}
	                 	/>
	                 }
	                 <div className="absolute inset-0 group flex place-content-center hover:bg-black/50">
	                   <MdAddPhotoAlternate
	                     className={`self-center w-10 h-10 rounded-[5px] group-hover:opacity-100 ${
	                       stallImgFile? "opacity-0" : null
	                     }`}
	                   />

	                   <input
	                     type="file"
	                     id="imageInput"
	                     name="imageInput"
	                     accept="image/*"
	                     className={`absolute left-0 top-0 opacity-0 hover:cursor-pointer w-full h-full`}
	                     onChange={handleImageChange}
	                   />
	                 </div>
	               </div>

	               	<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
						<input type="text" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={(e) => setStallName(e.target.value)} value={stallName}/>
						<p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Name:</p>
					</div>

					<div className="flex flex-row gap-4">
		               	<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
							<input type="text" min="0" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" value={metaData.stallNumber} disabled />
							<p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Stall Number:</p>
						</div>

						<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
							<select
							  name="size"	
							  className="h-full bg-white rounded-[10px] outline-none w-full pl-4"
							  onChange={(e) => setStallSize(e.target.value)}
							  value={stallSize}
							  disabled
							>
							  <option value="small">Small</option>
							  <option value="large">Large</option>	
							</select>
							<label className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Size:</label>
						</div>
					</div>

					<div className="flex flex-row gap-4">
   	        <div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
   						<input type="number" min="0" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={(e) => setStallAmount(e.target.value)} value={stallAmount} disabled />
   						<p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Amount:</p>
   					</div>

						<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
							<select
							  name="status"	
							  className="h-full bg-white rounded-[10px] outline-none w-full pl-4"
							  onChange={(e) => setStatus(e.target.value)}
							  value={status}
							  disabled
							>
							  <option value="Vacant">Vacant</option>
							  <option value="Unavailable">Unavailable</option>	
							</select>
							<label className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Status:</label>
						</div>
					</div>

	        <div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
						<input type="text" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={(e) => setStallProduce(e.target.value)} value={stallProduce} />
						<p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Produce:</p>
					</div>

          <div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full pt-2 min-h-[80px] max-h-[80px]">
						<textarea type="text" className="h-full max-h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={(e) => setStallDescription(e.target.value)} value={stallDescription} />
						<p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Description:</p>
					</div>

	        <div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
						<input type="text" className="h-full bg-white rounded-[10px] outline-none w-full pl-4" onChange={(e) => setStallOwner(e.target.value)} value={stallOwner} placeholder="N/A" disabled/>
						<p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Owner:</p>
					</div>

					<div className="flex flex-row w-full gap-4">
						<button 
						type="button" 
						onClick={() => toggleUpdateItemModal()} 
						className="w-full border-2 border-primary-green min-h-[40px] rounded-xl px-4 font-bold 
						text-primary-green mt-4 hover:opacity-70">
							Cancel
						</button>
						<button type="submit" className="w-full bg-primary-green min-h-[40px] rounded-xl px-4 font-bold text-white mt-4 hover:opacity-70">
							Update
						</button>
					</div>

				</form>

			</div>

		</div>

	)
}

const StallApplicationModal = ({isVisible, toggleStallApplicationModal}) => {

	const { user, userDetails } = useAuthContext();
	const [letter, setLetter] = useState();
	const [stallNumber, setStallNumber] = useState();
	const [selectedStall, setSelectedStall] = useState([]);
	const [stalls, setStalls] = useState([]);
	const [stallAvailable, setStallAvailable] = useState(false)
	const [disableButton, setDisableButton] = useState(false)
	const [addStallButton, setAddStallButton] = useState(false)
	const [loading, setLoading] = useState(false);
	const [reapply, setReapply] = useState(userDetails.applicationStatus && userDetails.applicationStatus === "Rejected"? false : true);

	const handleAddStall = () => {
	    if (stallNumber && !selectedStall.some(stall => stall.stallNumber === stallNumber)) {
	        setSelectedStall([...selectedStall, stallNumber]);
	        setStallNumber('');
	    }
	};

	const handleRemoveStall = (stallNumberToRemove) => {
	    setSelectedStall(selectedStall.filter(stall => stall !== stallNumberToRemove));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		let dataToUpdate = {
			letter: letter,
			stallApplications: selectedStall,
			applicationStatus: "Pending",
		}

		try {
		  const dataItemRef = ref(db, `users/${user.uid}`);
		  await update(dataItemRef, dataToUpdate);
		  toast.success("Application sent successfully!");
		  toggleStallApplicationModal();
		} catch (error) {
		  console.error("Error updating response:", error);
		  toast.error("Failed to update response.");
		}

	}

	useEffect(() => {
		console.log(userDetails)
	    setDisableButton(selectedStall.length === 0);
	    setAddStallButton(stalls.some(stall => stall.stallNumber == stallNumber));
	}, [stallNumber, selectedStall, stalls]);

	useEffect(() => {
	  const db = getDatabase();
	  const stallsRef = ref(db, 'stalls');
	  setLoading(true);

	  get(stallsRef).then((snapshot) => {
	    if (snapshot.exists()) {
	      let stallsArray = [];
	      snapshot.forEach((childSnapshot) => {
	        const key = childSnapshot.key;
	        const value = childSnapshot.val();
	        stallsArray.push({ uid: key, ...value });
	      });
	      stallsArray = stallsArray.filter(stall => 
	      	stall.status !== 'Unavailable'
	      )
	      setStalls(stallsArray)
	    } else {
	      console.log('No stalls available');
	    }
	  }).catch((error) => {
	    console.error('Error fetching stalls:', error);
	  }).finally(() => {
	    setLoading(false);
	  });
	}, []);

	if(!isVisible) return null

	if(loading) return <p>Loading...</p>

	if(userDetails.applicationStatus && userDetails.applicationStatus === "Rejected" && !reapply){
		return (

			<div className={`fixed z-50 inset-0 flex items-start justify-center overflow-y-auto backdrop-blur-sm py-8`}>

				<div className="flex flex-col max-w-[500px] min-h-[400px] w-full bg-white rounded-lg shadow-2xl mx-2 my-auto p-4 gap-4">

					<div className="flex items-center justify-center grow font-semibold">
							{userDetails.statusMessage}
					</div>

					<div className="flex flex-row w-full gap-4">
						<button 
						type="button" 
						onClick={() => toggleStallApplicationModal()} 
						className="w-full border-2 border-primary-green min-h-[40px] rounded-xl px-4 font-bold 
						text-primary-green mt-4 hover:opacity-70">
							Close
						</button>
						<button 
						onClick={() => setReapply(true)}
						className={`w-full bg-primary-green min-h-[40px] rounded-xl px-4 font-bold text-white mt-4 hover:opacity-70`}
						>
							Re-Apply
						</button>
					</div>

				</div>

			</div>
		)
	}	

	if(userDetails.stallApplications && userDetails.stallApplications.length !== 0){
		return (

			<div className={`fixed z-50 inset-0 flex items-start justify-center overflow-y-auto backdrop-blur-sm py-8`}>

				<div className="flex flex-col max-w-[500px] min-h-[400px] w-full bg-white rounded-lg shadow-2xl mx-2 my-auto p-4 gap-4">

					<div className="flex items-center justify-center grow font-semibold">
							You currently have an existing application.
					</div>

					<div className="flex flex-row w-full gap-4">
						<button 
						type="button" 
						onClick={() => toggleStallApplicationModal()} 
						className="w-full border-2 border-primary-green min-h-[40px] rounded-xl px-4 font-bold 
						text-primary-green mt-4 hover:opacity-70">
							Close
						</button>
					</div>

				</div>

			</div>
		)
	}	

	return (

		<div className={`fixed z-50 inset-0 flex items-start justify-center overflow-y-auto backdrop-blur-sm py-8`}>

			<form onSubmit={handleSubmit} className="flex flex-col max-w-[500px] min-h-[400px] w-full bg-white rounded-lg shadow-2xl mx-2 my-auto p-4 gap-4">

				<h2 className="text-center mt-4">Stall Application</h2>
				<div className="w-[60px] mx-auto border-2 border-primary-green rounded-xl mb-4"></div>

				<div className="grow">
					<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full min-h-[150px]">
					    <textarea type="text" className="h-full max-h-full bg-white rounded-[10px] outline-none w-full pl-4 pt-2" placeholder="Please fill out this form to write your application letter" onChange={(e) => setLetter(e.target.value)} required />
					    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Application Letter:</p>
				    </div>
				</div>

				<p className="font-bold bg-white text-primary-green text-[14px]">Stalls:</p>

				<div className="flex flex-row gap-4">
					<input type="number" min="1" className="border rounded-lg w-16 px-2" value={stallNumber} onChange={(e) => setStallNumber(e.target.value)}/>
					<button 
					type="button"
					onClick={handleAddStall} 
					disabled={!addStallButton}
					className={`${addStallButton? "bg-primary-green" : "bg-danger/[.70]"} px-2 text-white rounded-lg`}
					>{addStallButton? "Add Stall" : "Not Available"}</button>
				</div>

				<div className="flex flex-wrap gap-4">
		            {selectedStall.map((stall, index) => (
		                <div key={index} className="w-max h-max rounded-md px-2 bg-primary-gray flex flex-row items-center gap-2">
		                    <p className="text-[14px] text-white"># {stall}</p>
		                    <IoCloseSharp onClick={() => handleRemoveStall(stall)} className="text-white cursor-pointer" />
		                </div>
		            ))}
		        </div>

				<div className="flex flex-row w-full gap-4">
					<button 
					type="button" 
					onClick={() => toggleStallApplicationModal()} 
					className="w-full border-2 border-primary-green min-h-[40px] rounded-xl px-4 font-bold 
					text-primary-green mt-4 hover:opacity-70">
						Cancel
					</button>
					<button 
					type="submit" 
					className={`w-full bg-primary-green min-h-[40px] rounded-xl px-4 font-bold text-white mt-4 ${disableButton? "opacity-40" : "hover:opacity-70"}`}
					disabled={disableButton}
					>
						
						Apply
					</button>
				</div>

			</form>

		</div>
	)
}

export default function OwnedStall(){

	const { user, userDetails } = useAuthContext();

	const [ownedStalls, setOwnedStalls] = useState([]);
	const [loading, setLoading] = useState(true);
	const [reloadTable, setReloadTable] = useState(false)

	const [showAddItemModal, setShowAddItemModal] = useState(false);
	const [showUpdateItemModal, setShowUpdateItemModal] = useState(false);
	const [showStallApplicationModal, setShowStallApplicationModal] = useState(false);
	const [metaData, setMetaData] = useState({}); 

	const toggleAddItemModal = () => {
		setShowAddItemModal(!showAddItemModal)
	}

	const toggleUpdateItemModal = () => {
		setShowUpdateItemModal(!showUpdateItemModal)
	}

	const toggleStallApplicationModal = () => {
		setShowStallApplicationModal(!showStallApplicationModal)
	}

	const toggleReloadTable = () => {
		setReloadTable(!reloadTable)
	}

	useEffect(() => {
	  if (user) {
	    const fetchOwnedStalls = async () => {
	      const stallsRef = ref(db, 'stalls');
	      const stallsQuery = query(stallsRef, orderByChild('stallOwner'), equalTo(user.uid));

	      try {
	        const snapshot = await get(stallsQuery);
	        if (snapshot.exists()) {
	          // Include the uid by transforming the snapshot entries into an array of objects
	          const stallsData = Object.entries(snapshot.val()).map(([uid, stallDetails]) => {
	            return {
	              uid, // This is the uid of the stall
	              ...stallDetails, // Spread the rest of the stall details
	            };
	          });
	          console.log(stallsData);
	          setOwnedStalls(stallsData);
	        } else {
	          console.log("No owned stalls available");
	          setOwnedStalls([]);
	        }
	      } catch (error) {
	        console.error("Error fetching owned stalls:", error);
	      } finally {
	        setLoading(false);
	      }
	    };

	    fetchOwnedStalls();
	  }
	}, [user, reloadTable]); 

	if (loading) {
	  return <div>Loading...</div>;
	}

	return (
		<div className="w-full px-4 pb-4">
			<AddItemModal isVisible={showAddItemModal} toggleAddItemModal={toggleAddItemModal} metaData={metaData} userDetails={userDetails} toggleReloadTable={toggleReloadTable}/>
			<UpdateItemModal isVisible={showUpdateItemModal} toggleUpdateItemModal={toggleUpdateItemModal} metaData={metaData} userDetails={userDetails} toggleReloadTable={toggleReloadTable}/>
			<StallApplicationModal isVisible={showStallApplicationModal} toggleStallApplicationModal={toggleStallApplicationModal} />

			<h1 className="text-center mt-6">Stalls</h1>
			<div className="w-[60px] mx-auto border-2 border-primary-green rounded-xl mb-6"></div>

			<div className="w-full flex justify-end my-4">
				<button onClick={toggleStallApplicationModal}  className="bg-primary-green h-10 rounded-xl px-4 font-bold text-white">Stall Application</button>
			</div>

			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
			{
				ownedStalls.map((stall, index) => {
					return (
						<div 
						key={index} 
						className="flex flex-col gap-4 bg-white rounded-lg cursor-pointer"
						onClick={() => {
							setMetaData(stall)
							toggleAddItemModal();
						}}>

							<div className="relative w-full pt-[56.25%] rounded-t-lg overflow-hidden hover:bg-primary-gray hover:cursor-pointer">
								<img 
								src={stall.stallImg} 
								alt="Stall Img" 
								className="absolute top-0 left-0 bottom-0 right-0 object-cover rounded-t-xl h-full w-full"
								onError={(e) => {
									e.target.src = `${defaultStallImg}`
									e.target.className = "absolute top-0 left-0 bottom-0 right-0 object-cover rounded-t-xl h-full w-full"
								}}/>
							</div>

							<div className="flex flex-col px-2 mb-4">
								<div className="flex flex-row gap-2 mb-2">
									<div className="w-max h-max rounded-md px-2 bg-primary-gray"><p className="text-[14px] text-white"># {stall.stallNumber}</p></div>
									<div className="w-max h-max rounded-md px-2 bg-primary-gray"><p className="text-[14px] text-white">{stall.stallSize}</p></div>
									<div className="grow flex items-center justify-end">
										<button 
										className="w-7 h-7 border rounded-lg border-primary-green text-primary-green hover:bg-primary-green hover:text-white flex items-center justify-center"
										onClick={(e) => {
											e.stopPropagation()
											setMetaData(stall)
											toggleUpdateItemModal()
										}}>
											<FaPencil className=""/>
										</button>
									</div>
								</div>

								<p className="font-bold text-center">{stall.stallName? stall.stallName : `# ${stall.stallNumber}`}</p>

							</div>

						</div>
					) 
				})
			}
			</div>

		</div>

	)
}