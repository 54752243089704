import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyAZCgDJRUaReRYWjir6CYS6zmvcdl2KbCw",
  authDomain: "tanauan-tradings.firebaseapp.com",
  projectId: "tanauan-tradings",
  storageBucket: "tanauan-tradings.appspot.com",
  messagingSenderId: "659425010459",
  appId: "1:659425010459:web:ee3381f152397fbf2aa9be",
  measurementId: "G-48ZVG6T1W3"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getDatabase(app);
export const storage = getStorage(app); 