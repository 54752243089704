import { useState, useEffect } from 'react';

import { ref as dbRef, push, set as dbSet, get, child, update, remove } from "firebase/database";
import { db } from "../configs/firebase";
import { toast } from 'react-toastify';

export default function SelectGoods(){

	const [typeOfGoods, setTypeOfGoods] = useState([]);
	const [loading, setLoading] = useState(false);
	const [rows, setRows] = useState([]);

	const [date, setDate] = useState('');
	const [good, setGood] = useState('');
	const [weight, setWeight] = useState();
	const [quantity, setQuantity] = useState();

	const handleGoodSave = async (e) => {
		e.preventDefault();

		const goodsData = {
		  good,
		  weight,
		  quantity,
		  date,
		  createdAt: Date.now(),
		  updatedAt: Date.now(),
		};

		try {
		  const goodsRef = dbRef(db, 'goods');
		  const newGoodsRef = push(goodsRef);
		  await dbSet(newGoodsRef, goodsData);
		  toast.success("Goods saved successfully!");
		  setDate('');
		  setGood('');
		  setWeight(0);
		  setQuantity(0);
		} catch (error) {
		  console.error("Error saving goods:", error);
		  toast.error("Failed to save goods.");
		}
	}

	useEffect(() => {
	  const fetchCommodities = async () => {
	    const dataRef = dbRef(db, 'commodities');
	  	
	    try {
	      setLoading(true);
	      const snapshot = await get(dataRef);
	  
	      if (snapshot.exists()) {
	        const dataArray = Object.entries(snapshot.val()).map(([key, value]) => ({
	          id: key,
	          ...value,
	        }));
	        dataArray.sort((a, b) => b.createdAt - a.createdAt);
	        setRows(dataArray);
	      } else {
	        setRows([]);
	        console.log("No data available");
	      }
	    } catch (error) {
	      console.error(error);
	    } finally {
	      setLoading(false);
	    }
	  };
	  
	  fetchCommodities();
	}, []);

	return (

		<div className="flex flex-col w-full px-4">
			<h1 className="text-center mt-6">Select Goods</h1>
			<div className="w-[60px] mx-auto border-2 border-primary-green rounded-xl"></div>

			<div className="grow flex items-center justify-center">
				<form onSubmit={handleGoodSave} className="flex flex-col justify-center gap-4 w-full max-w-[360px] h-[400px] rounded-xl bg-white shadow-2xl p-4">
					
					<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
						<input
						  name="date"
						  type="date"	
						  className="h-full bg-white rounded-[10px] outline-none w-full pl-4"
						  onChange={(e) => setDate(e.target.value)}
						  value={date}
						  required
						/>
						<label className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Date:</label>
					</div>

					<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
						<select
						  name="typeOfGoods"	
						  className="h-full bg-white rounded-[10px] outline-none w-full pl-4"
						  onChange={(e) => setGood(e.target.value)}
						  value={good}
						  required
						>
						  <option value="">Select Good</option>	
						  {
						  	rows.map((row, index) => {
						  		return(
						  			<option value={`${row.name}`}>{row.name}</option>
						  		)
						  	})
						  }
						</select>
						<label className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Type Of Goods:</label>
					</div>


					<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
						<input
						  name="weight"
						  type="number"
						  min="1"	
						  className="h-full bg-white rounded-[10px] outline-none w-full pl-4"
						  onChange={(e) => setWeight(e.target.value)}
						  value={weight}
						  required
						/>
						<label className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Weight: (KG)</label>
					</div>

					<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full">
						<input
						  name="quantity"
						  type="number"	
						  min="1"
						  className="h-full bg-white rounded-[10px] outline-none w-full pl-4"
						  onChange={(e) => setQuantity(e.target.value)}
						  value={quantity}
						  required
						/>
						<label className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Quantity:</label>
					</div>

					<button type="submit" className="w-full bg-primary-green min-h-[40px] rounded-xl px-4 font-bold text-white mt-4 hover:opacity-70">
						Save
					</button>

				</form>
			</div>

		</div>
	);
};