import { useState, useEffect } from 'react';

import { Chart } from "react-google-charts";

import DateSelector from "../components/DateSelector";

import { ref as dbRef, get } from "firebase/database";
import { db } from "../configs/firebase";

export default function Reports(){

	const [loading, setLoading] = useState(false);
	const [goodsData, setGoodsData] = useState([["Goods", "Quantity"]]);
	const [stalls, setStalls] = useState([])
	const [transactions, setTransactions] = useState();
	const [filterDate, setFilterDate] = useState("");
	const [filter, setFilter] = useState('all');

	const data = [
	  ["Year", "Sales", "Expenses"],
	  ["2013", 1000, 400],
	  ["2014", 1170, 460],
	  ["2015", 660, 1120],
	  ["2016", 1030, 540],
	];

	const paymentOptions = {		
	  isStacked: false,
	  height: 300,
	  legend: { position: "top", maxLines: 3 },
	  vAxis: { minValue: 0 },
	};

	const stallOptions = {
	  backgroundColor: 'transparent',
	};

	const options = {
	  title: "Seasonal Goods Record",
	  is3D: true,
	};

	const handleDateChange = (date, filterType) => {
	  setFilter(filterType)
	  if(!date.year && !date.month && !date.day){
	    setFilterDate("");
	  } else{
	    setFilterDate(date);
	  }
	};

	useEffect(() => {
	  const fetchCommodities = async () => {
	    const dataRef = dbRef(db, 'goods');
	    
	    try {
	      setLoading(true);
	      const snapshot = await get(dataRef);
	      
	      if (snapshot.exists()) {
	        let dataArray = Object.entries(snapshot.val()).map(([key, value]) => ({
	          id: key,
	          ...value,
	        }));

	        // Application of the filter
	        if (filterDate && filter !== 'all') {
	          dataArray = dataArray.filter(item => {
	            const itemDate = new Date(item.date);
	            const startYear = filterDate.year;
	            const startMonth = filterDate.month ? filterDate.month - 1 : 0; // Months are 0-indexed
	            const startDay = filterDate.day || 1;

	            if (filter === 'year') {
	              return itemDate.getFullYear() === startYear;
	            } else if (filter === 'yearMonth') {
	              return itemDate.getFullYear() === startYear && itemDate.getMonth() === startMonth;
	            } else if (filter === 'yearMonthDay') {
	              return itemDate.getFullYear() === startYear && itemDate.getMonth() === startMonth && itemDate.getDate() === startDay;
	            }
	          });
	        }

	        // Aggregation of the quantities for each good
	        const goodsMap = dataArray.reduce((acc, item) => {
	          const quantity = parseInt(item.quantity, 10);
	          const weight = parseFloat(item.weight);
	          const totalQuantity = quantity * weight;

	          if (acc[item.good]) {
	            acc[item.good] += totalQuantity;
	          } else {
	            acc[item.good] = totalQuantity;
	          }
	          return acc;
	        }, {});

	        // Convert the aggregated object into an array for the chart
	        const newFormatArr = [["Goods", "Quantity"]];
	        for (const [good, totalQuantity] of Object.entries(goodsMap)) {
	          newFormatArr.push([good, totalQuantity]);
	        }

	        setGoodsData(newFormatArr);
	      } else {
	        setGoodsData([["Goods", "Quantity"]]);
	        console.log("No data available");
	      }
	    } catch (error) {
	      console.error(error);
	    } finally {
	      setLoading(false);
	    }
	  };
	  
	  fetchCommodities();
	}, [filter, filterDate]);

	useEffect(() => {
	    const fetchTransactions = async () => {
	        const transactionsRef = dbRef(db, 'transactions');

	        try {
	            setLoading(true);
	            const snapshot = await get(transactionsRef);

	            if (snapshot.exists()) {
	                let transactionsArray = Object.entries(snapshot.val()).map(([key, value]) => ({
	                    id: key,
	                    ...value,
	                }));

	                function isPastDue(dateString, createdAt) {
	                  const givenDate = new Date(dateString);
	                  const currentDate = new Date(createdAt);

	                  givenDate.setHours(0, 0, 0, 0);
	                  currentDate.setHours(0, 0, 0, 0);

	                  return givenDate < currentDate;
	                }

	                function arrPastDue(arrayOfDue, createdAt){
	                	const tempArr = [];
	                	arrayOfDue.map(due => {
	                		let penaltyAdded = isPastDue(due, createdAt)
	                		if(penaltyAdded){
	                			tempArr.push(due)
	                		}
	                	})

	                	return tempArr
	                }

	                // Aggregate the data by year
	                const aggregatedData = transactionsArray.reduce((acc, transaction) => {
	                    const listOfPastDue = arrPastDue(transaction.dues, transaction.createdAt);
	                    
	                    transaction.dues.forEach(due => {
	                        let dueDate = new Date(due);
	                        let month = dueDate.getMonth() + 1;
	                        let year = dueDate.getFullYear();
	                        let monthYear = `${year}-${month.toString().padStart(2, '0')}`;

	                        if (!acc[monthYear]) {
	                            acc[monthYear] = { Rents: 0, Penalties: 0 };
	                        }

	                        if (listOfPastDue.includes(due)) {
	                            let monthPenalty = listOfPastDue.length > 0 ? transaction.penalty / listOfPastDue.length : 0; // Avoid division by zero
	                            acc[monthYear].Rents += transaction.amount;
	                            acc[monthYear].Penalties += monthPenalty;
	                        } else {
	                            acc[monthYear].Rents += transaction.amount;
	                            acc[monthYear].Penalties += 0;
	                        }
	                    });

	                    return acc;
	                }, {});

	                // Convert the aggregated data to the chart data format
	                const chartData = [
	                    ["Year", "Rents", "Penalties"]
	                ];
	                Object.keys(aggregatedData).sort().forEach(year => {
	                    chartData.push([year, aggregatedData[year].Rents, aggregatedData[year].Penalties]);
	                });

	                setTransactions(chartData);
	            } else {
	                console.log("No transactions available");
	            }
	        } catch (error) {
	            console.error("Error fetching transactions:", error);
	        } finally {
	            setLoading(false);
	        }
	    };

	    fetchTransactions();
	}, [filter, filterDate]);

	useEffect(() => {
	    const fetchStalls = async () => {
	        const stallsRef = dbRef(db, 'stalls');

	        try {
	            setLoading(true);
	            const snapshot = await get(stallsRef);

	            if (snapshot.exists()) {
	                const stallsArray = Object.entries(snapshot.val()).map(([key, value]) => ({
	                    id: key,
	                    ...value,
	                }));

	                // Aggregate current status of stalls
	                const counts = stallsArray.reduce((acc, stall) => {
	                    const isVacant = stall.status === 'Vacant';

	                    if (isVacant) {
	                        acc.vacant += 1;
	                    } else {
	                        acc.unavailable += 1;
	                    }

	                    return acc;
	                }, { vacant: 0, unavailable: 0 });

	                // Prepare chart data
	                const stallChartData = [
	                    ["Status", "Vacant", "Unavailable"],
	                    ["Count", counts.vacant, counts.unavailable]
	                ];

	                setStalls(stallChartData);
	            } else {
	                console.log("No stalls available");
	            }
	        } catch (error) {
	            console.error("Error fetching stalls:", error);
	        } finally {
	            setLoading(false);
	        }
	    };

	    fetchStalls();
	}, []);



	return (

		<div className="flex flex-col gap-4 w-full px-4">
			<h1 className="text-center mt-6">Reports</h1>
			<div className="w-[60px] mx-auto border-2 border-primary-green rounded-xl mb-4"></div>

			<DateSelector onDateChange={handleDateChange} />

			<Chart
		      chartType="PieChart"
		      data={goodsData}
		      options={options}
		      width={"100%"}
		      height={"400px"}
		    />

		    <div className="w-full p-4 bg-[#ffffff]">
		    <p className="font-semibold">Stalls Report</p>
			    <Chart
		          chartType="Bar"
		          width="100%"
		          height="400px"
		          data={stalls}
		          options={stallOptions}
		        />
	        </div>

	        <div className="w-full p-4 bg-[#ffffff]">
	        <p className="font-semibold">Sales Report</p>
	        <Chart
		      chartType="AreaChart"
		      width="100%"
		      height="400px"
		      data={transactions}
		      options={paymentOptions}
		    />
		    </div>

		</div>

	)

}