import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';

import { IoMegaphoneOutline } from "react-icons/io5";

import { ref as dbRef, get, query, orderByChild, limitToLast } from "firebase/database";
import { db } from "../configs/firebase";

import BG from "../assets/login_BG.png";

// mui styling
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: `#40916C`,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));



export default function NewsBoard(){

	const navigate = useNavigate();

	const [ lastestNews, setLatestNews ] = useState('');
	const [ seeDetails, setSeeDetails ] = useState(false);

	// State for pagination
	const [rows, setRows] = useState([])
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	const handleChangePage = (event, newPage) => {
	    	setPage(newPage);
	  	};

  	const handleChangeRowsPerPage = (event) => {
  	  setRowsPerPage(parseInt(event.target.value, 10));
  	  setPage(0);
  	};

	useEffect(() => {
	  const fetchLatestNews = async () => {
	    const newsRef = query(dbRef(db, 'news'), orderByChild('createdAt'), limitToLast(1));

	    try {
	      const snapshot = await get(newsRef);
	      if (snapshot.exists()) {
	        // Get the last news item
	        const newsItem = Object.entries(snapshot.val()).map(([key, value]) => ({
	          id: key,
	          ...value,
	        }))[0]; // Since we expect only one item, we can directly access the first index
	        setLatestNews(newsItem);
	      }
	    } catch (error) {
	      console.error("Error fetching latest news:", error);
	      // Handle the error appropriately
	    }
	  };

	  fetchLatestNews();
	}, []);

	useEffect(() => {
	  const fetchCommodities = async () => {
	    const dataRef = dbRef(db, 'commodities');
	  
	    try {
	      const snapshot = await get(dataRef);
	  
	      if (snapshot.exists()) {
	        const dataArray = Object.entries(snapshot.val()).map(([key, value]) => ({
	          id: key,
	          ...value,
	        }));
	        dataArray.sort((a, b) => b.createdAt - a.createdAt);
	        setRows(dataArray);
	      } else {
	        setRows([]);
	        console.log("No data available");
	      }
	    } catch (error) {
	      console.error(error);
	    } finally {
	    }
	  };
	  
	  fetchCommodities();
	}, []);

	return(
		<div className="flex flex-row grow w-full h-full px-4 lg:px-36 overflow-x-auto">
			<div className="grow min-h-max flex items-center">
				<div className="w-full flex flex-col lg:flex-row gap-4">

					<div className="grow flex flex-col gap-2">
						<div className="flex flex-row gap-4 items-center">
							<IoMegaphoneOutline className="text-[32px]" />
							<h1>NEWS BOARD</h1>
						</div>
						<p className="text-[14px]">Be updated and get the latest news to Tanauan Trading Post.</p>

						<div className="relative w-full pt-[56.25%] overflow-hidden bg-secondary-gray shadow-xl group">
						 	<img
						 	  className="absolute top-0 left-0 bottom-0 right-0 object-fit h-full w-full"
						 	  src={lastestNews.imgUrl}
						 	  onError={(e) => {
							  	e.target.src = `${BG}`
							  }}
						 	/>
						 	<div onClick={() => navigate("/login")} className="absolute group-hover:flex items-center justify-center top-0 h-full 
						 	w-full group-hover:bg-[#000000] group-hover:opacity-70 hidden text-white cursor-pointer">
						 		See News Details
						 	</div>
						 </div>
						 <h2>{lastestNews.title}</h2>
						 {/*<p>{lastestNews.description}</p>*/}

						 <div className="w-full flex md:flex-row flex-col items-end justify-end">
						 	<div className="flex md:flex-row flex-col md:border-2 border-primary-green gap-2">
							 	<button onClick={() => {navigate("/application")}} className="p-1 px-2 md:pl-2 w-full md:w-max text-primary-green border-2 border-primary-green md:border-0 hover:opacity-90">
							 		APPLY FOR STALL HOLDER
							 	</button>
							 	<button onClick={() => {navigate("/register")}} className="md:border-l-2 text-white bg-primary-green w-full md:w-max p-1 px-2 hover:opacity-90">
							 		CREATE ACCOUNT FOR TRADERS
							 	</button>
						 	</div>
						 </div>

					</div>

					<div className="lg:min-w-[500px] lg:min-h-[500px] max-h-[400px]">
						<div className="h-full">
							<h2>Price Monitoring</h2>
								<TableContainer component={Paper}>
								  <Table sx={{ minWidth: '100%' }} aria-label="customized table">
								    <TableHead>
								      <TableRow>
								        <StyledTableCell>Commodities</StyledTableCell>
								        <StyledTableCell align="center">Weight (KG)</StyledTableCell>
								        <StyledTableCell align="center">Whole Sale Price (Php)</StyledTableCell>
								      </TableRow>
								    </TableHead>
								    <TableBody>
								    	{(rowsPerPage > 0
								    	  ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								    	  : rows
								    	).map((row, index) => (
								    	  <StyledTableRow key={row.name + index}>
								    	    <StyledTableCell component="th" scope="row">
								    	      {row.name}
								    	    </StyledTableCell>
								    	    <StyledTableCell align="center">{row.weight}</StyledTableCell>
								    	     <StyledTableCell align="center">{row.price}</StyledTableCell>
								    	  </StyledTableRow>
								    	))}

								    	{emptyRows > 0 && (
								    	  <TableRow style={{ height: 53 * emptyRows }}>
								    	    <TableCell colSpan={6} />
								    	  </TableRow>
								    	)}
								    </TableBody>
								  </Table>
								</TableContainer>

								<TablePagination
								  rowsPerPageOptions={[10]}
								  component="div"
								  count={rows.length}
								  rowsPerPage={rowsPerPage}
								  page={page}
								  onPageChange={handleChangePage}
								  onRowsPerPageChange={handleChangeRowsPerPage}
								/>
						</div>
					</div>		

				</div>	

			</div>
		</div>

	);

}