import { useNavigate, useMatch } from "react-router-dom";
import { useAuthContext } from '../contexts/AuthContext';

import { FaRegNewspaper } from "react-icons/fa6";
import { VscGraphLine, VscFeedback } from "react-icons/vsc";
import { FaUser } from "react-icons/fa";
import { HiMiniBuildingStorefront } from "react-icons/hi2";
import { GrMoney } from "react-icons/gr";
import { MdFeedback } from "react-icons/md";
import { GiFruitBowl } from "react-icons/gi";
import { BsClipboard2DataFill } from "react-icons/bs";
import { RiLogoutBoxLine } from "react-icons/ri";
import { FaMapMarkedAlt } from "react-icons/fa";
import { MdOutlinePayment } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";

export default function OffCanvas({ isVisible, toggleSidebar, hide }){

	const navigate = useNavigate();
	const { user, userType, logout } = useAuthContext();

	const isNewsBoard = useMatch("/")
	const isPrice = useMatch("/price-monitoring");
	const isAccounts = useMatch("/manage-accounts");
	const isStalls = useMatch("/manage-stalls");
	const isPayments = useMatch("/manage-payments");
	const isFeedbacks = useMatch("/manage-feedbacks");
	const isGoods = useMatch("/select-goods");
	const isReports = useMatch("/reports");

	const isMap = useMatch("/map");
	const isPayment = useMatch("/payment");
	const isFeedback = useMatch("/feedback");
	const isProfile = useMatch("/profile")

	const navigateTo = (route) => {
		navigate(route)
		toggleSidebar()
	}

	if(hide)return null

	if(userType === "Admin"){
		return (

			<>
				<div
		          className={`fixed z-[100] top-0 ${
		            isVisible ? "right-0" : "-right-96"
		          } w-60 h-full bg-secondary-green transition-all duration-300 flex flex-col gap-2 pb-6 pt-24`}
		        >
		        	<div className="flex flex-row">
		        		<button 
		        		onClick={() => {navigateTo("/")}} 
		        		className="flex flex-row items-center gap-4 pl-6 text-white font-bold w-full h-10 hover:opacity-70">
		        			<FaRegNewspaper />
		        			<p >News Board</p>
		        		</button>
		        		<div className={`w-[7px] ${isNewsBoard? "bg-white" : "hidden"} rounded-tl-md rounded-bl-md`}></div>
		        	</div>

		        	<div className="flex flex-row">
		        		<button 
		        		onClick={() => {navigateTo("/price-monitoring")}} 
		        		className="flex flex-row items-center gap-4 pl-6 text-white font-bold w-full h-10 hover:opacity-70">
		        			<VscGraphLine />
		        			<p >Price Monitoring</p>
		        		</button>
		        		<div className={`w-[7px] ${isPrice? "bg-white" : "hidden"} rounded-tl-md rounded-bl-md`}></div>
		        	</div>

		        	<div className="flex flex-row">
		        		<button 
		        		onClick={() => {navigateTo("/manage-accounts")}}  
		        		className="flex flex-row items-center gap-4 pl-6 text-white font-bold w-full h-10 hover:opacity-70">
		        			<FaUser />
		        			<p >Manage Accounts</p>
		        		</button>
		        		<div className={`w-[7px] ${isAccounts? "bg-white" : "hidden"} rounded-tl-md rounded-bl-md`}></div>
		        	</div>

		        	<div className="flex flex-row">
		        		<button 
		        		onClick={() => {navigateTo("/manage-stalls")}} 
		        		className="flex flex-row items-center gap-4 pl-6 text-white font-bold w-full h-10 hover:opacity-70">
		        			<HiMiniBuildingStorefront />
		        			<p >Manage Stalls</p>
		        		</button>
		        		<div className={`w-[7px] ${isStalls? "bg-white" : "hidden"} rounded-tl-md rounded-bl-md`}></div>
		        	</div>

		        	<div className="flex flex-row">
		        		<button 
		        		onClick={() => {navigateTo("/manage-payments")}} 
		        		className="flex flex-row items-center gap-4 pl-6 text-white font-bold w-full h-10 hover:opacity-70">
		        			<GrMoney />
		        			<p >Manage Payments</p>
		        		</button>
		        		<div className={`w-[7px] ${isPayments? "bg-white" : "hidden"} rounded-tl-md rounded-bl-md`}></div>
		        	</div>

		        	<div className="flex flex-row">
		        		<button 
		        		onClick={() => {navigateTo("/manage-feedbacks")}} 
		        		className="flex flex-row items-center gap-4 pl-6 text-white font-bold w-full h-10 hover:opacity-70">
		        			<MdFeedback />
		        			<p >Manage Feedbacks</p>
		        		</button>
		        		<div className={`w-[7px] ${isFeedbacks? "bg-white" : "hidden"} rounded-tl-md rounded-bl-md`}></div>
		        	</div>

		        	<div className="flex flex-row">
		        		<button 
		        		onClick={() => {navigateTo("/select-goods")}} 
		        		className="flex flex-row items-center gap-4 pl-6 text-white font-bold w-full h-10 hover:opacity-70">
		        			<GiFruitBowl />
		        			<p >Select Goods</p>
		        		</button>
		        		<div className={`w-[7px] ${isGoods? "bg-white" : "hidden"} rounded-tl-md rounded-bl-md`}></div>
		        	</div>

		        	<div className="flex flex-row">
		        		<button 
		        		onClick={() => {navigateTo("/reports")}} 
		        		className="flex flex-row items-center gap-4 pl-6 text-white font-bold w-full h-10 hover:opacity-70">
		        			<BsClipboard2DataFill />
		        			<p>Reports</p>
		        		</button>
		        		<div className={`w-[7px] ${isReports? "bg-white" : "hidden"} rounded-tl-md rounded-bl-md`}></div>
		        	</div>

		        	<div className="grow flex items-end justify-center">

		        		<button 
		        		onClick={() => {
		        			logout()
		        			toggleSidebar()
		        		}}
		        		className="font-bold text-white flex items-center gap-2 hover:opacity-70">
		        			<RiLogoutBoxLine /> 
		        			Logout
		        		</button>
		        	</div>
		        </div>
		        {isVisible && (
	              <div onClick={toggleSidebar} className="fixed inset-0 z-50" />
	            )}
	        </>

		);
	}

	if(userType === "driver" || userType === "magdadala" || userType === "kwartahera" || userType === "stallOwner" || userType === "approvedStallOwner"){
		return (

			<>
				<div
		          className={`fixed z-[100] top-0 ${
		            isVisible ? "right-0" : "-right-96"
		          } w-60 h-full bg-secondary-green transition-all duration-300 flex flex-col gap-2 pb-6 pt-24`}
		        >
		        	<div className="flex flex-row">
		        		<button 
		        		onClick={() => {navigateTo("/")}} 
		        		className="flex flex-row items-center gap-4 pl-6 text-white font-bold w-full h-10 hover:opacity-70">
		        			<FaRegNewspaper />
		        			<p >News Board</p>
		        		</button>
		        		<div className={`w-[7px] ${isNewsBoard? "bg-white" : "hidden"} rounded-tl-md rounded-bl-md`}></div>
		        	</div>

		        	<div className="flex flex-row">
		        		<button 
		        		onClick={() => {navigateTo("/map")}} 
		        		className="flex flex-row items-center gap-4 pl-6 text-white font-bold w-full h-10 hover:opacity-70">
		        			<FaMapMarkedAlt />
		        			<p>Map</p>
		        		</button>
		        		<div className={`w-[7px] ${isMap? "bg-white" : "hidden"} rounded-tl-md rounded-bl-md`}></div>
		        	</div>

		        	{
		        		userType === "stallOwner" || userType === "approvedStallOwner"?
		        		<div className="flex flex-row">
		        			<button 
		        			onClick={() => {navigateTo("/payment")}} 
		        			className="flex flex-row items-center gap-4 pl-6 text-white font-bold w-full h-10 hover:opacity-70">
		        				<MdOutlinePayment />
		        				<p>Payment</p>
		        			</button>
		        			<div className={`w-[7px] ${isPayment? "bg-white" : "hidden"} rounded-tl-md rounded-bl-md`}></div>
		        		</div>
		        		:
		        		<div className="flex flex-row">
		        			<button 
		        			onClick={() => {navigateTo("/profile")}} 
		        			className="flex flex-row items-center gap-4 pl-6 text-white font-bold w-full h-10 hover:opacity-70">
		        				<FaUserCircle />
		        				<p>Trader's ID</p>
		        			</button>
		        			<div className={`w-[7px] ${isProfile? "bg-white" : "hidden"} rounded-tl-md rounded-bl-md`}></div>
		        		</div>
		        	}

		        	<div className="flex flex-row">
		        		<button 
		        		onClick={() => {navigateTo("/feedback")}} 
		        		className="flex flex-row items-center gap-4 pl-6 text-white font-bold w-full h-10 hover:opacity-70">
		        			<VscFeedback />
		        			<p>Feedback</p>
		        		</button>
		        		<div className={`w-[7px] ${isFeedback? "bg-white" : "hidden"} rounded-tl-md rounded-bl-md`}></div>
		        	</div>

		        	<div className="grow flex items-end justify-center">

		        		<button 
		        		onClick={() => {
		        			logout()
		        			toggleSidebar()
		        		}}
		        		className="font-bold text-white flex items-center gap-2 hover:opacity-70">
		        			<RiLogoutBoxLine /> 
		        			Logout
		        		</button>
		        	</div>
		        </div>
		        {isVisible && (
	              <div onClick={toggleSidebar} className="fixed inset-0 z-50" />
	            )}
	        </>

		);
	}

	return(
		<>
			<div
	          className={`fixed z-[100] top-0 ${
	            isVisible ? "right-0" : "-right-96"
	          } w-60 h-full bg-secondary-green transition-all duration-300 flex flex-col gap-2`}
	        >
	        	<div className="pt-[100px] px-4 flex flex-col gap-4">
	        		<div>
	        			<button onClick={() => navigateTo("/")} className="text-white">NEWS BOARD</button>
	        		</div>
	        		<div>
	        			<button onClick={() => navigateTo("/mapping")} className="text-white">MAPPING</button>
	        		</div>
	        		<div>
	        			<button onClick={() => navigateTo("/about")} className="text-white">ABOUT</button>
	        		</div>
	        		<div>
	        			<button onClick={() => navigateTo("/login")} className="text-white">LOGIN</button>
	        		</div>
	        	</div>
	        </div>
	        {isVisible && (
              <div onClick={toggleSidebar} className="fixed inset-0 z-50" />
            )}
        </>

	);

};