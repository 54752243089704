import { FaInfo } from "react-icons/fa";
import { FaPhoneAlt, FaLocationArrow  } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";

export default function About(){

	return (

		<div className="flex flex-row grow w-full h-full px-4 lg:px-52 overflow-x-auto">
			<div className="flex flex-col justify-center w-full">
				<div className="flex items-center">
					<FaInfo className="w-7 h-7" />
					<h1>ABOUT</h1>
				</div>
				<p className="text-[14px] mb-4">Get To Know Tanauan Trading Post.</p>
				<div className="bg-white rounded-lg shadow-2xl p-4 flex flex-col gap-4">
					<p>
					A Trading Post is a station or store of a trader or trading company established in a thirty settled region where
					local products (as furs) are exchanged for manufactured goods.
					</p>

					<p>
					Examples of trading posts of goods include farmer's market, flea markets, antique stores, consignment shops,
					online marketplaces such as Amazon and eBay, and specialized trading platforms such as Alibaba for wholesale goods.
					</p>
				</div>

				<div className="flex flex-col gap-4 mt-6 mb-10">
					<h2>CONTACT US</h2>
					<div className="bg-white rounded-lg shadow-2xl p-4 flex flex-col gap-4">
						
						<div className="flex flex-row items-center gap-2">
							<FaPhoneAlt />
							<p>+63 932 512 4748</p>
						</div>

						<div className="flex flex-row items-center gap-2">
							<IoIosMail className="w-5 h-5" />
							<p>administrator@tanauancitytradingpost.com</p>
						</div>

						<div className="flex flex-row items-center gap-2">
							<FaLocationArrow />
							<p>Tanauan, Batangas</p>
						</div>

					</div>
				</div>
			</div>
		</div>

	);
};