import { useState, useEffect } from 'react';
import { ref as dbRef, push, set as dbSet, get, child, update, remove, query, orderByChild } from "firebase/database";
import { db } from "../configs/firebase";
import { toast } from 'react-toastify';
import { useAuthContext } from '../contexts/AuthContext';

import LOGO_TANAUAN from "../assets/LOGO_TANAUAN.png";

// mui styling
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: `#40916C`,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const AddItemModal = ({isVisible, toggleAddItemModal, metaData}) => {

	const [response, setResponse] = useState('');
	const [userDetails, setUserDetails] = useState({});
	const [isCompose, setIsCompose] = useState(false);

	useEffect(() => {
        const userRef = dbRef(db, 'users/' + metaData.userId);
        get(userRef).then((snapshot) => {
            if (snapshot.exists()) {
                setUserDetails(snapshot.val());
            } else {
                console.log("No data available for user: " + metaData.userId);
            }
        }).catch((error) => {
            console.error(error);
        });
        console.log(metaData.id);
	}, [metaData]);

	if(!isVisible)return null	

	return (

		<div className={`fixed z-50 inset-0 flex items-center justify-center overflow-y-auto backdrop-blur-sm`}>
			<div className="flex flex-col max-w-[500px] min-h-[400px] w-full bg-white rounded-lg shadow-2xl mx-2 p-4 gap-4">

				{
					!isCompose?
					<>
						<div className="flex flex-col gap-4  grow">
							<img 
							src={userDetails.profileImg} 
							alt="Profile Img" 
							className="mx-auto w-24 h-24 object-fill rounded-full"
							onError={(e) => {
								e.target.src=`${LOGO_TANAUAN}`
							}}
							/>
							<p className="text-center">{userDetails.email}</p>
							<h2>{metaData.title}</h2>
							<p>{metaData.message}</p>
						</div>
				        <div className="flex flex-row w-full gap-4">
				        	<button 
				        	type="button" 
				        	onClick={() => toggleAddItemModal()} 
				        	className="w-full border-2 border-primary-green min-h-[40px] rounded-xl px-4 font-bold 
				        	text-primary-green mt-4 hover:opacity-70">
				        		Close
				        	</button>
				        	<button type="button" onClick={() => setIsCompose(true)} className="w-full bg-primary-green min-h-[40px] rounded-xl px-4 font-bold text-white mt-4 hover:opacity-70">
				        		View Response
				        	</button>
				        </div>
					</>
					:
					<>
						<div className="grow"><p>{metaData.response}</p></div>
				        <div className="flex flex-row w-full gap-4">
				        	<button 
				        	type="button" 
				        	onClick={() => setIsCompose(false)} 
				        	className="w-full border-2 border-primary-green min-h-[40px] rounded-xl px-4 font-bold 
				        	text-primary-green mt-4 hover:opacity-70">
				        		Back
				        	</button>
				        </div>
					</>
				}
			</div>

		</div>

	)
}


const UpdateItemModal = ({isVisible, toggleUpdateItemModal, metaData}) => {

	const [response, setResponse] = useState('');
	const [userDetails, setUserDetails] = useState({});
	const [isCompose, setIsCompose] = useState(false);

	useEffect(() => {
        const userRef = dbRef(db, 'users/' + metaData.userId);
        get(userRef).then((snapshot) => {
            if (snapshot.exists()) {
                setUserDetails(snapshot.val());
            } else {
                console.log("No data available for user: " + metaData.userId);
            }
        }).catch((error) => {
            console.error(error);
        });
        console.log(metaData.id);
	}, [metaData]);

	const handleUpdateItem = async (e) => {
		e.preventDefault();

		const dataToUpdate = {
		  response: response,	
		  updatedAt: Date.now(),
		};

		try {
		  const dataItemRef = dbRef(db, `feedbacks/${metaData.id}`);
		  await update(dataItemRef, dataToUpdate);
		  toast.success("Response sent successfully!");

		  toggleUpdateItemModal();
		} catch (error) {
		  console.error("Error updating response:", error);
		  toast.error("Failed to update response.");
		}
	}

	if(!isVisible)return null	

	return (

		<div className={`fixed z-50 inset-0 flex items-center justify-center overflow-y-auto backdrop-blur-sm`}>

			<form onSubmit={handleUpdateItem} className="flex flex-col max-w-[500px] w-full bg-white rounded-lg shadow-2xl mx-2 p-4 gap-4">

				{
					!isCompose?
					<>
						<img 
						src={userDetails.profileImg} 
						alt="Profile Img" 
						className="mx-auto w-24 h-24 object-cover  rounded-full"
						onError={(e) => {
							e.target.src=`${LOGO_TANAUAN}`
						}}
						/>
						<p className="text-center">{userDetails.email}</p>
						<h2>{metaData.title}</h2>
						<p>{metaData.message}</p>

				        <div className="flex flex-row w-full gap-4">
				        	<button 
				        	type="button" 
				        	onClick={() => toggleUpdateItemModal()} 
				        	className="w-full border-2 border-primary-green min-h-[40px] rounded-xl px-4 font-bold 
				        	text-primary-green mt-4 hover:opacity-70">
				        		Cancel
				        	</button>
				        	<button type="button" onClick={() => setIsCompose(true)} className="w-full bg-primary-green min-h-[40px] rounded-xl px-4 font-bold text-white mt-4 hover:opacity-70">
				        		Compose
				        	</button>
				        </div>
					</>
					:
					<>
						<div className="relative top-0 h-[40px] border shadow-md rounded-[10px] outline-none w-full min-h-[140px]">
						    <textarea type="text" className="h-full max-h-full bg-white rounded-[10px] outline-none w-full pl-4 pt-2" onChange={(e) => setResponse(e.target.value)} required />
						    <p className="absolute top-[-12px] left-[12px] font-bold bg-white px-[1px] text-primary-green text-[14px]">Message:</p>
					    </div>
				        <div className="flex flex-row w-full gap-4">
				        	<button 
				        	type="button" 
				        	onClick={() => setIsCompose(false)} 
				        	className="w-full border-2 border-primary-green min-h-[40px] rounded-xl px-4 font-bold 
				        	text-primary-green mt-4 hover:opacity-70">
				        		Back
				        	</button>
				        	<button type="submit" className="w-full bg-primary-green min-h-[40px] rounded-xl px-4 font-bold text-white mt-4 hover:opacity-70">
				        		Send
				        	</button>
				        </div>
					</>
				}

			</form>

		</div>

	)
}

export default function ManageFeedbacks(){

	const { user } = useAuthContext();

	const [showAddItemModal, setShowAddItemModal] = useState(false);
	const [showUpdateItemModal, setShowUpdateItemModal] = useState(false);
	const [metaData, setMetaData] = useState({}); 

	const [loading, setLoading] = useState(false);

	// State for pagination
	const [rows, setRows] = useState([])
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	const toggleAddItemModal = () => {
		setShowAddItemModal(!showAddItemModal)
	}

	const toggleUpdateItemModal = () => {
		setShowUpdateItemModal(!showUpdateItemModal)
	}

	const handleChangePage = (event, newPage) => {
    	setPage(newPage);
  	};

	const handleChangeRowsPerPage = (event) => {
	  setRowsPerPage(parseInt(event.target.value, 10));
	  setPage(0);
	};

	function formatTimestamp(timestamp) {
	  const date = new Date(timestamp);
	  const year = date.getFullYear().toString().slice(-2); // Get the last two digits
	  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
	  const day = date.getDate().toString().padStart(2, '0');

	  // Convert 24h time to 12h time format
	  let hours = date.getHours();
	  const ampm = hours >= 12 ? 'PM' : 'AM';
	  hours = hours % 12;
	  hours = hours ? hours.toString().padStart(2, '0') : '12'; // the hour '0' should be '12'
	  
	  const minutes = date.getMinutes().toString().padStart(2, '0');
	  const seconds = date.getSeconds().toString().padStart(2, '0');

	  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${ampm}`;
	}

	useEffect(() => {
	  const fetchFeedback = async () => {
	    setLoading(true);
	    // Reference to your database path where feedbacks are stored
	    const feedbackRef = dbRef(db, 'feedbacks');
	    // Create a query that orders feedback by the 'createdAt' field
	    const feedbackQuery = query(feedbackRef, orderByChild('createdAt'));

	    try {
	      // Execute the query
	      const snapshot = await get(feedbackQuery);
	      if (snapshot.exists()) {
	        // Process the snapshot to get feedback data in reverse order (latest first)
	        const feedbackData = [];
	        snapshot.forEach((childSnapshot) => {
	          // Insert at the start of the array
	          feedbackData.unshift({
	            id: childSnapshot.key,
	            ...childSnapshot.val(),
	          });
	        });
	        // Update state with the fetched data
	        setRows(feedbackData);
	      } else {
	        console.log("No feedback available");
	      }
	    } catch (error) {
	      console.error("Error fetching feedback:", error);
	      toast.error("Failed to fetch feedback.");
	    } finally {
	      setLoading(false);
	    }
	  };

	   fetchFeedback();

	}, [showUpdateItemModal]);

	if(loading)return<p>Loading...</p>

	return (

		<div className="w-full px-4">

			<AddItemModal isVisible={showAddItemModal} toggleAddItemModal={toggleAddItemModal} metaData={metaData}/>
			<UpdateItemModal isVisible={showUpdateItemModal} toggleUpdateItemModal={toggleUpdateItemModal} metaData={metaData}/>

			<h1 className="text-center mt-6">Manage Feedbacks</h1>
			<div className="w-[60px] mx-auto border-2 border-primary-green rounded-xl mb-6"></div>

			<TableContainer component={Paper}>
			  <Table sx={{ minWidth: 700 }} aria-label="customized table">
			    <TableHead>
			      <TableRow>
			        <StyledTableCell>Title</StyledTableCell>
			        <StyledTableCell align="center">Date</StyledTableCell>
			        <StyledTableCell align="center">Action</StyledTableCell>
			      </TableRow>
			    </TableHead>
			    <TableBody>
			    	{(rowsPerPage > 0
			    	  ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
			    	  : rows
			    	).map((row, index) => (
			    	  <StyledTableRow key={row.title + index}>
			    	    <StyledTableCell component="th" scope="row">
			    	      {row.title}
			    	    </StyledTableCell>
			    	    <StyledTableCell align="center">{formatTimestamp(row.createdAt)}</StyledTableCell>
			    	    <StyledTableCell align="center">
			    	    	{
			    	    		!row.response?
	    		    	    	<button 
	    			              onClick={() => {
	    			              	toggleUpdateItemModal()
	    			              	setMetaData(row)
	    			              }}
	    			              className="p-2 font-bold text-primary-green border-2 border-primary-green rounded-lg 
	    			              hover:bg-primary-green hover:text-white">
	    			              Respond
	    			            </button>
	    			            :
	                	    	<button 
	            	              onClick={() => {
	            	              	toggleAddItemModal()
	            	              	setMetaData(row)
	            	              }}
	            	              className="p-2 font-bold text-primary-green border-2 border-primary-green rounded-lg 
	            	              hover:bg-primary-green hover:text-white">
	            	              View
	            	            </button>
			    	    	}
            			</StyledTableCell>
			    	  </StyledTableRow>
			    	))}

			    	{emptyRows > 0 && (
			    	  <TableRow style={{ height: 53 * emptyRows }}>
			    	    <TableCell colSpan={6} />
			    	  </TableRow>
			    	)}
			    </TableBody>
			  </Table>
			</TableContainer>

			<TablePagination
			  rowsPerPageOptions={[5, 10, 25, 50]}
			  component="div"
			  count={rows.length}
			  rowsPerPage={rowsPerPage}
			  page={page}
			  onPageChange={handleChangePage}
			  onRowsPerPageChange={handleChangeRowsPerPage}
			/>            

		</div>

	)

}