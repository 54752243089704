import { useState, useEffect } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import { get, ref } from "firebase/database";
import { db } from "../configs/firebase";

import LOGO_TANAUAN from "../assets/LOGO_TANAUAN.png";

export default function TradersProfile(){

    const { user } = useAuthContext();
    const [userDetails, setUserDetails] = useState(null);

    useEffect(() => {
        if (user && user.uid) {
            const userRef = ref(db, 'users/' + user.uid);
            get(userRef).then((snapshot) => {
                if (snapshot.exists()) {
                    setUserDetails(snapshot.val());
                } else {
                    console.log("No data available for user: " + user.uid);
                }
            }).catch((error) => {
                console.error(error);
            });
        }
    }, [user]);

    return (
        <div className="flex flex-col items-center w-full px-4">
            <h1 className="text-center mt-6">Profile</h1>
            <div className="w-[60px] mx-auto border-2 border-primary-green rounded-xl mb-6"></div>

            {userDetails && (
                <div className="flex flex-col items-center bg-white rounded-lg w-full max-w-[540px] p-4 gap-4 shadow-2xl">
                    <div className="flex items-center w-full justify-center relative">
                        <img 
                        src={LOGO_TANAUAN} 
                        alt="Profile Img" 
                        className="w-full absolute top-1 left-3 h-max max-w-[70px] object-cover rounded-full"
                        />
                        <div className="flex flex-col items-center mb-4">
                            <p className="text-[14px] font-[600] text-center text-primary-gray">Republic of the Philippines Province of Batangas</p>
                            <p className="text-[20px] font-semibold text-center text-primary-gray">City of Tanauan</p>
                            <p className="text-[16px] font-semibold text-center text-primary-gray">Office of the Public Market Trading Post</p>
                        </div>
                    </div>
                	<img 
                	src={userDetails.profileImg} 
                	alt="Profile Img" 
                	className="mx-auto w-full h-auto max-w-[120px] lg:max-w-[240px] object-cover rounded-full"
                	onError={(e) => {
                		e.target.src=`${LOGO_TANAUAN}`
                	}}
                	/>
                    <div className="flex flex-col items-center">
                    	<h2 className="text-center">{userDetails.firstName} {userDetails.middleName} {userDetails.lastName}</h2>
                    	<p className="text-[14px] text-secondary-gray text-center">ID: {userDetails.userType[0].toUpperCase()}-{user.uid}</p>
                    </div>
                    <p className="">{userDetails.userType.toUpperCase()}</p>
                </div>
            )}
        </div>
    );
}
